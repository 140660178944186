import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  X,
  MapPin,
  Truck,
  House,
  Clock,
  BuildingOffice,
} from "@phosphor-icons/react";
import {
  BodyBodyMedium,
  BodyBodySmall,
  HeadlineHeadlineMedium,
} from "../../utils/styleMethod";
import ImageUpload from "../image-upload";
import GoogleMapLocationMapModal from "./GoogleMapLocationModal";
import { mainApi } from "../../api/main";
import { toast } from "react-toastify";
const initQuery = {
  page: 1,
  limit: 8,
  sort: "desc",
  status: "CALL_TRACKING",
  keyWord: null,
  searchType: null,
  total: 0,
};
const options = [
  {
    id: "CALL_TO_COME",
    title: "Ирж ачууллах дуудлага өгөх",
  },
  {
    id: "GO_TO_DROPOFF",
    title: "Цэг дээр очиж өгөх",
  },
  {
    id: "DELIVER_TO_SCHEDULED_PARK",
    title: "Хуваарьт зогсоолд хүргэх",
  },
  {
    id: "GIVE_SUH",
    title: "СӨХ -д тушаах",
  },
];

const subItemList = [
  { id: "item1", name: "item sub 1" },
  { id: "item2", name: "item sub 2" },
  { id: "item3", name: "item sub 3" },
];
const NewCallRegisterModal = ({
  open,
  handleClose,
  factoryList,
  itemList,
  userId,
  setQuery,
}) => {
  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phoneNumber: "",
      emergencyPhoneNumber: "",
      factory: "",
      item: "",
      subitem: "",
    },
  });

  const [urlImage, setImageUrl] = useState("");
  const [openMapModal, setOpenMapModal] = useState(false);
  const [addressInfo, setAddressInfo] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const [subItemList, setSubItemList] = useState([]);
  
  const item = watch("item");

  useEffect(() => {
    const fetchSubItems = async () => {
      if (item) {
        try {
          const res = await mainApi(`items/group/type/${item}`);
          setSubItemList(res); 
        } catch (error) {
          console.error("Error fetching sub items:", error);
        }
      } else {
        setSubItemList([]); 
      }
    };

    fetchSubItems();
  }, [item]);

  useEffect(() => {
    if (addressInfo) {
      clearErrors("mapMethod");
    }
  }, [addressInfo, setError, clearErrors]);

  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);
    if (optionId) {
      clearErrors("deliveryMethod");
    }
  };

  const onSubmit = async (data) => {
    if (!selectedOption) {
      setError("deliveryMethod", {
        type: "manual",
        message: "Хүргэлтийн аргыг сонгоно уу",
      });
      return;
    }
    if (!addressInfo) {
      setError("mapMethod", {
        type: "manual",
        message: "Хаяг сонгоно уу",
      });
      return;
    }

    const formData = {
      factoryId: data.factory,
      userId: userId,
      itemsGroupId: data.item,
      itemsGroupTypeId: data.subitem,
      callType: selectedOption,
      callImage: urlImage,
      address: addressInfo?.address,
      addressDetail: data.addressDetail,
      phoneNumber: data.phoneNumber,
      emergencyPhoneNumber: data.emergencyPhoneNumber,
      lat: addressInfo.lat,
      lng: addressInfo.lng,
    };

    const res = await mainApi("call/create", "PUT", formData);

    reset();
    setSelectedOption("");
    setImageUrl("");
    setAddressInfo("");
    handleClose();
    setQuery(initQuery);
    toast.success("Дуудлаг амжилттай үүслээ.");
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "750px",
          maxWidth: "95vw",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          pl: 6,
          pb: 6,
          pt: 2,
          pr: 6,
        }}
      >
        <Box
          display="flex"
          width="100%"
          pb={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            flexGrow={1}
          >
            <HeadlineHeadlineMedium color="var(--on-surface-high)">
              Шинэ дуудлага
            </HeadlineHeadlineMedium>
          </Box>
          <Box>
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={2}>
            {/* Phone Number Field */}

            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
              <Box flex="1 1 45%">
                <BodyBodySmall color="var(--on-surface-high)">
                  Утасны дугаар
                </BodyBodySmall>
                <TextField
                  {...register("phoneNumber", {
                    required: "Утасны дугаарыг заавал оруулна уу",
                    pattern: {
                      value: /^[0-9]{8}$/,
                      message: "Утасны дугаар 8 оронтой байх ёстой",
                    },
                  })}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                />
              </Box>

              {/* Emergency Phone Number Field */}
              <Box flex="1 1 45%">
                <BodyBodySmall color="var(--on-surface-high)">
                  Яаралтай үед холбоо барих дугаар
                </BodyBodySmall>
                <TextField
                  {...register("emergencyPhoneNumber", {
                    required: "Яаралтай үед холбоо барих дугаарыг оруулна уу",
                    pattern: {
                      value: /^[0-9]{8}$/,
                      message: "Дугаар 8 оронтой байх ёстой",
                    },
                  })}
                  error={!!errors.emergencyPhoneNumber}
                  helperText={errors.emergencyPhoneNumber?.message}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                />
              </Box>

              {/* Factory List Dropdown */}
              <Box flex="1 1 45%">
                <BodyBodySmall color="var(--on-surface-high)">
                  Үйлдвэр сонгоно уу
                </BodyBodySmall>
                <Controller
                  name="factory"
                  control={control}
                  rules={{ required: "Үйлдвэр сонгоно уу" }}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      error={!!errors.factory}
                      helperText={errors.factory?.message}
                      fullWidth
                      InputProps={{ sx: { height: "45px" } }}
                      value={watch("factory") || ""}
                    >
                      {factoryList.map((factory) => (
                        <MenuItem
                          key={factory.organizationId}
                          value={factory.organizationId}
                        >
                          {factory.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>

              {/* item List Dropdown */}
              <Box flex="1 1 45%">
                <BodyBodySmall color="var(--on-surface-high)">
                  Та ямар дахивар тушаах вэ?
                </BodyBodySmall>
                <Controller
                  name="item"
                  control={control}
                  rules={{ required: "дахивар сонгоно уу" }}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      error={!!errors.item}
                      helperText={errors.item?.message}
                      fullWidth
                      InputProps={{ sx: { height: "45px" } }}
                    >
                      {itemList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>

            {/* sub item  List Dropdown */}
            {item && (
              <Box flex="1 1 45%">
                <BodyBodySmall color="var(--on-surface-high)">
                  Төрөл нь юу вэ?
                </BodyBodySmall>
                <Controller
                  name="subitem"
                  control={control}
                  rules={{ required: "төрөл сонгоно уу" }}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      error={!!errors.subitem}
                      helperText={errors.subitem?.message}
                      fullWidth
                      InputProps={{ sx: { height: "45px" } }}
                    >
                      {subItemList?.map((subitem) => (
                        <MenuItem key={subitem.id} value={subitem.id}>
                          {subitem.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            )}
            {/* Map Selection Button */}
            <Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">
                  Хаяг
                </BodyBodySmall>
                <Box display={"flex"} flexDirection={"row"} gap={2}>
                  <TextField
                    value={addressInfo?.address || ""}
                    disabled
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setOpenMapModal(true)}
                    startIcon={<MapPin size={20} />}
                    fullWidth
                    sx={{ width: "90px" }}
                  >
                    Map
                  </Button>
                </Box>
                {errors.mapMethod && (
                  <Typography color="error" variant="body2">
                    {errors.mapMethod.message}
                  </Typography>
                )}
              </Box>

              <BodyBodySmall color="var(--on-surface-high)">
                Хаягын дэлгэрэнгүй
              </BodyBodySmall>
              <TextField
                {...register("addressDetail", {})}
                fullWidth
                InputProps={{ sx: { height: "45px" } }}
              />
            </Box>

            <Box display="flex" width="100%" justifyContent="space-between">
              <Box display="flex" flexGrow={1}>
                {/* Delivery Method Options */}
                <Box>
                  <BodyBodySmall color="var(--on-surface-high)">
                    Хүргэлтийн арга сонгоно уу
                  </BodyBodySmall>
                  <RadioGroup
                    value={selectedOption}
                    onChange={(e) => handleOptionClick(e.target.value)}
                  >
                    {options.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio sx={{ color: "#2D8C37" }} />}
                        label={
                          <BodyBodyMedium color="var(--on-surface-high)">
                            {option.title}
                          </BodyBodyMedium>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {errors.deliveryMethod && (
                    <Typography color="error" variant="body2">
                      {errors.deliveryMethod.message}
                    </Typography>
                  )}
                </Box>
              </Box>{" "}
              <Box
                display="flex"
                justifyContent="center"
                textAlign="center"
                flexGrow={1}
              >
                {" "}
                {/* Image Upload */}
                <ImageUpload type="cover" setImageUrl={setImageUrl} />
              </Box>
            </Box>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              mt={2}
            >
              Дуудлага нэмэх
            </Button>
          </Box>
        </form>

        {openMapModal && (
          <GoogleMapLocationMapModal
            open={openMapModal}
            handleClose={() => setOpenMapModal(false)}
            setAddressInfo={setAddressInfo}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default NewCallRegisterModal;
