import { Card, CardContent, Box, Grid2 } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "344px",
  height: "244px",
  padding: "16px 8px 8px",
  border: "1px solid #A9DBAA ",
  borderRadius: "10px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
}));

const Name = styled("h6")(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  lineHeight: 1.4,
}));

const Info = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.primary.dark,
  lineHeight: 1.6,
}));

const AdminInfoLabel = styled("span")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  lineHeight: 1.63,
  letterSpacing: "normal",
  color: theme.palette.primary.dark,
  marginRight: "12px",
  paddingBottom: 0,
}));

const AdminInfoValue = styled("span")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.primary.dark,
  fontWeight: "bold",
  fontStretch: "normal",
  lineHeight: 1.4,
  letterSpacing: "normal",
}));

const LabelDisplay = ({ label, value }) => {
  return (
    <Box display="flex" alignItems="center" marginBottom="8px">
      <AdminInfoLabel>{label}</AdminInfoLabel>
      <AdminInfoValue>{value}</AdminInfoValue>
    </Box>
  );
};

export default function GridCardComponent(props) {
  const navigate = useNavigate();

  const onClickNavigate = (id) => {
    navigate(`detail/${id}`)
  }

  return (
    <Grid2 container spacing={2} alignItems="stretch">
      {props.data.map((item, index) => (
        <Grid2 xs={12} sm={6} md={4} lg={3} key={index}>
          <StyledCard onClick={() => onClickNavigate(item.id)}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={item.profileImage}
                  alt={item.name}
                  style={{
                    width: 85,
                    height: 85,
                    marginRight: "10px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                />
                <Box>
                  <Name>{item.name}</Name>
                  <Info>{item.description}</Info>
                </Box>
              </Box>
              <LabelDisplay
                label="Админ:"
                value={item.adminPhoneNumber}
              />
              <LabelDisplay
                label="Туслах админ 1:"
                value={item.subUserPhone1}
              />
              <LabelDisplay
                label="Туслах админ 2:"
                value={item.subUserPhone2}
              />
            </CardContent>
          </StyledCard>
        </Grid2>
      ))}
    </Grid2>
  );
}
