import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid2,
  Pagination,
  Paper,
  TableContainer,
} from "@mui/material";
import { DriverTable } from "../../components/driver/driver-table";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";
import { Plus } from "@phosphor-icons/react";
import DriverCreateEditModal from "../../components/driver/driver-create-edit-modal";
import { mainApi } from "../../api/main";
import { driverFilter } from "../../utils/method";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../components/form/form-input-dropdown";

const initialQuery = {
  page: 1,
  limit: 8,
  total: 0,
  type: "",
};
const Driver = () => {
  const [driverModalOpen, setDriverModalOpen] = useState(false);
  const { control } = useForm({});
  const [query, setQuery] = useState(initialQuery);
  const [driverListData, setDriverListData] = useState([]);
  
  useEffect(() => {
    getDriverList();
  }, [query]);

  const getDriverList = async () => {
    console.log('orlo --------->');
    try {
      const res = await mainApi("driver/list", "POST", query);
      setDriverListData(res.content);

      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery;
      });
    } catch (error) {
      console.error("Error fetching driver list:", error);
    } finally {
    }
  };
  const handleChangePage = (event, newPage) => {
    setQuery({ ...query, page: newPage });
  };

  const refreshList = () => {
    getDriverList();
  };

  const driverModalClose = () => setDriverModalOpen(false);

  const onchange1 = (values) => setQuery({ ...query, type: values });

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "24px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Жолооч
        </HeadlineHeadlineMedium>
        <Button
          onClick={() => setDriverModalOpen(true)}
          variant="contained"
          sx={{
            backgroundColor: "#2e7d32",
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
        >
          Шинэ жолооч нэмэх
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "16px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <p
            className="HeadlineHeadline---Small"
            style={{ color: "var(--on-surface-high)" }}
          >
            Жолоочийн дэлгэрэнгүй <span> | </span>{" "}
            <span className="BodyBody---Medium">
              {" "}
              Нийт {query.total} жолооч
            </span>{" "}
          </p>
          <FormInputDropdown
            control={control}
            name={`asc`}
            options={driverFilter}
            sx={{ minWidth: "250px", marginRight: "12px" }}
            onChange={(selectedValue) => onchange1(selectedValue)}
          />
        </Box>
        <DriverTable driverListData={driverListData} total={query.total} />{" "}
      </TableContainer>

      <Box display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(query.total / query.limit)} // Total pages
          page={query.page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
      <DriverCreateEditModal
        type="create"
        data={[]}
        open={driverModalOpen}
        handleClose={driverModalClose}
        // setDriverData={setDriverData}
        refreshList={refreshList}
      />
    </Grid2>
  );
};

export default Driver;
