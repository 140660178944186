import * as React from "react";
import Box from "@mui/material/Box";
import { Grid2 } from "@mui/material";
import Sidebar from "./SideBar";
import TopBar from "./top-bar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "20px 32px 0 32px", // Default padding
    "@media (min-width: 1920px) and (max-width: 1920px)": {
      padding: "48px 72px 0 72px", // For 1920x1080
    },
    "@media (min-width: 1366px) and (max-width: 1366px)": {
      padding: "40px 60px 0 60px", // For 1366x768
    },
    "@media (min-width: 1680px) and (max-width: 1680px)": {
      padding: "44px 64px 0 64px", // For 1680x1050
    },
    "@media (min-width: 1440px) and (max-width: 1440px)": {
      padding: "42px 60px 0 60px", // For 1440x900
    },
  },
});

export default function MainLayout(props) {
  const classes = useStyles();

  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Grid2
        style={{
          backgroundColor: "#ffffff",
          width: "238px",
          height: "100vh",
          borderRight: "1px solid #f2f2f2",
          overflowY: "auto",
        }}
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#c1c1c1",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a0a0a0",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-track:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        {/* Sidebar Section */}
        <div
          style={{
            paddingTop: "32px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            height: "40px",
          }}
        >
          <img src="/images/logo.svg" alt="logo" />
        </div>
        <Sidebar />
      </Grid2>

      <Grid2 container direction="column" style={{ flex: 1 }}>
        {/* TopBar Section */}
        <Grid2
          style={{
            height: "88px",
            width: "100%",
            backgroundColor: "#ffffff",
            borderBottom: "1px solid #f2f2f2",
          }}
        >
          <TopBar />
        </Grid2>

        {/* Main Content Section (Scrollable) */}
        {/* <Grid2
          style={{
            flex: 1,
            overflowY: "auto", // Allow only props.children to scroll
            backgroundColor: "#FAFBFD",
            padding: "40px 64px 0 64px",
          }}
        >
          {props.children}
        </Grid2> */}

  
        <Grid2
          sx={{
            flex: 1,
            overflowY: "auto",
            backgroundColor: "#FAFBFD",
            padding: {
              xs: "30px 48px 0 48px", // Small devices (phones, tablets)
              sm: "35px 50px 0 50px", // Small laptops or large tablets
              md: "40px 64px 0 64px", // Default padding
              lg: {
                padding: "46px 70px 0 70px", // Larger laptops (1600px, etc.)
              },
              "@media (min-width: 1920px) and (max-width: 1920px)": {
                padding: "48px 72px 0 72px", // For 1920x1080 resolution
              },
              "@media (min-width: 1366px) and (max-width: 1366px)": {
                padding: "40px 60px 0 60px", // For 1366x768 resolution
              },
              "@media (min-width: 1680px) and (max-width: 1680px)": {
                padding: "32px 16px 0 32px", // For 1680x1050 resolution
              },
              "@media (min-width: 1440px) and (max-width: 1440px)": {
                padding: "8px 16px 0 16px", // For 1440x900 resolution
              },
              "@media (min-width: 1280px) and (max-width: 1280px)": {
                padding: "36px 56px 0 56px", // For 1280x800 resolution
              },
              "@media (min-width: 1600px) and (max-width: 1600px)": {
                padding: "46px 70px 0 70px", // For 1600x900 resolution
              },
              "@media (min-width: 1024px) and (max-width: 1024px)": {
                padding: "32px 50px 0 50px", // For 1024x768 resolution
              },
              "@media (min-width: 2560px) and (max-width: 2560px)": {
                padding: "60px 90px 0 90px", // For 2560x1440 resolution (or larger)
              },
            },
          }}
        >
          {props.children}
        </Grid2>
      </Grid2>
    </Box>
  );
}
