export const StatusSpan = ({ label , statusColor, }) => {
  return (
    <span
      style={{
        border: `1px solid ${statusColor}`,
        backgroundColor: `${statusColor}20`,
        color: statusColor,
        padding: "4px 12px",
        borderRadius: "24px",
        fontWeight: "bold",
        display: "inline-block",
        minWidth: "150px",
        textAlign: "center",
      }}
    >
      {label}
    </span>
  );
};
