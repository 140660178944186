import { Box, Button, Grid2, IconButton, Menu, MenuItem } from "@mui/material";
import {
    ArrowLeft,
    ClockUser,
    Coins,
    DotsThreeVertical,
    Pencil,
    Trash,
    UserMinus,
    UserPlus,
} from "@phosphor-icons/react";
import {
    BodyBodyLarge,
    BodyBodyMedium,
    BodyBodySmall,
    HeadlineHeadlineSmall,
    TitleTitleMedium,
} from "../../utils/styleMethod";
import { useEffect, useState } from "react";
import UpdateOrganizationModal from "../modals/UpdateOrganization";
import { mainApi } from "../../api/main";
import AddPriceModal from "./modals/AddPrice";
import OrganizationCloseOpen from "./modals/OrganizationCloseOpen";

// import UpdateOrganizationModal from '../modals/UpdateOrganizationModal';

export default function OrganizationDetail({ data }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);

    const [openPriceToItem, setOpenPriceToItem] = useState(false);

    const [openOrganizationCloseOpen, setOpenOrganizationCloseOpen] = useState(false);
    useEffect(() => {
        getItemList();
    }, []);

    const getItemList = async () => {
        const res = await mainApi("items/group/list", "GET", null);
        setItemList(res);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openUpdate = () => {
        setOpenUpdateModal(true);
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                // border: "1px solid #E0E0E0",
                borderRadius: "10px",
                p: "24px 32px",
                backgroundColor: "#FFFFFF",
                width: "100%",
                maxWidth: "100vw",
                margin: "0 auto",
                mt: 2,
                boxSizing: "border-box",
            }}
        >
            <Grid2 container spacing={2} mb={4}>
                {/* Logo Section */}
                <Grid2 xs={12} md={2}>
                    <img
                        src={data.profileImage}
                        alt={data.profileImage}
                        style={{
                            width: 80,
                            height: 80,
                            border: "1px solid #ccc",
                            borderRadius: "12px",
                            objectFit: "cover",
                        }}
                    />
                </Grid2>

                {/* Company Information Section */}
                <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }}>
                    <Box display="flex" alignItems="center">
                        <Box
                            sx={{
                                flexGrow: 1,
                                paddingTop: "12px",
                                paddingLeft: "12px",
                            }}
                        >
                            <HeadlineHeadlineSmall color="var(--on-surface-high)">
                                {data.name}
                            </HeadlineHeadlineSmall>
                            <TitleTitleMedium color="var(--on-surface-high)">
                                {data.description}
                            </TitleTitleMedium>
                        </Box>
                        {/* More options icon using Phosphor */}
                        <IconButton
                            onClick={handleClick}
                            sx={{
                                "&:hover": { color: "#4CAF50" }, // Change color on hover
                            }}
                        >
                            <DotsThreeVertical size={24} weight="bold" />
                        </IconButton>
                    </Box>
                </Grid2>
            </Grid2>

            {/* Phone, Email, Address Section */}
            <Grid2 container spacing={2} mb={4}>
                <Grid2 xs={12} sm={4} sx={{ mr: 5 }}>
                    {" "}
                    {/* Added right margin */}
                    <BodyBodySmall color="var(--on-surface-high)">
                        Утас:
                    </BodyBodySmall>
                    <TitleTitleMedium color="var(--on-surface-high)">
                        {data.phoneNumber}
                    </TitleTitleMedium>
                </Grid2>
                <Grid2 xs={12} sm={4} sx={{ mr: 5 }}>
                    {" "}
                    {/* Added right margin */}
                    <BodyBodySmall color="var(--on-surface-high)">
                        Имэйл хаяг:
                    </BodyBodySmall>
                    <TitleTitleMedium color="var(--on-surface-high)">
                        {data.email}
                    </TitleTitleMedium>
                </Grid2>
                <Grid2 xs={12} sm={4}>
                    <BodyBodySmall color="var(--on-surface-high)">
                        Хаяг:
                    </BodyBodySmall>
                    <TitleTitleMedium color="var(--on-surface-high)">
                        {data.address}
                    </TitleTitleMedium>
                </Grid2>
            </Grid2>

            {/* About Section */}
            <Box mt={2} sx={{ width: "100%", pb: 4 }} mb={4}>
                <TitleTitleMedium color="var(--on-surface-high)">
                    Бидний тухай
                </TitleTitleMedium>
                <BodyBodyLarge color="var(--on-surface-high)">
                    {data.description}
                </BodyBodyLarge>
            </Box>

            {/* Dropdown Menu */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={openUpdate}>
                    {" "}
                    <Pencil
                        style={{ marginRight: "12px" }}
                        color="var( --on-surface-high)"
                        size={16}
                        weight="light"
                    />
                    <BodyBodyMedium color="var( --on-surface-high)">
                        Үйлдвэрийн мэдээлэл засах
                    </BodyBodyMedium>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpenPriceToItem(true);
                        handleClose();
                    }}
                >
                    <Coins
                        style={{ marginRight: "12px" }}
                        color="var( --on-surface-high)"
                        size={16}
                        weight="light"
                    />
                    <BodyBodyMedium color="var( --on-surface-high)">
                        Үнэ оруулах
                    </BodyBodyMedium>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpenOrganizationCloseOpen(true); handleClose();
                    }}
                >
                    <ClockUser
                        style={{ marginRight: "12px" }}
                        color="var( --on-surface-high)"
                        size={16}
                        weight="light"
                    />
                    <BodyBodyMedium color="var( --on-surface-high)">
                        Үйлдвэр нээх/хаах
                    </BodyBodyMedium>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Trash
                        style={{ marginRight: "12px" }}
                        color="var( --on-surface-high)"
                        size={16}
                        weight="light"
                    />
                    <BodyBodyMedium color="var( --on-surface-high)">
                        Үйлдвэр устгах
                    </BodyBodyMedium>
                </MenuItem>
            </Menu>
            <UpdateOrganizationModal
                open={openUpdateModal}
                handleClose={() => setOpenUpdateModal(false)}
                organizationData={data}
                update={true}
                type="FACTORY"
                itemList={itemList}
                roleName={"FactoryAdmin"}
            />

            {openPriceToItem ? (
                <AddPriceModal
                    open={openPriceToItem}
                    handleClose={() => setOpenPriceToItem(false)}
                    itemList={itemList}
                    itemIds={data.itemIds}
                    id={data.organizationId}
                />
            ) : null}

            <OrganizationCloseOpen
                title={data.active ? "Хаах" : "Нээх"}
                content={data.active ? "Түр хаахдаа итгэлтэй байна уу? " : "Идвэхтэй болгох" }
                buttonText={data.active ? "Хаах" : "Нээх" }
                open={openOrganizationCloseOpen}
                handleClose={()=>setOpenOrganizationCloseOpen(false)}
                active = {data.active}
                organizationId={data.organizationId}
            />
        </Box>
    );
}
