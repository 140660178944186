import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { StatusSpan } from "../../form/span";
import {
  tableBodyCellStyle,
  tableHeaderStyle,
} from "../../../utils/styleMethod";

export const DriverTable = ({ driverListData }) => {
  const navigate = useNavigate();

  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();

  return (
    <Table aria-label="order table">
      <TableHead>
        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
          {[
            "Жолооч ID",
            "Утас",
            "Нэр",
            "Бүртгэлтэй үйлдвэр",
            "Дуудлагын тоо",
            "Ачилтын чиглэл",
            "Дуудлага",
          ].map((header) => (
            <TableCell key={header} className={classessHeader.tableHeader}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {driverListData.map((row, index) => (
          <TableRow
            key={index}
            onClick={() => navigate(`detail/${row.userId}`)}
            style={{ cursor: "pointer" }}
            className={index % 2 === 1 ? "table-row-odd" : ""}
          >
            <TableCell className={classessCell.tableBodyCell}>
              {row.driverCode}
            </TableCell>
            <TableCell className={classessCell.tableBodyCell}>
              {row.phoneNumber}
            </TableCell>
            <TableCell className={classessCell.tableBodyCell}>
              {row.name}
            </TableCell>
            <TableCell className={classessCell.tableBodyCell}>
              {row.registeredFactory}
            </TableCell>
            <TableCell className={classessCell.tableBodyCell}>
              {row.totalCall}
            </TableCell>
            <TableCell className={classessCell.tableBodyCell}>
              {row.zoneName}
            </TableCell>
            <TableCell className={classessCell.tableBodyCell}>
              <StatusSpan
                label={row.driverStatus}
                statusColor={
                  row.driverStatus === "WITH_CALLS" ? "green" : "red"
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
