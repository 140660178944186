// import { useEffect, useState } from "react";
// import { UserContext } from "./user";
// import Cookies from "js-cookie";

// export const AuthProvider = ({ children }) => {
//   const cookieData = Cookies.get("user");

//   const [user, setUser] = useState();
//   const [activeMenu, setActiveMenu] = useState(0);

//   useEffect(() => {
//     const cookieData = Cookies.get("user");
//     if (cookieData) {
//       setUser(cookieData);
//     }
//   }, []);

//   const toggleMenu = (value) => {
//     setActiveMenu(value);
//   };

//   const saveUser = (data) => {
//     setUser(data);
//     Cookies.set("user", JSON.stringify(data), { expires: 1 });
//   };

//   const logout = () => {
//     setUser(null);
//     Cookies.remove("user");
//   };

//   return (
//     <UserContext.Provider
//       value={{
//         activeMenu,
//         setActiveMenu,
//         user,
//         setUser,
//         toggleMenu,
//         saveUser,
//         logout,
//       }}
//     >
//       {children}
//     </UserContext.Provider>
//   );
// };


import { useEffect, useState } from "react";
import { UserContext } from "./user";
import Cookies from "js-cookie";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    const cookieData = Cookies.get("user");
    if (cookieData) {
      setUser(JSON.parse(cookieData)); // Parse the cookie data to an object
    }
  }, []);

  const toggleMenu = (value) => {
    setActiveMenu(value);
  };

  const saveUser = (data) => {
    setUser(data);
    Cookies.set("user", JSON.stringify(data), { expires: 1 }); // Store user data as a string in cookie
  };

  const logout = () => {
    setUser(null);
    Cookies.remove("user");
  };

  return (
    <UserContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        user,
        setUser,
        toggleMenu,
        saveUser,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
