import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { BodyBodyMedium } from "../../../utils/styleMethod";

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #E0E0E0",
    "&:focus": {
      borderColor: "none",
    },
  },
}));

export const FormInputDropdown = ({
  name = "name",
  control = {},
  sx,
  options,
  staticValue = options[0]?.value,
  disabled,
  onChange: onChangeProp,
  placeholder,
  disabledItem = <div />,
}) => {
  const generateSingleOptions = (value) => {
    return options.map((option) => (
      <MenuItem key={option.value} selected={option.value === value} value={option.value}>
        <BodyBodyMedium color="var(--on-surface-high)">{option.label}</BodyBodyMedium>
      </MenuItem>
    ));
  };

  return (
    <FormControl variant="standard" sx={sx} size={"small"}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value = staticValue  } }) => {
          const handleChange = (e) => {
            const selectedValue = e.target.value; 
            onChange(selectedValue);
            if (onChangeProp) {
              onChangeProp(selectedValue); 
            }
          };

          return (
            <Select
              displayEmpty
              sx={{ textAlign: "start" }}
              placeholder={placeholder}
              onChange={handleChange}
              value={value}
              disabled={disabled}
              input={<BootstrapInput />}
              IconComponent={(props) => (
                <img
                  {...props}
                  style={{
                    padding: "5px 12px 0 12px",
                    cursor: "pointer",
                    filter: "invert(42%) sepia(78%) saturate(4901%) hue-rotate(124deg) brightness(95%) contrast(92%)",
                  }}
                  src="/icons/chevron-down.svg"
                  alt="chevron-down"
                />
              )}
            >
              {disabledItem}
              {generateSingleOptions(value)}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};