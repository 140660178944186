import { Button } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";
import React from "react";

export default function ArrowRightBtn({ onClick }) {
    return (
        <Button
            className="back-button no-print"
            onClick={onClick}
            style={{
                textDecoration: "none",
                marginLeft:'12px',
                // backgroundColor: "var(--primary-soft-primary-soft)",
                backgroundColor: "var(--state-disabled-disabled-surface)",                
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px", // Adjust padding for circle size
                borderRadius: "50%", // Make it circular
                minWidth: "unset",
                height: "36px", // Set a fixed height for the button
                width: "36px", // Set a fixed width for the button
            }}
        >
            <CaretRight
                size={24}
                style={{ color: "var(--primary-soft-primary-soft-h)" }}
            />
        </Button>
    );
}
