import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { timeIntervals, callFilter } from "../../../utils/method";
import { FormInputDropdown } from "../../form/form-input-dropdown";
import { StatusSpan } from "../../form/span";

// Reusable common cell styles
const commonCellStyles = {
  fontWeight: "bold",
  color: "#153216",
  borderBottom: "none",
  textAlign: "center",
  borderBottom:'1px solid #f2f2f2'
};

// Reusable button styles for the pill-shaped buttons
const buttonStyles = {
  color: "#2e7d32", // Green text color
  borderColor: "#2e7d32", // Green border
  borderRadius: "24px", // Rounded corners for pill shape
  padding: "4px 12px", // Padding to make the button wider
  textTransform: "none", // Disable uppercase transformation
  minWidth: "150px", // Set a consistent width
  fontWeight: "bold", // Bold text
  display: "inline-block", // Align the buttons in a row
};

function OrderTable() {
  const { control } = useForm({});
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 960px)");

  const onchange1 = (values) => {
    console.log("values dropdown --> ", values);
  };

  const rows = [
    {
      time: "2024.05.06 16:30",
      type: "Хуванцар",
      address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
      factory: "TML Plastic",
      driver: "D1208",
      status: "Жолоочид шилжүүлсэн",
      statusColor: "grey",
    },
    {
      time: "2024.05.06 16:30",
      type: "Хуванцар",
      address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
      factory: "TML Plastic",
      driver: "D1208",
      status: "Замдаа гарсан",
      statusColor: "orange",
    },
    {
      time: "2024.05.06 16:30",
      type: "Хуванцар",
      address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
      factory: "TML Plastic",
      driver: "D1208",
      status: "Зогсоолд ирлээ",
      statusColor: "orange",
    },
    {
      time: "2024.05.06 16:30",
      type: "Хуванцар",
      address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
      factory: "TML Plastic",
      driver: "D1208",
      status: "Амжилттай ачигдсан",
      statusColor: "green",
    },
    {
      time: "2024.05.06 16:30",
      type: "Хуванцар",
      address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
      factory: "TML Plastic",
      driver: "D1208",
      status: "Бодит жин бодогдож байна",
      statusColor: "grey",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        padding: "16px 24px 16px 32px",
        marginTop: isSmallScreen ? "16px" : "24px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        width: isSmallScreen ? "350px" : isMediumScreen ? "700px" : "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <p
          className="HeadlineHeadline---Small"
          style={{
            color: "var(--on-surface-high)",
            paddingTop: "8px",
          }}
        >
          Дуудлагын дэлгэрэнгүй
        </p>

        <Box>
          <FormInputDropdown
            control={control}
            name={`callFilter`}
            options={callFilter}
            sx={{ minWidth: "250px", marginRight: "12px" }}
            onChange={(selectedValue) => onchange1(selectedValue)}
          />
          <FormInputDropdown
            control={control}
            name={`timeIntervals`}
            options={timeIntervals}
            sx={{ minWidth: "116px", marginRight: "12px" }}
            onChange={(selectedValue) => onchange1(selectedValue)}
          />
        </Box>
      </Box>
      <p className="LabelLabel---Small" style={{ color: "#368137" }}>
        Бүх төлөв
      </p>
      <TableContainer
        component={Paper}
        sx={{
          paddingTop: "24px",
          marginTop: "24px",
          marginBottom: "64px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Table aria-label="order table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              {[
                "Ачигдах огноо",
                "Дахивар",
                "Ачилтын хаяг",
                "Хүргэх үйлдвэр",
                "Жолооч",
                "Төлөв",
              ].map((header) => (
                <TableCell key={header} sx={commonCellStyles}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "transparent",
                  },
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell sx={commonCellStyles}>{row.time}</TableCell>
                <TableCell sx={commonCellStyles}>
                  <Button variant="outlined" sx={buttonStyles}>
                    {row.type}
                  </Button>
                </TableCell>
                <TableCell sx={commonCellStyles}>{row.address}</TableCell>
                <TableCell sx={commonCellStyles}>{row.factory}</TableCell>
                <TableCell sx={commonCellStyles}>{row.driver}</TableCell>
                <TableCell sx={commonCellStyles}>
                  <StatusSpan label={row.status} statusColor={row.statusColor} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default OrderTable;
