import React, { useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Box, useMediaQuery } from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../form/form-input-dropdown";
import ArrowLeftBtn from "../../form/buttons/arrow-left";
import ArrowRightBtn from "../../form/buttons/arrow-right";
import { months } from "../../../utils/method";

// Register the necessary ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const LineChart = () => {
    const { handleSubmit, control, reset, setValue, watch } = useForm({});

    const chartRef = useRef(null);
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery("(max-width: 960px)");

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current;
            const ctx = chart.ctx;

            const gradient = ctx.createLinearGradient(0, 100, 0, 400);
            gradient.addColorStop(0, "#10933629"); // Dark green (#109336)
            gradient.addColorStop(1, "#FFFFFF2D"); // Light green (#FFFFFF2D)

            chart.data.datasets[0].backgroundColor = gradient;
            chart.update();
        }
    }, []);
    const data = {
        labels: [
            "Шил",
            "Хуванцар",
            "Хөнгөн цагаан",
            "Төмөр, хайлш",
            "Цаас",
            "Хүчлийн батарей",
        ],
        datasets: [
            {
                label: "Дахиврын дэлгэрэнгүй",
                data: [
                    800000, 1200000, 80000, 400000, 1800000, 1000000, 2000001,
                ],
                borderColor: "#2e7d32",
                backgroundColor: "", // Placeholder for gradient
                fill: true,
                pointBackgroundColor: "#2e7d32",
                tension: 0, // Straight line
            },
        ],
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    callback: (value) => value + "₮",
                    stepSize: 400000,
                    color: "#153216", // Change the color of y-axis labels
                    font: {
                        size: 12, // Change the font size of y-axis labels
                        FontFace: "NotoSans",
                    },
                },
            },
            x: {
                ticks: {
                    color: "#153216", // Change the color of x-axis labels
                    font: {
                        size: 12, // Change the font size of x-axis labels
                        FontFace: "NotoSans",
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                titleFont: {
                    size: 14, // Change the font size of tooltip title
                },
                bodyFont: {
                    size: 12, // Change the font size of tooltip body
                },
                borderWidth: 1, // Add border width to tooltip
            },
        },
    };

    const onchange1 = (values) => {
        console.log("values dropdown --> ", values);
    };

    const onClickButton = () => {
        console.log("left button clicked");
    };

    const onClickButtonRight = () => {
        console.log("right button clicked");
    };

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "16px 24px 16px 32px",
                marginTop: isSmallScreen ? "16px" : "24px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                width: isSmallScreen
                    ? "350px"
                    : isMediumScreen
                    ? "700px"
                    : "100%",
            }}
        >
            {/* Title and Controls */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: isSmallScreen ? "column" : "row",
                }}
            >
                <p
                    className="HeadlineHeadline---Small"
                    style={{
                        color: "var(--on-surface-high)",
                        paddingTop:'24px',
                        paddingBottom: isSmallScreen ? "16px" : "24px",
                    }}
                >
                    Худалдан авсан дахиврын дэлгэрэнгүй
                </p>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormInputDropdown
                        control={control}
                        name={"select"}
                        options={months}
                        sx={{
                            minWidth: "100px",
                            marginRight: "12px",
                        }}
                        onChange={(selectedValue) => {
                            onchange1(selectedValue);
                        }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ArrowLeftBtn onClick={() => onClickButton()} />
                        <ArrowRightBtn onClick={() => onClickButtonRight()} />
                    </Box>
                </Box>
            </Box>

            {/* Line Chart */}
            <Box
                sx={{
                    height: isSmallScreen ? "250px" : "400px",
                    width: "100%",
                }}
            >
                <Line ref={chartRef} data={data} options={options} />
            </Box>
        </Box>
    );
};

export default LineChart;
