import React, { useEffect, useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { rows } from "../../../utils/method";
import OrganizationDetail from "../../../components/org-detail";
import UserList from "../../../components/user-list";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";
import PdfModal from "../../../components/modals/PdfModal";

const EnterpriseDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [enterpriseData, setEnterpriseData] = useState({});
    const [filteredEnterpriseUsers, setFilteredEnterpriseUsers] = useState([]);
    const [pdfOpen, setPdfOpen] = useState(false);
    const [certificateList, setCertificateList] = useState([]);

    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        if (id) {
            findByEnterpriseId();
            getCertificateDatas();
        }
    }, []);

    const findByEnterpriseId = async () => {
        try {
            const res = await mainApi(
                `organization/getById/${id}`,
                "GET",
                null
            );
            setEnterpriseData(res);
            const enterpriseUsers = res.users.filter(
                (user) => user.role === "SUHuser"
            );
            setFilteredEnterpriseUsers(enterpriseUsers);
        } catch (error) {
            toast.warning("Өгөгдөл олдсонгүй");
        }
    };

    const addCertifcate = async () => {
        try {
            const res = await mainApi(
                `certificate/calculate/add/${id}`,
                "PUT",
                null
            );
            getCertificateDatas();
        } catch (error) {
            toast.warning("Системд алдаа гарлаа");
        }
    };

    const getCertificateDatas = async () => {
        try {
            const res = await mainApi(
                `certificate/enterprise/${id}`,
                "GET",
                null
            );
            setCertificateList(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Grid2>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "12px",
                }}
            >
                <HeadlineHeadlineMedium color="var(--on-surface-high)">
                    Аж ахуйн нэгжийн дэлгэрэнгүй
                </HeadlineHeadlineMedium>
            </Box>
            {id ? <OrganizationDetail data={enterpriseData} /> : "lodading.."}

            <Box pt={2} backgroundColor="white" mt={2} pb={2}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    width={"100%"}
                    pr={4}
                >
                    <Button
                        sx={{ background: "green", color: "white" }}
                        onClick={() => addCertifcate()}
                    >
                        Батламж нэмэх
                    </Button>
                </Box>
                
                <Box display={'flex'} gap={2} p={2}>
                    {certificateList.map((item, index) => {
                        return (
                            <>
                                <Button
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "1px solid green",
                                    }}
                                    onClick={() => {
                                        setPdfOpen(true);
                                        setSelectedItem(item);
                                    }}
                                >
                                    <img
                                        src="/images/certificate_icon.jpg"
                                        alt="Certificate Icon"
                                        style={{
                                            height: "180px",
                                            objectFit: "fill",
                                        }}
                                    />
                                </Button>
                            </>
                        );
                    })}
                </Box>
            </Box>

            {id ? (
                <UserList
                    type={"Enterprise"}
                    data={filteredEnterpriseUsers}
                    organizationId={enterpriseData?.organizationId}
                />
            ) : (
                "loading..."
            )}
            {pdfOpen ? (
                <PdfModal
                    open={pdfOpen}
                    handleClose={() => setPdfOpen(false)}
                    data={selectedItem}
                />
            ) : null}
        </Grid2>
    );
};

export default EnterpriseDetail;
