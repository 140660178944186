import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider, // Import Divider
} from "@mui/material";
import "./sidebar.css"; // Import the CSS file
import {
  ChartPie,
  Phone,
  Truck,
  UsersThree,
  Buildings,
  CaretDown,
  Factory,
  House,
  MathOperations,
  Recycle,
  NewspaperClipping,
  Exam,
  Question,
  Gear,
  SignOut,
  Barcode,
  User,
} from "@phosphor-icons/react";
import { UserContext } from "../../context/user";

const Sidebar = () => {
  const { user } = useContext(UserContext);

  // Extract roleResource codes
  const allowedResources = user?.roleResource?.map((item) => item.resourceCode) || [];

  // Define menu items with resource codes
  const menuItems = [
    { path: "/", label: "Нүүр", icon: <ChartPie size={18} />, resourceCode: "Dashboard" },
    { path: "/call", label: "Дуудлага", icon: <Phone size={18} />, resourceCode: "CallPage" },
    { path: "/driver", label: "Жолооч", icon: <Truck size={18} />, resourceCode: "DriverPage" },
    { path: "/customer", label: "Хэрэглэгч", icon: <User size={18} />, resourceCode: "CustomerPage" },

    {
      label: "Аж ахуй нэгж",
      icon: <Buildings size={18} />,
      resourceCode: "EnterprisePage",
      path: "/enterprise",
      subMenu: [
        {
          path: "/enterprise/register",
          label: "Бүртгүүлэх хүсэлт",
          resourceCode: "EnterpriseRegister",
        },
      ],
    },
    
    { 
      label: "Цэг",
      icon: <House size={18} />, 
      resourceCode: "DropOffPage",
      path: "/dropoff", 
      subMenu: [
        {
          path: "/dropoff/prepare",
          label: "Бэлтгэх цэг",
          resourceCode: "DropOffPrepare",
        },
      ],
    },
    { path: "/finance", label: "Санхүү", icon: <MathOperations size={18} />, resourceCode: "FinancePage" },
    { path: "/factory", label: "Үйлдвэр", icon: <Factory size={18} />, resourceCode: "FactoryPage" },
    { type: "divider" }, // Divider here
    { path: "/guide", label: "Заавар", icon: <Recycle size={18} />, resourceCode: "GuidePage" },
    { path: "/barcode/list", label: "Баркод", icon: <Barcode size={18} />, resourceCode: "BarcodePage" },
    { path: "/news", label: "Мэдээ", icon: <NewspaperClipping size={18} />, resourceCode: "NewsPage" },
    { type: "divider" }, // Divider here
    { path: "/exam", label: "Тест", icon: <Exam size={18} />, resourceCode: "ExamPage" },
    { path: "/help/feedback", label: "Тусламж", icon: <Question size={18} />, resourceCode: "HelpPage" },
    { path: "/settings/role", label: "Тохиргоо", icon: <Gear size={18} />, resourceCode: "SettingsPage" },
  ];

  // Filter menu items based on allowed resources
  const filteredMenuItems = menuItems.filter((item) =>
    item.type === "divider" || allowedResources.includes(item.resourceCode)
  );

  const [openMenus, setOpenMenus] = useState({});

  const handleMenuClick = (label) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  return (
    <div className="sidebar">
      <List>
        {filteredMenuItems.map((item, index) => {
          if (item.type === "divider") {
            // Render Divider
            return (
              <Divider
                key={`divider-${index}`}
                sx={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  backgroundColor: "#ccc", 
                  height: "1px", // Consistent height
                }}
              />
            );
          }

          if (item.subMenu) {
            return (
              <React.Fragment key={item.label}>
                {/* Main Menu Item */}
                <ListItemButton
                  className={`list-item`}
                  onClick={() => handleMenuClick(item.label)}
                  component={Link}
                  to={item.path}
                >
                  {item.icon}
                  <ListItemText className="list-item-text" primary={item.label} />
                  <CaretDown size={18} />
                </ListItemButton>

                {/* Submenu */}
                <Collapse in={openMenus[item.label]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenu
                      .filter((subItem) => allowedResources.includes(subItem.resourceCode))
                      .map((subItem, subIndex) => (
                        <ListItemButton
                          key={subItem.path}
                          className="list-item collapse-padding"
                          component={Link}
                          to={subItem.path}
                        >
                          <ListItemText className="list-item-text" primary={subItem.label} />
                        </ListItemButton>
                      ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }

          return (
            <ListItemButton
              key={item.path}
              className="list-item"
              component={Link}
              to={item.path}
            >
              {item.icon}
              <ListItemText className="list-item-text" primary={item.label} />
            </ListItemButton>
          );
        })}
      </List>
    </div>
  );
};

export default Sidebar;