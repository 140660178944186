import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Grid2 } from "@mui/material";
import { X } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodySmall,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../utils/styleMethod";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import { mainApi } from "../../api/main";
import { FormInputDropdown } from "../form/form-input-dropdown";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SuccessModal from "../modals/Success";
import AcceptableModal from "../modals/Acceptable";

const today = dayjs();

const initQuery = {
  page: 1,
  limit: 7,
  sort: "desc",
  status: "CALL_TRACKING",
  keyWord: null, // "1",
  searchType: null, // "organization.id"
  total: 0,
};

const AsignToDriverModal = ({
  open,
  handleClose,
  callData,
  timeSlots,
  filteredFactoryDrivers,
  setQuery,
  driver,
}) => {
  const { control } = useForm({});

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState();
  const [filterredAssignTime, setFilterredAssignTime] = useState();
  const [driverId, setDriverId] = useState();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  const transformedDrivers = filteredFactoryDrivers.map((driver) => ({
    value: driver.driverId,
    label: driver.driverCode,
  }));

  // useEffect(() => {
  //   if (open) {
  //     // Reset state when modal opens
  //     setSelectedDate(dayjs());
  //     setSelectedTimeSlot(null);
  //     setSelectedTimeSlotId(null);
  //     setFilterredAssignTime([]);
  //     setDriverId(null);

  //     // Optionally, fetch available time slots for the selected date here
  //     filteredByOnDay();
  //   }
  // }, [open]);

  useEffect(() => {
    filteredByOnDay();
  }, [selectedDate, driverId]);

  const filteredByOnDay = async () => {
    const data = {
      onDay: selectedDate.format("YYYY-MM-DD"),
      driverId: driver ? driver.userId : driverId,
    };
    const res = await mainApi("driver/check-assigned-driver", "POST", data);
    if (res.statusCode === 200) {
      const assignTimeIdsToRemove = res.data.map((item) => item.assignTimeId);

      const filteredAssignTimes = timeSlots.filter(
        (timeSlots) => !assignTimeIdsToRemove.includes(timeSlots.id)
      );

      setFilterredAssignTime(filteredAssignTimes);
    }
  };

  const handleTimeClick = (time) => {
    setSelectedTimeSlot((prev) => (prev === time.name ? null : time.name));
    setSelectedTimeSlotId((prev) => (prev === time.id ? null : time.id));
  };

  const asignButton = async () => {
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    
    if (!formattedDate) {
      toast.warning("Сонгосон огноо шаардлагатай.");
    } else if (!selectedTimeSlot) {
      toast.warning("Цагийн зурвас сонгох шаардлагатай.");
    } else if (!selectedTimeSlotId) {
      toast.warning("Цагийн зурвас ID шаардлагатай.");
    } else if (!driverId && !driver) {
      toast.warning("Жолооч сонгох шаардлагатай.");
    } else {
      
      const assignmentData = {
        selectedDate: formattedDate,
        selectedTimeSlotId: selectedTimeSlotId,
        driverId: driver ? driver.userId : driverId,
        callId: callData.callId,
      };

      try {
        const res = await mainApi("driver/assign", "POST", assignmentData);
        
        if (res.statusCode === 200) {
          setOpenSuccessModal(true);
          setQuery(initQuery);
          handleClose();

          setSelectedDate(dayjs());
          setSelectedTimeSlot(null);
          setSelectedTimeSlotId(null);
          setFilterredAssignTime([]);
          setDriverId(null);
        }
      } catch (error) {}
    }
  };

  const onChangeSelect = (selectedDriverId) => setDriverId(selectedDriverId);
  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const closeAcceptableModal = () => {
    setAcceptableModalOpen(false);
  };

  const cancelCall = async () => {
    try {
      const res = await mainApi(`call/cancel/${callData.callId}`, "GET", null);

      if (res) {
        setQuery(initQuery);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {callData && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 650,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <BodyBodySmall color="var(--on-surface-high)">
              {callData.createdDate}
            </BodyBodySmall>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              width={"100%"}
            >
              <TitleTitleLarge color="var(--on-surface-high)">
                Дуудлагын дэлгэрэнгүй
              </TitleTitleLarge>
              <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box display="flex" mb={4}>
              <img
                src={callData.imageUrl || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBcmFc-564fzdVaz-txpjNyohU_YP3S78VAg&s"}
                alt="Recycling"
                style={{
                  width: "192px",
                  height: "160px",
                  borderRadius: "4px",
                  marginRight: "16px",
                  objectFit:'cover'
                }}
              />
              <Box>
                <Box display="flex">
                  <Box mr={8}>
                    <BodyBodySmall
                      color="var(--on-surface-high)"
                      sx={{ mb: 1 }}
                    >
                      Дахивар:
                    </BodyBodySmall>
                    <div
                      style={{
                        border: "2px solid #A9DBAA",
                        borderRadius: "12px",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                      }}
                    >
                      <BodyBodyLarge color="var(--primary-primary)">
                        {callData.itemsGroupName}
                      </BodyBodyLarge>
                    </div>
                  </Box>
                  <Box>
                    <BodyBodySmall
                      color="var(--on-surface-high)"
                      sx={{ mb: 1 }}
                    >
                      Туслах үйлдвэр:
                    </BodyBodySmall>
                    <TitleTitleMedium color="var(--on-surface-high)">
                      {callData.factoryName}
                    </TitleTitleMedium>
                  </Box>
                </Box>
                <Box
                  mt={2}
                  style={{
                    backgroundColor: "var(--surface-primary)",
                    padding: "12px",
                    borderRadius: "6px",
                  }}
                >
                  <BodyBodySmall color="var(--on-surface-high)">
                    Дуулга олгосон хэрэглэг:
                  </BodyBodySmall>
                  <TitleTitleLarge color="var(--on-surface-high)">
                    {callData.phoneNumber}
                  </TitleTitleLarge>
                </Box>
              </Box>
            </Box>

            <Box display={"flex"} justifyContent={"space-between"}>
              <Button
                onClick={() => setAcceptableModalOpen(true)}
                sx={{
                  backgroundColor: "#F9DEDC",
                  color: "#B3261E",
                  marginBottom: "24px",
                }}
              >
                Татгалзах
              </Button>
              <BodyBodySmall color="var(--on-surface-high)"></BodyBodySmall>

              {driver ? (
                driver.driverCode
              ) : (
                <FormInputDropdown
                  control={control}
                  name={`asc`}
                  staticValue={"сонгох"}
                  placeholder="жолооч сонгох"
                  options={transformedDrivers}
                  sx={{ minWidth: "150px", marginRight: "12px" }}
                  onChange={(selectedValue) => onChangeSelect(selectedValue)}
                />
              )}
            </Box>

            <Box sx={{ border: "1px solid #ccc", borderRadius: "8px" }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
                width="100%"
              >
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid2
                      container
                      columns={{ xs: 1, lg: 2 }}
                      spacing={4}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid2>
                        <DateCalendar
                          date={selectedDate}
                          onChange={(newDate) => setSelectedDate(newDate)}
                          disablePast
                        />
                      </Grid2>

                      <Grid2>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          mr={4}
                        >
                          <BodyBodySmall>Боломжит цаг</BodyBodySmall>

                          <Box
                            display="flex"
                            flexDirection="column"
                            mt={1}
                            sx={{
                              maxHeight: "250px",
                              overflowY: "auto",
                              width: "180px",
                              paddingRight: "8px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {filterredAssignTime.map((time, index) => (
                              <Button
                                key={index}
                                variant="outlined"
                                onClick={() => handleTimeClick(time)}
                                sx={{
                                  backgroundColor:
                                    selectedTimeSlot === time.name
                                      ? "#4caf50"
                                      : "#fff",
                                  color:
                                    selectedTimeSlot === time.name
                                      ? "#fff"
                                      : "#4caf50",
                                  border: "1px solid #4caf50",
                                  marginBottom: "8px",
                                  width: "100px",
                                }}
                              >
                                {time.name}
                              </Button>
                            ))}
                          </Box>
                        </Box>
                      </Grid2>
                    </Grid2>
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
            <Box width={"100%"}>
              <Button
                onClick={asignButton}
                variant="contained"
                color="success"
                sx={{ width: "100%", mt: 2 }}
              >
                Жолоочид хуваарилах
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      <SuccessModal
        title={``}
        content={`${
          filteredFactoryDrivers.find((driver) => driver.driverId === driverId)
            ?.driverCode
        }  дугаар бүхий шинэ жолооч амжилттай нэмэгдлээ`}
        open={openSuccessModal}
        handleClose={closeSuccessModal}
      />
      <AcceptableModal
        title={""}
        content={`${callData?.phoneNumber} бүртгэл бүхий хэрэглэгчийн дуудлагаас татгалзахдаа та итгэлтэй байна уу?`}
        open={acceptableModalOpen}
        handleClose={closeAcceptableModal}
        deleteApi={() => cancelCall()}
        successTitle={`${callData?.phoneNumber} бүртгэл бүхий хэрэглэгчийн дуудлагаас татгалзлаа`}
      />
    </>
  );
};

export default AsignToDriverModal;
