import React, { useState } from "react";
import { Dialog, Box, Button, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";

const initialCenter = { lat: 47.916438, lng: 106.9088411 };
const containerStyle = {
  width: "100%",
  height: "600px",
};

const GoogleMapLocationMapModal = ({ open, handleClose, setAddressInfo }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState("");
  const [latLng, setLatLng] = useState({ lat: null, lng: null }); // State to store lat/lng

  const handleMapClick = async (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    // Set the marker position and lat/lng
    setMarker({ lat, lng });
    setLatLng({ lat, lng }); // Store lat/lng

    // Fetch address using Geocoding API
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY; // Your Google Maps API key
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );

    if (response.data.results && response.data.results.length > 0) {
      const formattedAddress = response.data.results[0].formatted_address;
      setAddress(formattedAddress); // Update the address state
    } else {
      setAddress("Address not found");
    }
  };

  const handleConfirmAddress = () => {
    // You can perform any action with the selected address and lat/lng here
    console.log("Selected address:", address);
    console.log("Latitude:", latLng.lat);
    console.log("Longitude:", latLng.lng);
    const addressData = {
      address: address,
      lat: latLng.lat,
      lng: latLng.lng,
    };
    setAddressInfo(addressData);
    handleClose(); // Close the modal
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "1000px", // Desired width
          maxWidth: "95vw", // Responsive maximum width
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="end" width="100%">
          <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
            <X size={24} color="#4caf50" />
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
          width="100%"
          mb={2}
        >
          <HeadlineHeadlineMedium>
            Хэрэглэгчийн хаягын мэдээлэл авах
          </HeadlineHeadlineMedium>

          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmAddress}
            disabled={!address}
          >
            Сонгох
          </Button>
        </Box>
        <div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={initialCenter}
            zoom={14}
            onClick={handleMapClick}
            options={{ disableDefaultUI: true }}
          >
            {marker && <Marker position={marker} />}
          </GoogleMap>
        </div>
        {address && (
          <Typography variant="h6" align="center" mt={2}>
            Address: {address}
          </Typography>
        )}
        {latLng.lat !== null && latLng.lng !== null && (
          <Typography variant="body1" align="center" mt={1}>
            Latitude: {latLng.lat}, Longitude: {latLng.lng}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default GoogleMapLocationMapModal;
