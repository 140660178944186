import React, { useContext } from "react";
import { Grid2, Typography } from "@mui/material";
import { UserContext } from "../../../context/user";

const Barcode = () => {
  const { user, setUser } = useContext(UserContext);
  
  return (
    <Grid2
      sx={{
        height: "68vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      container
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        Barcode page
      </Typography>
    </Grid2>
  );
};

export default Barcode;
