// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom-input.css */
.custom-input {
    border: 1px solid #a9dbaa;
    border-radius: 8px;
    padding: 12px 8px 12px 20px;
    font-size: 16px;
    
    line-height: 20px;
    transition: border-color 0.3s ease-in-out; /* Smooth transition for focus */
  }
  
  .custom-input:focus {
    border-color: #4caf50; /* Changes border color on focus */
    outline: none; /* Removes default browser outline */
  }
  
  .custom-input.error {
    border-color: red; /* Shows red border when there's an error */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/form/input/custom-input.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,eAAe;;IAEf,iBAAiB;IACjB,yCAAyC,EAAE,gCAAgC;EAC7E;;EAEA;IACE,qBAAqB,EAAE,kCAAkC;IACzD,aAAa,EAAE,oCAAoC;EACrD;;EAEA;IACE,iBAAiB,EAAE,2CAA2C;EAChE","sourcesContent":["/* custom-input.css */\n.custom-input {\n    border: 1px solid #a9dbaa;\n    border-radius: 8px;\n    padding: 12px 8px 12px 20px;\n    font-size: 16px;\n    \n    line-height: 20px;\n    transition: border-color 0.3s ease-in-out; /* Smooth transition for focus */\n  }\n  \n  .custom-input:focus {\n    border-color: #4caf50; /* Changes border color on focus */\n    outline: none; /* Removes default browser outline */\n  }\n  \n  .custom-input.error {\n    border-color: red; /* Shows red border when there's an error */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
