import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid2,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { UserContext } from "../../../context/user";
import {
    HeadlineHeadlineMedium,
    tableBodyCellStyle,
    tableHeaderStyle,
} from "../../../utils/styleMethod";
import { ArrowRight, Plus } from "@phosphor-icons/react";
import { mainApi } from "../../../api/main";
import RequestDetailModal from "./request-detail-modal";

const initialQuery = {
    page: 1,
    limit: 8,
    total: 0,
    type: "SUH",
    itemGroupId: 0,
};
const EnterpriseRegister = () => {
    const { user, setUser } = useContext(UserContext);
    const [data, setData] = useState([]);
    const classessCell = tableBodyCellStyle();
    const classessHeader = tableHeaderStyle();
    const [query, setQuery] = useState(initialQuery);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    useEffect(() => {
        requestedList();
    }, []);

    const requestedList = async () => {
        const res = await mainApi("organization/requested-list", "POST", query);
        setData(res.content);
        setQuery((prevQuery) => {
            if (prevQuery.total !== res.totalElements) {
                return { ...prevQuery, total: res.totalElements };
            }
            return prevQuery;
        });
    };

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setQuery({ ...query, page: newPage });
    };

    const onSelect = async (item) => {
        setOpenDetailModal(true);
        const res = await mainApi(`organization/getById/${item.id}`,"GET", null) 
        setSelectedItem(res);
    };

    return (
        <Grid2>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "24px",
                    //   flexDirection: isSmallScreen ? "column" : "row",
                }}
            >
                <HeadlineHeadlineMedium color="var(--on-surface-high)">
                    ААН бүртгүүлэх хүсэлт
                </HeadlineHeadlineMedium>
                {/* <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#2e7d32",
                        borderRadius: "24px",
                        padding: "8px 24px",
                        textTransform: "none",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#1b5e20",
                        },
                    }}
                    startIcon={<Plus />}
                    // onClick={() => setOpenOrganizationModal(true)}
                >
                    ААН нэмэх
                </Button> */}
            </Box>

            <Table aria-label="order table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                        {[
                            "Огноо",
                            "ААН",
                            "Холбоо барих",
                            "Хаяг",
                            "Шийдвэрлэх",
                        ].map((header) => (
                            <TableCell
                                key={header}
                                className={classessHeader.tableHeader}
                            >
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow
                            key={index}
                            className={index % 2 === 1 ? "table-row-odd" : ""}
                        >
                            <TableCell className={classessCell.tableBodyCell}>
                                {row.createdDate}
                            </TableCell>
                            <TableCell className={classessCell.tableBodyCell}>
                                {row.name}
                            </TableCell>
                            <TableCell className={classessCell.tableBodyCell}>
                                {row.adminPhoneNumber}
                            </TableCell>
                            <TableCell className={classessCell.tableBodyCell}>
                                {row.address}
                            </TableCell>
                            <TableCell className={classessCell.tableBodyCell}>
                                <Button
                                    onClick={() => {
                                        onSelect(row);
                                    }}
                                >
                                    Дэлгэрэнгүй <ArrowRight></ArrowRight>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={Math.ceil(query.total / query.limit)}
                    page={query.page}
                    onChange={handleChangePage}
                    siblingCount={1}
                    boundaryCount={1}
                />
            </Box>
            <RequestDetailModal
                open={openDetailModal}
                handleClose={() => setOpenDetailModal(false)}
                data={selectedItem}
            />
        </Grid2>
    );
};

export default EnterpriseRegister;
