import React, { useContext } from "react";
import { Grid2, Typography } from "@mui/material";
import { UserContext } from "../context/user";
import Dashboard from "../components/home/dashboard/DashBoard";
import LineChart from "../components/home/line-chart/LineChart";
import OrderTable from "../components/home/order-table/OrderTable";


const HomePage = () => {
  const { user, setUser } = useContext(UserContext);

  const clickBtn = () => {
    setUser("hello ");
  };

  const clickGet = () => {};

  return (
    <Grid2>
      <p className="HeadlineHeadline---Medium" style={{color:"var(--on-surface-high)"}}>Нүүр</p>
      <Dashboard />
      <LineChart />
      <OrderTable />
      
    </Grid2>
  );
};

export default HomePage;
