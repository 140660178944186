import React, { useState } from "react";
import { Autocomplete, TextField, Checkbox, ListItemText } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";

const MyAutocomplete = ({ options, placeholder, defaultValue , setItem }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleToggle = (item) => {
    const currentIndex = selectedItems.indexOf(item);
    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }
    setSelectedItems(newSelectedItems);
    // setItem(newSelectedItems)
  };

  return (
    <Autocomplete
      freeSolo
      options={options.map((option) => option.name)} // Extracting names from the options array
      inputValue={defaultValue}
      onInputChange={(event, value) => setItem(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <MagnifyingGlass size={24} style={{ marginRight: 8 }} />
            ),
          }}
        />
      )}
      sx={{ mb: 2, width: "365px" }}
      renderOption={(props, option) => {
        const selectedItem = options.find((item) => item.name === option);
        return (
          <li {...props} key={option}>
            <ListItemText primary={option} />
            <Checkbox
              checked={selectedItems.indexOf(selectedItem) !== -1}
              onChange={() => handleToggle(selectedItem)}
            />
          </li>
        );
      }}
    />
  );
};

export default MyAutocomplete;
