export const handleSection = (section) => {
  var parts = section.split("/");
  const id = parts[parts.length - 1];
  const slug = parts[parts.length - 2];

  switch (section) {
    case "/":
      return "Нүүр хуудас";
    case "/call":
      return "Дуудлага";
    case `/driver`:
      return "Жолооч";
    case `/driver/detail/${id}`:
      return "Жолооч / Жолоочийн дэлгэрэнгүй";
    case `/driver/detail/location/${id}`:
      return "Жолооч / Байршил хянах";
    case `/customer`:
      return "Хэрэглэгч";
    case `/customer/detail/${id}`:
      return "Хэрэглэгч / Хэрэглэгчийн дэлгэрэнгүй";
    case `/enterprise`:
      return "Аж ахуй нэгж";
    case `/enterprise/detail/${id}`:
      return "Аж ахуй нэгж  /  Аж ахуй нэгжийн дэлгэрэнгүй";
    case `/enterprise/register`:
      return "Аж ахуй нэгж / Бүртгүүлэх хүсэлт";
    case `/factory`:
      return "Үйлдвэр";
    case `/factory/detail/${id}`:
      return "Үйлдвэр / Дэлгэрэнгүй";
    case `/dropoff`:
      return "Цэг";
    case `/dropoff/detail/${id}`:
      return "Цэг  /  Цэгийн дэлгэрэнгүй";
    case `/dropoff/prepare`:
      return "Цэг / Бэлтгэх цэг";
    case `/finance`:
      return "Санхүү";
    case `/guide`:
      return "Заавар";
    case `/guide/${id}`:
      return "Заавар";
    case `/guide/detail/${slug}/${id}`:
      return "Заавар / Зааврын дэлгэрэнгүй";
    case `/guide/barcode`:
      return "Заавар / Баркод";
    case `/news`:
      return "Мэдээ";
    case `/exam`:
      return "Тест";
    case `/help`:
      return "Тусламж";
    case `/profile`:
      return "Админ профайл";
    case `/settings`:
      return "Тохиргоо";
  }
};
export const months = [
  {
    label: "1 сар",
    value: 1,
  },
  {
    label: "2 сар",
    value: 2,
  },
  {
    label: "3 сар",
    value: 3,
  },
  {
    label: "4 сар",
    value: 4,
  },
  {
    label: "5 сар",
    value: 5,
  },
  {
    label: "6 сар",
    value: 6,
  },
  {
    label: "7 сар",
    value: 7,
  },
  {
    label: "8 сар",
    value: 8,
  },
  {
    label: "9 сар",
    value: 9,
  },
  {
    label: "10 сар",
    value: 10,
  },
  {
    label: "11 сар",
    value: 11,
  },
  {
    label: "12 сар",
    value: 12,
  },
];
export const callFilter = [
  {
    label: "Хуучин нь эхэндээ",
    value: "asc",
  },
  {
    label: "Шинэ нь эхэндээ",
    value: "desc",
  },
  // {
  //   label: "Замдаа гарсан",
  //   value: "onTheWay",
  // },
  // {
  //   label: "Зогсоолд ирлээ",
  //   value: "arrivedAtParking",
  // },
  // {
  //   label: "Амжилттай ачигдсан",
  //   value: "successfullyLoaded",
  // },
  // {
  //   label: "Бодит жин бодогдож байна",
  //   value: "calculatingActualWeight",
  // },
];

export const userStatus = [
  {
    label: "Апп ашигладаг",
    value: "uses_app",
  },
  {
    label: "Апп ашигладаггүй",
    value: "does_not_use_app",
  },
  {
    label: "Сүүлд бүртгүүлсэн",
    value: "registered_last",
  },
  {
    label: "Эхэнд бүртгүүлсэн",
    value: "registered_first",
  },
  {
    label: "Хамгийн бага дуудлага өгсөн",
    value: "least_calls",
  },
];

export const timeIntervals = [
  {
    label: "Өнөөдөр",
    value: "today",
  },
  {
    label: "7 хоног",
    value: "week",
  },
  {
    label: "Сар",
    value: "month",
  },
  {
    label: "Улирал",
    value: "quarter",
  },
  {
    label: "Жил",
    value: "year",
  },
];

export const role = [
  {
    label: "Туслах админ",
    value: "1",
  },
  {
    label: "Туслах админ",
    value: "2",
  },
  {
    label: "Үйлдвэрийн ажилтан",
    value: "3",
  },
];

export const rows = [
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар 2222 ",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  // Add more rows here as needed
];

export const transferToDriverData = [
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Дуудлага хянах",
    statusColor: "green",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Замдаа гарсан",
    statusColor: "orange",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Зогсоолд ирлээ",
    statusColor: "red",
  },
  {
    time: "2024.05.06 16:30",
    type: "Хуванцар",
    address: "Баянзүрх дүүрэг, Улаанхуаран 56-3-15",
    factory: "TML Plastic",
    driver: "D1208",
    status: "Жолоочид шилжүүлсэн",
    statusColor: "grey",
  },
];

export const driverData = [
  {
    driver_id: "D1525",
    phone: "88888888",
    name: "Byambaa",
    registered_company: "GoRecycle",
    number_of_calls: 870,
    loading_direction: "BGD 2,3,4,5 district",
    call_status: "Дуудлагатай",
  },
  {
    driver_id: "D1525",
    phone: "88888888",
    name: "Byambaa",
    registered_company: "GoRecycle",
    number_of_calls: 870,
    loading_direction: "BGD 2,3,4,5 district",
    call_status: "Дуудлагагүй",
  },
  {
    driver_id: "D1525",
    phone: "88888888",
    name: "Byambaa",
    registered_company: "Lorem ipsum",
    number_of_calls: 870,
    loading_direction: "BGD 2,3,4,5 district",
    call_status: "Дуудлагагүй",
  },
  {
    driver_id: "D1525",
    phone: "88888888",
    name: "Byambaa",
    registered_company: "GoRecycle",
    number_of_calls: 870,
    loading_direction: "BGD 2,3,4,5 district",
    call_status: "Дуудлагатай",
  },
  {
    driver_id: "D1526",
    phone: "99999999",
    name: "Batbayar",
    registered_company: "GreenRecycle",
    number_of_calls: 500,
    loading_direction: "BGD 1,2,3,4 district",
    call_status: "Дуудлагагүй",
  },
  {
    driver_id: "D1527",
    phone: "77777777",
    name: "Erdene",
    registered_company: "CleanTech",
    number_of_calls: 620,
    loading_direction: "BGD 5,6,7 district",
    call_status: "Дуудлагатай",
  },
  {
    driver_id: "D1528",
    phone: "66666666",
    name: "Altangerel",
    registered_company: "GoWaste",
    number_of_calls: 900,
    loading_direction: "BGD 3,4,5,6 district",
    call_status: "Дуудлагагүй",
  },
  {
    driver_id: "D1529",
    phone: "55555555",
    name: "Togtokhbayar",
    registered_company: "WasteSolutions",
    number_of_calls: 750,
    loading_direction: "BGD 1,2,6,7 district",
    call_status: "Дуудлагатай",
  },
];

export const driverFilter = [
  {
    label: "Дуудлагатай жолооч",
    value: "WITH_CALLS",
  },
  {
    label: "Дуудлагагүй жолооч",
    value: "WITHOUT_CALLS",
  },
  {
    label: "Хамгийн их дуудлага авсан",
    value: "MOST_CALLS",
  },
  {
    label: "Хамгийн бага дуудлага авсан",
    value: "LEAST_CALLS",
  },
  {
    label: "GoRecycle -ийн жолооч",
    value: "GORECYCLE_DRIVER",
  },
  {
    label: "Бусад үйлдвэрийн жолооч",
    value: "OTHER_FACTORY_DRIVER",
  },
  {
    label: "Сүүлд бүртгүүлсэн",
    value: "LATEST_REGISTERED",
  },
  {
    label: "Эхэнд бүртгүүлсэн",
    value: "EARLIEST_REGISTERED",
  },
];

export const generateCode = () => {
  const letter = "D";
  const number = String(Math.floor(Math.random() * 1000)).padStart(3, "0");
  return `${letter}${number}`;
};
