import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  DisplayMedium,
  DisplaySmall,
  HeadlineHeadlineMedium,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../utils/styleMethod";
import { mainApi } from "../../api/main";
import { NewCallTable } from "../call/new-call-table";

const initQuery = {
  page: 1,
  limit: 6,
  sort: "desc",
  status: "CALL_TRACKING",
  keyWord: null,
  searchType: null,
  total: 0,
};

const NewAssignCallModal = ({ open, handleClose, driverId }) => {
  const [driverData, setDriverData] = useState();
  const [query, setQuery] = useState(initQuery);
  const [data, setData] = useState([]);

  useEffect(() => {
    getDriverItem();
  }, []);

  useEffect(() => {
    callList();
  }, [query]);

  const callList = async () => {
    try {
      const res = await mainApi("call/list", "POST", query);
      setData(res.content);
      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery;
      });
    } catch (error) {
      console.log("error");
    }
  };

  const getDriverItem = async () => {
    const res = await mainApi(`driver/info/${driverId}`, "GET", null);
    setDriverData(res.data);
  };

  console.log("modal orloo ");
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height:'95vh',
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <BodyBodySmall color="var(--on-surface-high)"></BodyBodySmall>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            width={"100%"}
          >
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
          <Box alignItems={"center"} display={"flex"} textAlign={"center"} width={'100%'} justifyContent={'center'}>
            <HeadlineHeadlineMedium color="var(--on-surface-high)">
              {driverData?.driverCode} жолоочид дуудлага хуваарьлах
            </HeadlineHeadlineMedium>
          </Box>
          <NewCallTable
            data={data}
            query={query}
            setQuery={setQuery}
            driver={driverData}
          />
        </Box>
      </Modal>
    </>
  );
};

export default NewAssignCallModal;
