import { Box, Grid2 } from "@mui/material";

const StatCard = ({
    title,
    value,
    icon,
    comparison,
    comparisonValue,
    comparisonIcon,
}) => (
    <Grid2 xs={12} sm={6} md={4}>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                paddingTop: "16px",
                paddingRight: "24px",
                paddingLeft: "24px",
                borderRadius: "8px",
                height: "156px",
                width: "275px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                position: "relative",
            }}
        >
            <Box>
                <p
                    className="BodyBody---Large"
                    style={{ color: "var(--on-surface-high)" }}
                >
                    {title}
                </p>
                <p
                    className="HeadlineHeadline---Medium"
                    style={{ color: "var(--on-surface-high)" }}
                >
                    {value}
                </p>
            </Box>

            {/* Icon in a circle */}
            <Box
                sx={{
                    position: "absolute",
                    top: "32px",
                    right: "16px",
                    backgroundColor: icon.bgColor,
                    p: "10px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {icon.component}
            </Box>

            {/* Comparison section */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <p
                    className="BodyBody---Large"
                    style={{ color: "var(--on-surface-medium-neutral)" }}
                >
                    {comparison}
                </p>
                <p
                    className="BodyBody---Large"
                    style={{ paddingLeft: "8px", color: comparisonValue.color }}
                >
                    {comparisonValue.text} {comparisonIcon}
                </p>
            </Box>
        </Box>
    </Grid2>
);

export default StatCard;
