

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Routing from "./routes/Routing";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#368137", // --color-brand-50
      light: "#c5e7c6", // --color-brand-95
      dark: "#153216", // --color-brand-10
      contrastText: "#fff", // --color-brand-100
    },
    secondary: {
      main: "#1d1d1d", // --color-neutral-10
      light: "#f7f7f7", // --color-neutral-95
      dark: "#0a0a0a", // --color-neutral-00
      contrastText: "#fff", // --color-neutral-100
    },
    error: {
      main: "#dc362e", // --color-error-50
      light: "#fdf2f2", // --color-error-95
      dark: "#410e0b", // --color-error-10
      contrastText: "#fff", // --color-error-100
    },
    warning: {
      main: "#ea9708", // --color-warning-60
      light: "#fee8c3", // --color-warning-95
      dark: "#421e06", // --color-warning-10
      contrastText: "#fff", // --color-warning-100
    },
    success: {
      main: "#16a34a", // --color-success-50
      light: "#bbf7d0", // --color-success-90
      dark: "#052e16", // --color-success-10
      contrastText: "#fff", // --color-success-100
    },
    background: {
      default: "#f7f7f7", // --color-neutral-95
      paper: "#fff", // --color-neutral-100
    },
    text: {
      primary: "#1d1d1d", // --color-neutral-10
      secondary: "#6c6c6c", // --color-neutral-50
      disabled: "#bababa", // --color-neutral-80
    },
    divider: "#e46962", // --stroke-error
    // action: {
    //   active: "#368137", // --color-brand-50
    //   hover: "#c5e7c6", // --color-brand-95
    //   selected: "#a9dbaa", // --color-brand-90
    //   disabled: "#f7f7f7", // --color-neutral-95
    //   disabledBackground: "#f2f2f2", // --color-neutral-90
    // },
  },
  typography: {
    fontFamily: '"NotoSans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: 800, // NotoSans-ExtraBold
    },
    h2: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: 600, // NotoSans-SemiBold
    },
    h3: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: "bold", // NotoSans-Bold
    },
    body1: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: "normal", // NotoSans-Regular
    },
    body2: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: 500, // NotoSans-DisplayMedium
    },
    button: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: 600, // NotoSans-SemiBold
    },
    caption: {
      fontFamily: '"NotoSans", sans-serif',
      fontWeight: "normal",
    },
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 500,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="top-right" autoClose={3000} theme="light" />
      <Routing />
    </ThemeProvider>
  );
}

export default App;
