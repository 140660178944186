import { Grid2 } from "@mui/material";
import {
    Users,
    ArrowUpRight,
    Phone,
    ArrowDownRight,
    Money,
    Wallet,
    Buildings,
    Barcode,
    EnvelopeOpen,
} from "@phosphor-icons/react";

import StatCard from "./StatCard";

const Dashboard = () => (
    <Grid2 container spacing={2}>
        <StatCard
            title="Шинэ хэрэглэгч"
            value="40,689"
            icon={{
                component: (
                    <Users
                        size={24}
                        weight="duotone"
                        color="var(--on-surface-high)"
                    />
                ),
                bgColor: "#c5e7c6",
            }}
            comparison="Өчигдрөөс"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />
        <StatCard
            title="Дахиврын дуудлага"
            value="26"
            icon={{
                component: (
                    <Phone
                        size={24}
                        weight="duotone"
                        color="var(--brand-2-on-primary-h)"
                    />
                ),
                bgColor: "var(--brand-2-soft-primary-soft)",
            }}
            comparison="Сүүлийн 7 хоногт"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />
        <StatCard
            title="Худалдаж авсан дахивар"
            value="6'574'891₮"
            icon={{
                component: (
                    <Money
                        size={24}
                        weight="duotone"
                        color="var(--secondary-secondary)"
                    />
                ),
                bgColor: "var(--color-neutral-95)",
            }}
            comparison="Энэ сард"
            comparisonValue={{ text: "8.5%", color: "var(--error-error)" }}
            comparisonIcon={
                <ArrowDownRight
                    size={24}
                    weight="regular"
                    color="var(--error-error)"
                />
            }
        />

        <StatCard
            title="Тараагдсан оноо"
            value="6'793 оноо"
            icon={{
                component: (
                    <Wallet
                        size={24}
                        weight="duotone"
                        color="var(--error-error)"
                    />
                ),
                bgColor: "var(--color-error-95)",
            }}
            comparison="Энэ сард"
            comparisonValue={{ text: "8.5%", color: "var(--error-error)" }}
            comparisonIcon={
                <ArrowDownRight
                    size={24}
                    weight="regular"
                    color="var(--error-error)"
                />
            }
        />
        <StatCard
            title="Байгууллагын хүсэлт"
            value="23"
            icon={{
                component: (
                    <Buildings
                        size={24}
                        weight="duotone"
                        color="var(--brand-2-primary)"
                    />
                ),
                bgColor: "var(--surface-brand-2)",
            }}
            comparison="Энэ сард"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />

        <StatCard
            title="Ангилах хүсэлт"
            value="2"
            icon={{
                component: (
                    <Barcode
                        size={24}
                        weight="duotone"
                        color="var(--success-error)"
                    />
                ),
                bgColor: "var(--primary-soft-primary-soft)",
            }}
            comparison="Энэ сард"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />
        <StatCard
            title="Санал хүсэлт, гомдол"
            value="2"
            icon={{
                component: (
                    <EnvelopeOpen
                        size={24}
                        weight="duotone"
                        color="var(--error-soft-on-error-soft-h)"
                    />
                ),
                bgColor: "var(--error-on-error-m)",
            }}
            comparison="Энэ сард"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />
    </Grid2>
);

export default Dashboard;
