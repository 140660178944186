import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Grid2,
} from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { X } from "@phosphor-icons/react"; // Import the close icon from phosphor-icons
import { BodyBodyLarge, HeadlineHeadlineMedium } from "../../utils/styleMethod";
import { FormInputDropdown } from "../form/form-input-dropdown";
import { useForm } from "react-hook-form";
import { role } from "../../utils/method";
import CustomInput from "../form/input";

const AddEmployeeModal = ({ open, handleClose }) => {
  const [contact, setContact] = React.useState("");
  const [contactValue, setContactValue] = useState('')
  const { control } = useForm({});
  
  const handleInputChange = (value) => {
    setContactValue(value);
  };

  const onchange1 = (values) => {
    console.log("values dropdown --> ", values);
  };
    
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 450, // Updated width
          height: 650, // Updated height
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          // justifyContent: 'center',
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          justifyContent="end"
          alignItems="center"
          width="100%"
        >
          <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
            <X size={24} color="#4caf50" />
          </Button>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          mb={2}
        >
          <HeadlineHeadlineMedium>Ажилтан нэмэх</HeadlineHeadlineMedium>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          width="100%"
          mb={3}
        >
          <BodyBodyLarge>
            Та үйлдвэрийн админ, туслах админ, үйлдвэрийн ажилтан гэсэн 3
            зэрэглэлээс өөрийн шаардлагатайг сонгоорой
          </BodyBodyLarge>
        </Box>

        <Grid2 container spacing={2} justifyContent="center" mt={2}>
          <Grid2  xs={12}>
            <FormInputDropdown
              control={control}
              name={`role`}
              options={role}
              sx={{ minWidth: "250px" }}
              onChange={(selectedValue) => onchange1(selectedValue)}
            />
          </Grid2>

          <Grid2  xs={12}>
            <CustomInput  handleInputChange={handleInputChange} style={{ width: '250px', height: '30px' }}/>
          </Grid2>

          <Grid2 xs={12}>
            <Button fullWidth variant="contained" color="success" onClick={()=> {alert(`Entered value: ${contactValue}`);}}>
              Админаар нэмэх
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    </Modal>
  );
};

export default AddEmployeeModal;
