import React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  DisplayMedium,
  DisplaySmall,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../utils/styleMethod";
import { Data } from "@react-google-maps/api";

const CallDetailModal = ({ open, handleClose, callData }) => {
  
  return (
    <>
      {callData && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <BodyBodySmall color="var(--on-surface-high)">
              {callData.createdDate}
            </BodyBodySmall>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              width={"100%"}
            >
              <TitleTitleLarge color="var(--on-surface-high)">
                Дуудлагын дэлгэрэнгүй
              </TitleTitleLarge>
              <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box display="flex" mb={4}>
              <img
                src={`${callData.imageUrl || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBcmFc-564fzdVaz-txpjNyohU_YP3S78VAg&s" } ` }
                alt="Recycling"
                style={{
                  width: "192px",
                  height: "auto",
                  borderRadius: "4px",
                  marginRight: "16px",
                }}
              />
              <Box>
                <Box display="flex">
                  <Box mr={8}>
                    <BodyBodySmall
                      color="var(--on-surface-high)"
                      sx={{ mb: 1 }}
                    >
                      Дахивар:
                    </BodyBodySmall>
                    <div
                      style={{
                        border: "2px solid #A9DBAA",
                        borderRadius: "12px",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                      }}
                    >
                      <BodyBodyLarge color="var(--primary-primary)">
                        {callData.itemsGroupName}
                      </BodyBodyLarge>
                    </div>
                  </Box>
                  <Box>
                    <BodyBodySmall
                      color="var(--on-surface-high)"
                      sx={{ mb: 1 }}
                    >
                      Туслах үйлдвэр:
                    </BodyBodySmall>
                    <TitleTitleMedium color="var(--on-surface-high)">
                      {callData.factoryName}
                    </TitleTitleMedium>
                  </Box>
                </Box>
                <Box
                  mt={2}
                  style={{
                    backgroundColor: "var(--surface-primary)",
                    padding: "12px",
                    borderRadius: "6px",
                  }}
                >
                  <BodyBodySmall color="var(--on-surface-high)">
                    Дуулга олгосон хэрэглэг:
                  </BodyBodySmall>
                  <TitleTitleLarge color="var(--on-surface-high)">
                    {callData.phoneNumber}
                  </TitleTitleLarge>
                </Box>
              </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
              <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
                Ачилт хийх хугацаа:{" "}
                <strong style={{ marginLeft: "20px" }}>
                  {callData.shipDate}
                </strong>
              </TitleTitleMedium>

              <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
                Дуулга авсан жолооч:{" "}
                <strong
                  style={{
                    marginLeft: "8px",
                    backgroundColor: "#368137",
                    color: "white",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {callData.driverCode}
                </strong>
              </TitleTitleMedium>

              <Box display="flex" alignItems="center">
                <Box width={'310px'}>
                  <TitleTitleMedium color="var(--on-surface-high)">
                    Ачилт хийх байршил:
                  </TitleTitleMedium>
                </Box>
                <Box >
                  <strong>{callData.address}</strong>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CallDetailModal;
