import React, { useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";
import { TransferToDriverTable } from "../../../components/call/transfer-to-driver-table";
import DriverBoxDetail from "../../../components/driver/box-detail";


const DriverDetail = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const initQuery = {
    page: 1,
    limit: 8,
    sort: "asc", // createdDate
    // status: null,
    // status: "CALL_TRACKING",
    //  "status":"ON_WAY",
    // "status":"SUCCESS_SHIPPED",
    // "status":"TRANSFERRED_TO_DRIVER",
    // "status":"CANCELLED",
    keyWord: id, // "1",
    searchType: 'driver.id', // "organization.id"
    total: 0,
  };
  const [query, setQuery] = useState(initQuery);
  const [callData, setCallData] = useState([]);

  useEffect(() => {
    if (id) {
      FindByUser();
      callList()
    }
  }, []);

  const FindByUser = async () => {
    try {
      const res = await mainApi(`driver/info/${id}`, "GET", null);
      setUserData(res.data);
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  const callList = async () => {
    try {
      const res = await mainApi("call/list", "POST", query);
      setCallData(res.content);
      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery;
      });
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Жолоочийн дэлгэрэнгүй
        </HeadlineHeadlineMedium>
      </Box>
      {id ? <DriverBoxDetail data={userData} /> : "уншиж байна..."}

      {id ? <TransferToDriverTable data={callData} query={query} setQuery={setQuery}/> : "өгөгдөл олдсонгүй"}
      {/* {id ? <UserList data={userData} /> : "уншиж байна..."} */}
    </Grid2>
  );
};

export default DriverDetail;
