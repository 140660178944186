import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, Box, Pagination
} from "@mui/material";
// import { makeStyles } from "@mui/styles";

import React, { useCallback, useEffect, useState } from "react";
import { FormInputDropdown } from "../../form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { userStatus } from "../../../utils/method";

import { StatusSpan } from "../../form/span";

import {
  BodyBodyMedium,
  HeadlineHeadlineSmall,
  tableBodyCellStyle,
  tableHeaderStyle,
  TitleTitleSmall,
} from "../../../utils/styleMethod";
import { useNavigate } from "react-router-dom";
import { mainApi } from "../../../api/main";

const initialQuery = {
  page: 1,
  limit: 8,
  total: 0,
  type: "",
};
export const UserTable = () => {
  const navigate = useNavigate();
  const { control } = useForm({});
  const [query, setQuery] = useState(initialQuery);
  const [page, setPage] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();

  useEffect(() => {
    getAllUser();
  }, [query]);

  const getAllUser = useCallback(async () => {
    try {
      const res = await mainApi("user/list", "POST", query);
      setUsersData(res.content);
      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery; //
      });
    } catch (err) {
      console.error("users fetch error:", err);
    } finally {
      console.log("error");
    }
  }, [query]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setQuery({ ...query, page: newPage });
    setPage(newPage - 1);
  };

  const onChangeFilter = (values) => {
    console.log('value -> ' , values);
    setQuery({ ...query, type: values });
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <HeadlineHeadlineSmall color="var(--on-surface-high)">
            Хэрэглэгчийн дэлгэрэнгүй
          </HeadlineHeadlineSmall>
          <span> | </span>{" "}
          <BodyBodyMedium color="var(--on-surface-medium-neutral)">
            Нийт {query.total} Аккоунт
          </BodyBodyMedium>
        </Box>

        <FormInputDropdown
          control={control}
          name={`asc`}
          options={userStatus}
          sx={{ minWidth: "300px", marginRight: "12px" }}
          onChange={(selectedValue) => onChangeFilter(selectedValue)}
        />
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["Бүртгэлтэй огноо", "Утас", "Нэр", "Оноо", "Апп ашигладаг"].map(
              (header) => (
                <TableCell key={header} className={classessHeader.tableHeader}>
                  <TitleTitleSmall color="var(--on-surface-high)">
                    {header}
                  </TitleTitleSmall>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {usersData.map((row, index) => (
            <TableRow
              key={index}
              className={index % 2 === 1 ? "table-row-odd" : ""}
              onClick={() => navigate(`detail/${row.userId}`)}
              style={{ cursor: "pointer" }}
            >
              <TableCell className={classessCell.tableBodyCell}>
                <BodyBodyMedium color="var(--on-surface-high)">
                  {" "}
                  {row.createdDate}
                </BodyBodyMedium>
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <BodyBodyMedium color="var(--on-surface-high)">
                  {" "}
                  {row.phoneNumber}{" "}
                </BodyBodyMedium>
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <BodyBodyMedium color="var(--on-surface-high)">
                  {" "}
                  {row.userName}{" "}
                </BodyBodyMedium>
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <BodyBodyMedium color="var(--on-surface-high)">
                  {781 + index}{" "}
                </BodyBodyMedium>
              </TableCell>

              <TableCell className={classessCell.tableBodyCell}>
                <StatusSpan
                  label={row.usesApp ? "Тийм" : "Үгүй"}
                  statusColor={row.usesApp ? "green" : "red"}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Custom Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(query.total / 8)}
          page={page + 1}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
    </TableContainer>
  );
};
