import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomStyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#2e7d32",
  borderRadius: "24px",
  padding: "8px 24px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#1b5e20",
  },
}));

export const AddButton = ({ label, onClickBtn }) => {
  return (
    <CustomStyledButton
      className="BodyBody---ExtraSmall222" // Applying external class
      onClick={onClickBtn}
    >
      {label}
    </CustomStyledButton>
  );
};
