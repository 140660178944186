import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
import { Box, Button, Grid2, Pagination } from "@mui/material";
import { FormInputDropdown } from "../../form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { callFilter } from "../../../utils/method";
import { rows } from "../../../utils/method";
import { StatusSpan } from "../../form/span";
import { ToggleButton } from "../../form/buttons/toggle-button";
import { tableBodyCellStyle, tableHeaderStyle } from "../../../utils/styleMethod";

export const ReelsTable = ({ data, query, setQuery }) => {
  const { control } = useForm({});
  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setQuery({ ...query, page: newPage });
  };

  const onChangeSort = (values) => setQuery({ ...query, sort: values });

  const handleButtonClick = (label) => {
    console.log("handleButtonClick==========> ", label);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Рийлс жагсаалт <span> | </span> <span className="BodyBody---Medium"> Нийт {query.total} рийлс байна</span>{" "}
        </p>
        <FormInputDropdown
          control={control}
          name={`asc`}
          options={callFilter}
          sx={{ minWidth: "250px", marginRight: "12px" }}
          onChange={(selectedValue) => onChangeSort(selectedValue)}
        />
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["Огноо", "Гарчиг", "Preview", "Хэл", "Тухай"].map((header) => (
              <TableCell key={header} className={classessHeader.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} className={index % 2 === 1 ? "table-row-odd" : ""}>
              <TableCell className={classessCell.tableBodyCell}>{row.postedDateStr}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.caption}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <img
                  src={row.mainImage}
                  alt={row.caption}
                  style={{
                    width: 60,
                    height: 100,
                    marginRight: "10px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                />
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.language}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <ToggleButton label={"Дэлгэрэнгүй"} onClickButton={() => handleButtonClick(row)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Custom Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination count={Math.ceil(query.total / query.limit)} page={query.page} onChange={handleChangePage} siblingCount={1} boundaryCount={1} />
      </Box>
    </TableContainer>
  );
};
