import React, { useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import CustomerBoxDetail from "../../../components/customer/box-detail";
import {
  HeadlineHeadlineMedium,
} from "../../../utils/styleMethod";
import { TransferToDriverTable } from "../../../components/call/transfer-to-driver-table";
import { ArrowUpRight, Users } from "@phosphor-icons/react";
import StatCard from "../../../components/home/dashboard/StatCard";

const CustomerDetail = () => {
  const { id } = useParams();
  const initQuery = {
    page: 1,
    limit: 8,
    sort: "asc", 
    status: null,
    keyWord: id, 
    searchType: 'user.id',
    total: 0,
  };
  const [userData, setUserData] = useState({});
  const [query, setQuery] = useState(initQuery);
  const [callData, setCallData] = useState([]);

  useEffect(() => {
    if (id) {
      FindByUser();
      callList()
    }
  }, []);

  const FindByUser = async () => {
    try {
      const res = await mainApi(`user/info/${id}`, "GET", null);
      setUserData(res.data);
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  
  const callList = async () => {
    try {
      const res = await mainApi("call/list", "POST", query);
      setCallData(res.content);
      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery;
      });
    } catch (error) {
      console.log("error");
    }
  };


  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Хэрэглэгчийн дэлгэрэнгүй
        </HeadlineHeadlineMedium>
      </Box>
      {id ? <CustomerBoxDetail data={userData} /> : "уншиж байна..."}
      {
        <Grid2 container spacing={2} sx={{paddingTop:'24px'}}>
          <StatCard
            title="Хуванцар"
            value="6189 кг"
            icon={{
              component: (
                <Users
                  size={24}
                  weight="duotone"
                  color="var(--on-surface-high)"
                />
              ),
              bgColor: "#c5e7c6",
            }}
            comparison="Өнгөрсөн улирал"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
              <ArrowUpRight
                size={24}
                weight="regular"
                color="var(--success-error)"
              />
            }
          />
          <StatCard
            title="Хуванцар"
            value="6189 кг"
            icon={{
              component: (
                <Users
                  size={24}
                  weight="duotone"
                  color="var(--on-surface-high)"
                />
              ),
              bgColor: "#c5e7c6",
            }}
            comparison="Өнгөрсөн улирал"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
              <ArrowUpRight
                size={24}
                weight="regular"
                color="var(--success-error)"
              />
            }
          />
            <StatCard
            title="Хуванцар"
            value="6189 кг"
            icon={{
                component: (
                    <Users
                        size={24}
                        weight="duotone"
                        color="var(--on-surface-high)"
                    />
                ),
                bgColor: "#c5e7c6",
            }}
            comparison="Өнгөрсөн улирал"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />
          <StatCard
            title="Хуванцар"
            value="6189 кг"
            icon={{
                component: (
                    <Users
                        size={24}
                        weight="duotone"
                        color="var(--on-surface-high)"
                    />
                ),
                bgColor: "#c5e7c6",
            }}
            comparison="Өнгөрсөн улирал"
            comparisonValue={{ text: "8.5%", color: "var(--success-error)" }}
            comparisonIcon={
                <ArrowUpRight
                    size={24}
                    weight="regular"
                    color="var(--success-error)"
                />
            }
        />
        </Grid2>
      }
      {id ? <TransferToDriverTable  data={callData} query={query} setQuery={setQuery}/> : "өгөгдөл олдсонгүй"}
      {/* {id ? <UserList data={userData} /> : "уншиж байна..."} */}
    </Grid2>
  );
};

export default CustomerDetail;
