import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Grid2
} from "@mui/material";
import {
  X,
} from "@phosphor-icons/react";
import {

  BodyBodySmall,
  HeadlineHeadlineMedium,
  HeadlineHeadlineSmall,
  LabelLabelLarge,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import CustomInput from "../../form/input";
import SuccessModal from "../../modals/Success";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import MyAutocomplete from "../../form/Auto-complate";
import {generateCode } from "../../../utils/method";

const DriverCreateEditModal = ({
  data,
  open,
  handleClose,
  setDriverData,
  type,
  refreshList
}) => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [searchFactoryValue, setSearchFactoryValue] = useState("");
  const [searchZoneValue, setSearchZoneValue] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [zoneNames, setZoneNames] = useState([]);

  const handleInputPhone = (value) => setPhone(value);
  const handleInputName = (value) => setName(value);

  useEffect(() => {
    
    if (type === "update") {
      setSearchFactoryValue(data.registeredFactory);
      setSearchZoneValue(data.zoneName);
    }

    const getCompanyNamesAndZoneNames = async () => {
      try {
        const resFactoryNames = await mainApi(
          "organization/titles/FACTORY",
          "GET",
          null
        );
        const resZoneNames = await mainApi(
          "organization/zone/names",
          "GET",
          null
        );
        setCompanyNames(resFactoryNames);
        setZoneNames(resZoneNames);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    getCompanyNamesAndZoneNames();
  }, [data]);

  // ******************* UPDATE && CREATE *******************
  const onClickUpdateAndCreate = async () => {
    // here condtion
    const zone = zoneNames.find((zone) => zone.name === searchZoneValue);
    const organization = companyNames.find(
      (item) => item.name === searchFactoryValue
    );
    if (type === "update") {
      try {
        const json = {
          id: data.userId,
          phoneNumber: phone ? phone : data.phoneNumber,
          organizationId: organization.organizationId,
          zoneId: zone.id,
        };
        const res = await mainApi(`auth/update`, "PUT", json);
        if (res.statusCode === 200) {
          handleClose();
          setOpenSuccessModal(true);
          
          setDriverData({
            ...data,
            phoneNumber: phone ? phone : data.phoneNumber,
            registeredFactory: organization.name,
            zoneName: zone.name,
          });
        }
      } catch (error) {
        console.log("error", error);
        toast.warning(error.message);
      }
    } else if (type === "create") {
      try {
        const jsonData = {
          phoneNumber: phone ? phone : data.phoneNumber,
          username: phone ? phone : data.phoneNumber,
          organizationId: organization.organizationId,
          password: "d123", // Corrected typo: 'password'
          driverCode: generateCode(),
          zoneId: zone.id,
          name: name,
          roleId: 8,
        };
        const res = await mainApi(`auth/signup`, "PUT", jsonData);
        handleClose();
        setOpenSuccessModal(true);
        refreshList()
      } catch (error) {
        console.log("error", error);
        toast.warning(error.message);
      }
    }
  };

  const closeSuccessModal = () => setOpenSuccessModal(false);

  return (
    <>
      <Modal open={open} onClose={() => handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450, // Updated width
            // height: 700, // Updated height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            width="100%"
          >
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={2}
          >
            <HeadlineHeadlineMedium color="var(--on-surface-high)">
              {type === "update"
                ? "Жолоочийн мэдээлэл засах"
                : "Шинэ жолооч нэмэх"}
            </HeadlineHeadlineMedium>
          </Box>

          {type === "update" ? (
            <>
              <Grid2 container spacing={2} mb={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={data.profilePicture}
                    alt={data.name}
                    style={{
                      width: 85,
                      height: 85,
                      marginRight: "10px",
                      border: "1px solid #f2f2f2",
                      borderRadius: "12px",
                      objectFit: "cover",
                    }}
                  />
                </Box>

                {/* User Information Section */}
                <Grid2 xs={12} md={12} sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        flexGrow: 1,
                        paddingTop: "12px",
                        paddingLeft: "8px",
                      }}
                    >
                      <HeadlineHeadlineSmall color="var(--on-surface-high)">
                        {data.userName}
                      </HeadlineHeadlineSmall>
                      <HeadlineHeadlineSmall color="var(--on-surface-high)">
                        {data.driverCode}
                      </HeadlineHeadlineSmall>
                      <TitleTitleMedium color="var(--on-surface-medium)">
                        Жолооч {data.name} · {data.phoneNumber}
                      </TitleTitleMedium>
                    </Box>
                  </Box>
                </Grid2>
              </Grid2>
              {/* User Information Section */}
            </>
          ) : null}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={3}
          ></Box>

          <Grid2 container spacing={2} justifyContent="center">
            <Grid2 xs={12}>
              <BodyBodySmall color="var(--on-surface-high)">
                {type === "update"
                  ? "Бүртгэлтэй дугаар "
                  : "Бүртгэл үүсгэх дугаар"}
              </BodyBodySmall>
              <CustomInput
                placeholderText="88888888"
                handleInputChange={handleInputPhone}
                style={{ width: "365px", height: "50px", marginTop: "8px" }}
                defaultValue={type === "update" ? data.phoneNumber : ""}
              />
            </Grid2>
            {type === "create" ? (
              <Grid2 xs={12}>
                <BodyBodySmall color="var(--on-surface-high)">
                  Нэр
                </BodyBodySmall>
                <CustomInput
                  placeholderText=""
                  handleInputChange={handleInputName}
                  style={{ width: "365px", height: "50px", marginTop: "8px" }}
                  // defaultValue={data.name}
                />
              </Grid2>
            ) : null}
            <Grid2 xs={12}>
              <BodyBodySmall color="var(--on-surface-high)">
                Үйлдвэр сонгох
              </BodyBodySmall>
              {/* Search Field */}
              <MyAutocomplete
                options={companyNames}
                placeholder="Үйлдвэр хайх..."
                defaultValue={searchFactoryValue}
                setItem={setSearchFactoryValue}
              />
            </Grid2>

            <Grid2 xs={12}>
              <BodyBodySmall color="var(--on-surface-high)">
                Ачилтын бүс сонгох
              </BodyBodySmall>
              <MyAutocomplete
                options={zoneNames}
                placeholder="Бүс хайх..."
                defaultValue={searchZoneValue}
                setItem={setSearchZoneValue}
              />
            </Grid2>
          </Grid2>
          <Button
            sx={{
              marginTop: "40px",
              height: "45px",
              border: "1px solid #368137",
              borderRadius: "16px",
              padding: "16px",
              color: "#368137",
            }}
            variant="contained"
            onClick={() => {
              onClickUpdateAndCreate();
            }}
          >
            <LabelLabelLarge color="white">Хадгалах</LabelLabelLarge>
          </Button>
        </Box>
      </Modal>
      <SuccessModal
        title={``}
        content={`${
          phone ? phone : data.phoneNumber
        }  дугаар бүхий шинэ жолооч амжилттай нэмэгдлээ`}
        open={openSuccessModal}
        handleClose={closeSuccessModal}
      />
    </>
  );
};

export default DriverCreateEditModal;
