import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid2, Paper, TableContainer, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { UserContext } from "../../context/user";
import { Plus } from "@phosphor-icons/react";
import { ToggleButton } from "../../components/form/buttons/toggle-button";
import { mainApi } from "../../api/main";
import ArrowLeftBtn from "../../components/form/buttons/arrow-left";
import ArrowRightBtn from "../../components/form/buttons/arrow-right";
import GridCardComponent from "../../components/grid-card-component";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";
import CreateOrganizationModal from "../../components/modals/CreateOrganization";

const initialQuery = {
  page: 1,
  limit: 8,
  total: 0,
  type: "FACTORY",
  itemGroupId: 0,
};

const Factory = () => {
  const { user, setUser } = useContext(UserContext);

  const [query, setQuery] = useState(initialQuery);

  const [selectedStatus, setSelectedStatus] = useState("Бүгд");
  const [selectedStatusId, setSelectedStatusId] = useState(1);
  const [factoryData, setFactoryData] = useState();
  const [category, setCategory] = useState([
    { id: 0, name: "Бүгд", code: "A001" },
  ]);
  const [itemList, setItemList] = useState([]);

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);

  useEffect(() => {
    getCategory();
    getItemList();
  }, []);

  useEffect(() => {
    getAllFactory();
  }, [query, selectedStatusId]);

  const getItemList = async () => {
    const res = await mainApi("items/group/list", "GET", null);
    setItemList(res);
  };

  const getCategory = async () => {
    const res = await mainApi("items/group/list", "GET", null);
    setCategory((prevCategories) => {
      const newCategories = res.filter(
        (newCat) => !prevCategories.some((prevCat) => prevCat.id === newCat.id)
      );

      if (newCategories.length > 0) {
        return [...prevCategories, ...newCategories];
      }
      return prevCategories; // No change if no new categories
    });
  };

  const getAllFactory = useCallback(async () => {
    try {
      const res = await mainApi("organization/", "POST", query);
      setFactoryData(res.content);

      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery; //
      });
    } catch (err) {
      console.error("Factory fetch error:", err);
    } finally {
      console.log("end");
    }
  }, [query]);

  const handleButtonClick = (label, id) => {
    console.log("selected label: ", label);
    setSelectedStatus(label);
    setSelectedStatusId(id);
    setQuery({ ...query, itemGroupId: id });
  };

  const onClickButtonLeft = async () => {
    setQuery({ ...query, page: query.page - 1 });
    getAllFactory();
  };
  const onClickButtonRight = async () => {
    setQuery({ ...query, page: query.page + 1 });
    getAllFactory();
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Үйлдвэр
        </HeadlineHeadlineMedium>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2e7d32",
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
          onClick={() => setOpenOrganizationModal(true)}
        >
          Шинэ үйлдвэр нэмэх
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {category.map((status, index) => (
          <ToggleButton
            key={index}
            label={status.name}
            onClickButton={() => {
              handleButtonClick(status.name, status.id);
            }}
            textColor="var(--primary-primary)"
            bgColor="var(--primary-soft-primary-soft)"
            selectedStatus={selectedStatus}
          />
        ))}
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "64px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <p
            className="HeadlineHeadline---Small"
            style={{ color: "var(--on-surface-high)" }}
          >
            Үйлдвэрүүд <span> | </span>{" "}
            <span className="BodyBody---Medium">
              {" "}
              Нийт {query.total} Үйлдвэр
            </span>{" "}
          </p>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftBtn onClick={() => onClickButtonLeft()} />
            <ArrowRightBtn onClick={() => onClickButtonRight()} />
          </Box>
        </Box>
        {/* {factoryData ? <CompanyGrid data={factoryData} /> : null} */}
        {factoryData ? (
          <GridCardComponent data={factoryData} type="FACTORY" />
        ) : null}
      </TableContainer>

      {openOrganizationModal ? (
        <CreateOrganizationModal
          Title={"Үйлдвэрийн бүртгэл"}
          open={openOrganizationModal}
          handleClose={() => setOpenOrganizationModal(false)}
          type="FACTORY"
          itemList={itemList}
          roleName={"FactoryAdmin"}
          initialQuery={initialQuery}
          setQuery={setQuery}
        />
      ) : null}
    </Grid2>
  );
};

export default Factory;
