// Sidebar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Typography,
  Divider,
} from "@mui/material";
import "./sidebar.css"; // Import the CSS file

import {
  ChartPie,
  Phone,
  Truck,
  UsersThree,
  Buildings,
  CaretUp,
  CaretDown,
  Factory,
  House,
  MathOperations,
  Recycle,
  NewspaperClipping,
  Exam,
  Question,
  Gear,
  SignOut,
} from "@phosphor-icons/react";

const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState(null); // Track active item
  const [openBusiness, setOpenBusiness] = useState(false);
  const [openServiceCenter, setOpenServiceCemter] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);
  const [openTime, setOpenTime] = useState(false);

  const handleBusinessClick = () => {
    setSelectedItem(3);
    setOpenBusiness(!openBusiness);
    setOpenServiceCemter(!openServiceCenter);
  };

  const handleServiceCenterClick = () => {
    setSelectedItem(7);
    setOpenServiceCemter(!openServiceCenter);
  };
  const handleGuideClick = () => {
    setSelectedItem(10);
    setOpenGuide(!openGuide);
  };

  const handleTimeClick = () => {
    setOpenTime(!openTime);
  };

  const handleListItemClick = (index) => {
    
    setSelectedItem(index);
  };

  return (
    <div className="sidebar">
      <List>
        <ListItemButton
          className={`list-item ${selectedItem === 0 ? "selected" : ""}`}
          onClick={() => handleListItemClick(0)}
          component={Link}
          to="/"
        >
          <ChartPie size={18} />
          <ListItemText className="list-item-text" primary="Нүүр" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 1 ? "selected" : ""}`}
          onClick={() => handleListItemClick(1)}
          component={Link}
          to="/call"
        >
          <Phone size={18} />
          <ListItemText className="list-item-text" primary="Дуудлага" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 2 ? "selected" : ""}`}
          onClick={() => handleListItemClick(2)}
          component={Link}
          to="/driver"
        >
          <Truck size={18} />
          <ListItemText className="list-item-text" primary="Жолооч" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 17 ? "selected" : ""}`}
          onClick={() => handleListItemClick(17)}
          component={Link}
          to="/customer"
        >
          <UsersThree size={18} />
          <ListItemText className="list-item-text" primary="Хэрэглэгч" />
        </ListItemButton>

        {/* Collapsible section for Аж ахуй нэгж */}
        <ListItemButton
          className={`list-item ${selectedItem === 4 ? "selected" : ""}`}
          onClick={handleBusinessClick}
          component={Link}
          to="/enterprise"
        >
          <Buildings size={18} />
          <ListItemText className="list-item-text" primary="Аж ахуй нэгж" />
          <CaretDown size={18} />
        </ListItemButton>
        <Collapse in={openBusiness} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={`list-item ${
                selectedItem === 5 ? "selected" : ""
              } collapse-padding`}
              onClick={() => handleListItemClick(5)}
              component={Link}
              to="/enterprise/register"
            >
              <ListItemText
                className="list-item-text"
                primary="Бүртгүүлэх хүсэлт"
              />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          className={`list-item ${selectedItem === 6 ? "selected" : ""}`}
          onClick={() => handleListItemClick(6)}
          component={Link}
          to="/factory"
        >
          <Factory size={18} />
          <ListItemText className="list-item-text" primary="Үйлдвэр" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 7 ? "selected" : ""}`}
          onClick={handleServiceCenterClick}
          component={Link}
          to="/dropoff"
        >
          <House size={18} />
          <ListItemText className="list-item-text" primary=" Цэг" />
          <CaretDown size={18} />
        </ListItemButton>

        <Collapse in={openServiceCenter} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={`list-item ${
                selectedItem === 8 ? "selected" : ""
              } collapse-padding`}
              onClick={() => handleListItemClick(8)}
              component={Link}
              to="/dropoff/prepare"
            >
              <ListItemText className="list-item-text" primary="Бэлтгэх цэг" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          className={`list-item ${selectedItem === 9 ? "selected" : ""}`}
          onClick={() => handleListItemClick(9)}
          component={Link}
          to="/finance"
        >
          <MathOperations size={18} />
          <ListItemText className="list-item-text" primary="Санхүү" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 10 ? "selected" : ""}`}
          onClick={handleGuideClick}
          component={Link}
          to="/guide"
        >
          <Recycle size={18} />
          <ListItemText className="list-item-text" primary=" Заавар" />
          <CaretDown size={18} />
        </ListItemButton>
        <Collapse in={openGuide} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={`list-item ${
                selectedItem === 11 ? "selected" : ""
              } collapse-padding`}
              onClick={() => handleListItemClick(11)}
              component={Link}
              to="/guide/barcode"
            >
              <ListItemText className="list-item-text" primary="Баркод" />
            </ListItemButton>
          </List>
        </Collapse>

        <Divider sx={{ marginTop: "16px" }}></Divider>
        <Typography
          sx={{
            marginTop: "16px",
            marginBottom: "8px",
            fontSize: "14px",
            color: "#153216",
            fontWeight: "600",
            marginLeft: "16px",
          }}
        >
          Мэдлэг
        </Typography>

        <ListItemButton
          className={`list-item ${selectedItem === 12 ? "selected" : ""}`}
          onClick={() => handleListItemClick(12)}
          component={Link}
          to="/news"
        >
          <NewspaperClipping size={18} />
          <ListItemText className="list-item-text" primary="Мэдээ" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 13 ? "selected" : ""}`}
          onClick={() => handleListItemClick(13)}
          component={Link}
          to="/exam"
        >
          <Exam size={18} />
          <ListItemText className="list-item-text" primary="Тест" />
        </ListItemButton>

        <Divider sx={{ marginTop: "16px" }}></Divider>

        <ListItemButton
          className={`list-item ${selectedItem === 14 ? "selected" : ""}`}
          onClick={() => handleListItemClick(14)}
          component={Link}
          to="/help"
        >
          <Question size={18} />
          <ListItemText className="list-item-text" primary="Тусламж" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 15 ? "selected" : ""}`}
          onClick={() => handleListItemClick(15)}
          component={Link}
          to="/settings"
        >
          <Gear size={18} />
          <ListItemText className="list-item-text" primary="Тохиргоо" />
        </ListItemButton>

        <ListItemButton
          className={`list-item ${selectedItem === 16 ? "selected" : ""}`}
          onClick={() => handleListItemClick(16)}
          component={Link}
          to="/"
        >
          <SignOut size={18} />
          <ListItemText className="list-item-text" primary="Гарах" />
        </ListItemButton>

        {/* Add more items as needed */}
      </List>
    </div>
  );
};

export default Sidebar;
