import React, { useEffect, useState, useContext } from "react";
import {
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  Avatar,
  Box,
  Typography,
  MenuItem,
  Menu,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlass, Pencil, Trash } from "@phosphor-icons/react";
import { handleSection } from "../../../utils/method";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context/user";
import { BodyBodyMedium, LabelLabelSmall } from "../../../utils/styleMethod";

const TopBar = () => {
  const location = useLocation();
  const sectionText = handleSection(location.pathname);
  const [firstPart, secondPart] = sectionText.split(" / ");
  const { user, logout, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const profileImage = user && user.profileImage ? user.profileImage : null;

  const [anchorEl, setAnchorEl] = useState(null);

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickLogout = async () => {
    logout();
    setUser(null);
    navigate("/login");
    handleClose();
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        height: "88px",
        borderBottom: "1px solid #ccc",
        backgroundColor: "#ffffff",
      }}
    >
      <Toolbar sx={{ height: "88px" }}>
        {/* Breadcrumb */}
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <LabelLabelSmall color="#BABABA" style={{ paddingRight: "20px" }}>
            Хуудас
          </LabelLabelSmall>
          <LabelLabelSmall color="#1D1D1D" style={{ paddingRight: "20px" }}>
            /
          </LabelLabelSmall>
          <LabelLabelSmall color="#1D1D1D" style={{ paddingRight: "20px" }}>
            {" "}
            {firstPart}{" "}
          </LabelLabelSmall>
          {secondPart ? (
            <>
              <LabelLabelSmall color="#1D1D1D" style={{ paddingRight: "20px" }}>
                /
              </LabelLabelSmall>
              <LabelLabelSmall color="#1D1D1D" style={{ paddingRight: "20px" }}>
                {secondPart}
              </LabelLabelSmall>
            </>
          ) : (
            ""
          )}

          {/* <LabelLabelSmall color="#1D1D1D" style={{paddingRight:'20px'}} >/</LabelLabelSmall> */}
          {/* <LabelLabelSmall color="#1D1D1D" style={{paddingRight:'20px'}}>
            {handleSection(location.pathname)}
          </LabelLabelSmall> */}
        </Box>

        {/* Search Field */}
        <TextField
          variant="outlined"
          placeholder="Хайх..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MagnifyingGlass size={20} />
              </InputAdornment>
            ),
            style: { height: "45px" },
          }}
          sx={{
            mr: 2,
            width: "300px",
            "& .MuiOutlinedInput-root": { height: "45px" },
          }}
        />

        {/* User Avatar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&:hover": {
              "& .avatar": {
                transform: "scale(1.1)",
                transition: "transform 0.2s ease",
              },
            },
          }}
        >
          <Avatar
            alt="User"
            src={profileImage}
            className="avatar"
            sx={{ height: "48px", width: "48px", cursor: "pointer" }}
            onClick={handleClick}
          />
        </Box>

        {/* Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ borderRadius: "24px" }}
        >
          <MenuItem
            sx={{ height: "48px" }}
            onClick={() => {
              navigate('/profile');
            }}
          >
            <Pencil
              style={{ marginRight: "12px" }}
              color="var(--on-surface-high)"
              size={16}
              weight="light"
            />
            <BodyBodyMedium color="var(--on-surface-high)">
              Хувийн мэдээлэл
            </BodyBodyMedium>
          </MenuItem>

          <MenuItem
            sx={{ height: "48px" }}
            onClick={() => {
              clickLogout();
            }}
          >
            <Trash
              style={{ marginRight: "12px" }}
              color="var(--on-surface-high)"
              size={16}
              weight="light"
            />
            <BodyBodyMedium color="var(--on-surface-high)">
              Гарах
            </BodyBodyMedium>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
