import React, { useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../../../../api/main";
import { toast } from "react-toastify";
import { rows } from "../../../../utils/method";
import ItemGroupInfo from "./itemGroupInfo";
import UserList from "../../../../components/user-list";
import { HeadlineHeadlineMedium } from "../../../../utils/styleMethod";

const ItemGroupDetail = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, []);

  const getDetail = async () => {
    try {
      if (type === "type") {
        const res = await mainApi(`itemGroups/admin/getDetail/type/${id}`, "GET", null);
        setCurrentData(res);
      } else {
        const res = await mainApi(`itemGroups/admin/getDetail/${id}`, "GET", null);
        setCurrentData(res);
      }
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">Зааврын дэлгэрэнгүй</HeadlineHeadlineMedium>
      </Box>
      {id ? <ItemGroupInfo data={currentData} /> : "lodading.."}
    </Grid2>
  );
};

export default ItemGroupDetail;
