// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    gap: 10px;
    padding-top: 12px;
  }
  
  .button {
    display: flex;
    align-items: center;
    background-color: #E6F4E5;
    border-radius: 32px;
    padding: 10px;
    gap: 10px;
    height: 36px;
  }
  
  .button .icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
  }
  
  .button span {
    font-size: 16px;
    color: #153216;
  }
  
  /* Progress bar styles */
  .progress-bar {
    width: 60px;
    height: 10px;
    
    background-color: #ccc;
    border-radius: 7px;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background-color: #3f9740;
    border-radius: 7px;
    transition: width 0.3s ease;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/customer/box-detail/box-style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA,wBAAwB;EACxB;IACE,WAAW;IACX,YAAY;;IAEZ,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;EAC7B","sourcesContent":[".button-container {\n    display: flex;\n    gap: 10px;\n    padding-top: 12px;\n  }\n  \n  .button {\n    display: flex;\n    align-items: center;\n    background-color: #E6F4E5;\n    border-radius: 32px;\n    padding: 10px;\n    gap: 10px;\n    height: 36px;\n  }\n  \n  .button .icon {\n    background-color: #fff;\n    border-radius: 50%;\n    padding: 2px;\n  }\n  \n  .button span {\n    font-size: 16px;\n    color: #153216;\n  }\n  \n  /* Progress bar styles */\n  .progress-bar {\n    width: 60px;\n    height: 10px;\n    \n    background-color: #ccc;\n    border-radius: 7px;\n    position: relative;\n  }\n  \n  .progress {\n    height: 100%;\n    background-color: #3f9740;\n    border-radius: 7px;\n    transition: width 0.3s ease;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
