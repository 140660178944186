import React, { useState } from "react";
import { Modal, Box, Button, Grid2, IconButton } from "@mui/material";
import { Leaf, Recycle, X } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodySmall,
  HeadlineHeadlineMedium,
  HeadlineHeadlineSmall,
  LabelLabelLarge,
} from "../../utils/styleMethod";
import CustomInput from "../form/input";
import SuccessModal from "./Success";
import { mainApi } from "../../api/main";
import { toast } from "react-toastify";

const CustomerEditModal = ({ data, open, handleClose, setUserData }) => {
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleInputEmail = (value) => setEmail(value);
  const handleInputAddress = (value) => setAddress(value);
  const handleInputPhone = (value) => setPhone(value);

  const onClickUpdate = async () => {
    // here condtion
    const json = {
      id: data.userId,
      phoneNumber: phone ? phone : data.phoneNumber,
      email: email ? email : data.email,
      address: address ? address : data.address,
    };
    console.log("json : ", json);
    try {
      const res = await mainApi(`auth/update`, "PUT", json);
      console.log("res -> ", JSON.stringify(res));
      if (res.statusCode == 200) {
        handleClose();
        setOpenSuccessModal(true);
        setUserData({
          ...data,
          phoneNumber: phone ? phone : data.phoneNumber,
          address: address ? address : data.address,
          email: email ? email : data.email,
        });
      }
    } catch (error) {
      console.log("error", error);
      toast.warning(error.message);
    }
  };

  const closeSuccessModal = () => setOpenSuccessModal(false);

  return (
    <>
      <Modal open={open} onClose={() => handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500, // Updated width
            height: 700, // Updated height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            // justifyContent: 'center',
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            width="100%"
          >
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={2}
          >
            <HeadlineHeadlineMedium>
              Хэрэглэгчийн мэдээлэл засах
            </HeadlineHeadlineMedium>
          </Box>

          <Grid2 container spacing={2} mb={4}>
            {/* Logo Section */}
            <div
              style={{ position: "relative", width: "90px", height: "90px" }}
            >
              {/* Laurels Image */}
              <img
                src="/images/Laurels.svg"
                alt="laurels"
                style={{
                  marginTop: "12px",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              />

              {/* Profile Image */}
              <img
                src={data.profilePicture}
                alt={data.profilePicture}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              />
            </div>

            {/* User Information Section */}
            <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }}>
              <Box display="flex" alignItems="center">
                <Box
                  sx={{ flexGrow: 1, paddingTop: "12px", paddingLeft: "8px" }}
                >
                  <HeadlineHeadlineSmall color="var(--on-surface-high)">
                    {data.userName}
                  </HeadlineHeadlineSmall>

                  <div className="button-container">
                    {/* First button with progress bar */}
                    <div className="button">
                      <div className="icon">
                        <Leaf size={24} weight="duotone" color="#4CAF50" />
                      </div>
                      <BodyBodySmall>Идэвхтэн</BodyBodySmall>
                      <div className="progress-bar">
                        <div
                          className="progress"
                          style={{ width: `${78}%` }}
                        ></div>
                      </div>
                    </div>

                    {/* Second button with score */}
                    <div className="button">
                      <div className="icon">
                        <Recycle size={24} weight="duotone" color="#4CAF50" />
                      </div>
                      <BodyBodySmall>828 оноо</BodyBodySmall>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
          {/* User Information Section */}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={3}
          ></Box>

          <Grid2 container spacing={2} justifyContent="center">
            <Grid2 xs={12}>
              <BodyBodySmall color="var(--on-surface-high)">
                Бүртгэлтэй дугаар
              </BodyBodySmall>
              <CustomInput
                placeholderText="88888888"
                handleInputChange={handleInputPhone}
                style={{ width: "365px", height: "50px", marginTop: "8px" }}
                defaultValue={data.phoneNumber}
              />
            </Grid2>
            <Grid2 xs={12}>
              <BodyBodySmall color="var(--on-surface-high)">
                Имэйл хаяг
              </BodyBodySmall>
              <CustomInput
                placeholderText="Имэйл"
                handleInputChange={handleInputEmail}
                style={{ width: "365px", height: "50px", marginTop: "8px" }}
                defaultValue={data.email}
              />
            </Grid2>

            <Grid2 xs={12}>
              <BodyBodySmall color="var(--on-surface-high)">Хаяг</BodyBodySmall>
              <CustomInput
                placeholderText="Хаяг"
                handleInputChange={handleInputAddress}
                style={{ width: "365px", height: "50px", marginTop: "8px" }}
                defaultValue={data.address}
              />
            </Grid2>
          </Grid2>
          <Button
            sx={{
              marginTop: "40px",
              height: "45px",
              border: "1px solid #368137",
              borderRadius: "16px",
              padding: "16px",
              color: "#368137",
            }}
            variant="contained"
            onClick={() => {
              onClickUpdate();
            }}
          >
            <LabelLabelLarge color="white">Хадгалах</LabelLabelLarge>
          </Button>
        </Box>
      </Modal>
      <SuccessModal
        title="Хэрэглэгчийн мэдээлэл амжилттай шинэчлэгдлээ"
        content={`${
          phone ? phone : data.phoneNumber
        }  дугаартай хэрэглэгчийн мэдээлэл системд амжилттай шинэчлэгдлээ`}
        open={openSuccessModal}
        handleClose={closeSuccessModal}
      />
    </>
  );
};

export default CustomerEditModal;
