import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid2, Pagination } from "@mui/material";
import { FormInputDropdown } from "../../components/form/form-input-dropdown";
import { useForm } from "react-hook-form";
import { Plus } from "@phosphor-icons/react";
import { callFilter } from "../../utils/method";
import { rows } from "../../utils/method";

import { AddButton } from "../../components/form/buttons/add-button";
import { ToggleButton } from "../../components/form/buttons/toggle-button";
import { mainApi } from "../../api/main";
import NewCallRegisterModal from "../../components/modals/NewCallRegister";
import { UserContext } from "../../context/user";
import { NewsTable } from "../../components/news/news-table";
import { ReelsTable } from "../../components/news/reels-table";

const initQuery = {
  page: 1,
  limit: 8,
  sort: "asc", // createdDate
  // status: null,
  newsType: "news",
  //  "status":"ON_WAY",
  // "status":"SUCCESS_SHIPPED",
  // "status":"TRANSFERRED_TO_DRIVER",
  // "status":"CANCELLED",
  keyWord: null, // "1",
  searchType: null, // "organization.id"
  total: 0,
};
const statuses = [
  {
    id: "news",
    label: "Текст мэдээ",
    value: "news",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
  },
  {
    id: "reels",
    label: "Богино рийлс",
    value: "reels",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
  },
];
const News = () => {
  const { user } = useContext(UserContext);

  const [selectedStatus, setSelectedStatus] = useState("Текст мэдээ");
  const [selectedStatusId, setSelectedStatusId] = useState("news");

  const [query, setQuery] = useState(initQuery);
  const [data, setData] = useState([]);

  const [newCallModalOpen, setNewCallModalOpen] = useState(false);

  const [openMapModal, setOpenMapModal] = useState(false);
  const [addressInfo, setAddressInfo] = useState();

  const [factoryList, setFactoryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [subItemList, setSubItemList] = useState([]);

  useEffect(() => {
    callList();
  }, [query]);

  const callList = async () => {
    try {
      const res = await mainApi(`news/admin/list/all/${selectedStatusId}`, "GET", query);
      setData(res.content);
      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery;
      });
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    getFactoryList();
    getItemList();
  }, []);

  const getFactoryList = async () => {
    const res = await mainApi("organization/titles/FACTORY", "GET", null);
    setFactoryList(res);
  };
  const getItemList = async () => {
    const res = await mainApi("items/group/list", "GET", null);
    setItemList(res);
  };
  // const getItemList = async () => {
  //   const res = await mainApi("items/group/list", "GET", null)
  //   setItemList(res)
  // }

  const handleButtonClick = (label, id, value) => {
    setSelectedStatus(label);
    setSelectedStatusId(id);
    setQuery({ ...query, status: value });
  };

  const onClickAddButton = () => {
    console.log("ON CLICKED ADD BUTTOn");
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <p
          className="HeadlineHeadline---Small"
          style={{
            color: "var(--on-surface-high)",
            paddingTop: "8px",
          }}
        >
          Мэдээ
        </p>

        <Button
          variant="contained"
          sx={{
            "backgroundColor": "#2e7d32",
            "borderRadius": "24px",
            "padding": "8px 24px",
            "textTransform": "none",
            "fontWeight": "bold",
            "display": "flex",
            "alignItems": "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          // onClick={() => setOpenMapModal(true)}
          onClick={() => setNewCallModalOpen(true)}
          startIcon={<Plus />}
        >
          {selectedStatusId === "news" ? "Шинэ мэдээ нэмэх" : "Шинэ рийлс нэмэх"}
        </Button>

        {/* <AddButton
          label="Шинэ дуудлага нэмэх"
          onClickBtn={() => onClickAddButton()}
        /> */}
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {statuses.map((status, index) => (
          <ToggleButton
            key={index}
            label={status.label}
            onClickButton={() => {
              handleButtonClick(status.label, status.id, status.value);
            }}
            textColor={status.color}
            bgColor={status.bgColor}
            selectedStatus={selectedStatus}
          />
        ))}
      </Box>

      {selectedStatusId === "news" ? <NewsTable data={data} query={query} setQuery={setQuery} /> : null}
      {selectedStatusId === "reels" ? <ReelsTable data={data} query={query} setQuery={setQuery} /> : null}

      {/* {openMapModal ? (
        <GoogleMapLocationMapModal
          open={openMapModal}
          handleClose={() => setOpenMapModal(false)}
          setAddressInfo={setAddressInfo}
        />
      ) : null} */}
      {newCallModalOpen ? (
        <NewCallRegisterModal
          open={newCallModalOpen}
          handleClose={() => setNewCallModalOpen(false)}
          userId={user?.id}
          factoryList={factoryList}
          itemList={itemList}
          setQuery={setQuery}
        />
      ) : null}
    </Grid2>
  );
};

export default News;
