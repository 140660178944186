export const BasicButton = ({ label, onClickButton }) => {
    return (
      <button
        className="basic-button LabelLabel---Small"
        key={label}
        onClick={() => onClickButton(label)}
      >
        {label}
      </button>
    );
  };
  