import React, { useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";
import { UserTable } from "../../components/customer/table";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";
import { Plus } from "@phosphor-icons/react";
import CreateCustomerModal from "../../components/modals/CreateCustomer";

const Customer = () => {
  const [customerModalOpen, setCustomerModalOpen ] = useState(false)

  const closeCustomerModal = () => {
    setCustomerModalOpen(false)
  }
  
  return (
    <Grid2> 
        <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Хэрэглэгч
        </HeadlineHeadlineMedium>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2e7d32",
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
          onClick={()=> setCustomerModalOpen(true)}
        >
          Шинэ хэрэглэгч нэмэх
        </Button>
      </Box> 
      <UserTable />

      <CreateCustomerModal open={customerModalOpen} handleClose={closeCustomerModal}/>
    </Grid2>
  );
};

export default Customer
