import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useEffect, useRef, useCallback, useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Plus } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  HeadlineHeadlineMedium,
  HeadlineHeadlineSmall,
  LabelLabelExtraSmall,
  LabelLabelLarge,
  LabelLabelSmall,
} from "../../../utils/styleMethod";
import { toast } from "react-toastify";
import "./map-style.css";
import NewAssignCallModal from "../../modals/NewAssignCall";

const mongolianTrees = [
  ["D001", 48.080083, 106.134301],
  ["D002", 48.186204, 106.099212],
];

const DriverMap = ({ data, selectedDate, setSelectedDate, driverId }) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const [openModalNewCall, setOpenModalNewCall] = useState(false);

  const handleCloseModal = () => {
    setOpenModalNewCall(false);
  };
  const newCallAssignToDriver = () => {
    setOpenModalNewCall(true);
  };
  if (!data) {
    return (
      <div>
        {" "}
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          mb={4}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
              />
            </LocalizationProvider>
            {data ? (
              <BodyBodySmall color="#1D1D1D">
                <span style={{ color: "red", padding: "3px" }}>*</span>
                {`Танд ${data?.driverCode} жолоочийн байршлыг шууд харуулж байна`}{" "}
              </BodyBodySmall>
            ) : (
              ""
            )}
          </Box>
          <Box alignItems={"flex-end"} justifyContent={"center"}>
            <Button
              onClick={() => {
                newCallAssignToDriver();
              }}
              sx={{
                backgroundColor: "green",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "12px",
              }}
            >
              <Plus size={24} color="white" />
              <LabelLabelLarge color="white">
                Дуудлага хуваарилах
              </LabelLabelLarge>
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
          height={"700px"}
          backgroundColor="white"
          alignItems={"center"}
        >
          <LabelLabelLarge >{"Тухайн өдөрт хуваарь олдсонгүй"}</LabelLabelLarge>
          
        </Box>
        {openModalNewCall ? (
          <NewAssignCallModal
            open={openModalNewCall}
            handleClose={handleCloseModal}
            driverId={driverId}
          />
        ) : null}
      </div>
    );
  }

  const locationData = data === undefined ? [] : data.locationForDriverList;

  // daraagiin ochih zogsooliig ylgan awch bn
  const filteredList = locationData?.filter(
    (item) => item.callStatus !== "SUCCESS_SHIPPED"
  );
  const minTimeEntry = filteredList && filteredList.length > 0 
  ? filteredList.reduce((min, item) => {
      return item.time < min.time ? item : min;
    }) 
  : null; 

  const newConvertedArray = locationData?.map(
    ({ time, callStatus, lat, lng }) => [time, callStatus, lat, lng]
  );

  function calculatePercentage(part, whole) {
    if (whole === 0) {
      return 0;
    }
    return (part / whole) * 100;
  }

  return (
    <Box width={"100%"} height={"100vh"} mb={4} backgroundColor="white" p={4}>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        mb={4}
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={(newDate) => setSelectedDate(newDate)}
            />
          </LocalizationProvider>
          {data ? (
            <BodyBodySmall color="#1D1D1D">
              <span style={{ color: "red", padding: "3px" }}>*</span>
              {`Танд ${data?.driverCode} жолоочийн байршлыг шууд харуулж байна`}{" "}
            </BodyBodySmall>
          ) : (
            ""
          )}
        </Box>
        <Box alignItems={"flex-end"} justifyContent={"center"}>
          <Button
            onClick={() => {
              newCallAssignToDriver();
            }}
            sx={{
              backgroundColor: "green",
              paddingLeft: "16px",
              paddingRight: "16px",
              borderRadius: "12px",
            }}
          >
            <Plus size={24} color="white" />
            <LabelLabelLarge color="white">Дуудлага хуваарилах</LabelLabelLarge>
          </Button>
        </Box>
      </Box>

      <Box height={"60%"}>
        <APIProvider apiKey={mapApiKey}>
          <Map
            defaultCenter={{ lat: 47.9172477, lng: 106.9156741 }}
            defaultZoom={12}
            mapId={"DEV_123"}
            disableDefaultUI={false}
            options={{ zoomControl: true }}
          >
            {data == [] ? (
              <Markers points={mongolianTrees} />
            ) : (
              <Markers points={newConvertedArray} />
            )}
          </Map>
        </APIProvider>
      </Box>
      {/* -------------------------------------------- */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }} // Stack on small screens, row on larger
        width="100%"
        mb={4}
        pt={2}
      >
        <Grid2 container spacing={2}>
          {/* Card 1: Driver Code */}
          <Grid2 xs="auto">
            <Box
              p={2}
              sx={{
                width: "300px",
                height: "109px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BodyBodyLarge
                color="var(--on-surface-high)"
                style={{ paddingBottom: "12px" }}
              >
                Жолооч
              </BodyBodyLarge>
              <HeadlineHeadlineMedium color="var(--on-surface-high)">
                {data?.driverCode}
              </HeadlineHeadlineMedium>
            </Box>
          </Grid2>

          {/* Card 2: Next Stop */}
          <Grid2  xs="auto">
            <Box
              p={2}
              sx={{
                width: "300px",
                height: "109px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BodyBodyLarge
                color="var(--on-surface-high)"
                style={{ paddingBottom: "12px" }}
              >
                Дараагийн зогсоол
              </BodyBodyLarge>
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#A9DBAA",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                  }}
                >
                  <HeadlineHeadlineMedium
                    color="var(--on-surface-high)"
                    sx={{ textAlign: "center" }}
                  >
                    {locationData?.length}
                  </HeadlineHeadlineMedium>
                </Box>
                <BodyBodyMedium
                  color="var(--on-surface-high)"
                  sx={{
                    paddingLeft: "16px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1,
                  }}
                >
                  {minTimeEntry?.address}
                </BodyBodyMedium>
              </Box>
            </Box>
          </Grid2>

          {/* Card 3: Accomplished */}
          <Grid2  xs="auto">
            <Box
              p={2}
              sx={{
                width: "300px",
                height: "109px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BodyBodyLarge
                color="var(--on-surface-high)"
                style={{ paddingBottom: "12px" }}
              >
                Ачилт хийсэн
              </BodyBodyLarge>
              <HeadlineHeadlineMedium color="var(--on-surface-high)">
                {
                  locationData?.filter(
                    (call) => call.callStatus === "SUCCESS_SHIPPED"
                  ).length
                }
              </HeadlineHeadlineMedium>
            </Box>
          </Grid2>

          {/* Card 4: Total Accomplished */}
          <Grid2 xs="auto">
            <Box
              p={2}
              sx={{
                width: "300px",
                height: "109px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BodyBodyLarge
                color="var(--on-surface-high)"
                style={{ paddingBottom: "12px" }}
              >
                Нийт ачилт
              </BodyBodyLarge>
              <Box display="flex" alignItems="center">
                <HeadlineHeadlineMedium color="var(--on-surface-high)">
                  {locationData?.length}/10
                </HeadlineHeadlineMedium>
                <BodyBodyLarge sx={{ paddingTop: "8px", paddingLeft: "16px" }}>
                  {`${calculatePercentage(
                    locationData?.filter(
                      (call) => call.callStatus === "SUCCESS_SHIPPED"
                    ).length,
                    10
                  )}% ачилт хийгдсэн`}
                </BodyBodyLarge>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
      {openModalNewCall ? (
        <NewAssignCallModal
          open={openModalNewCall}
          handleClose={handleCloseModal}
          driverId={driverId}
        />
      ) : null}
      {/* -------------------------------------------- */}
    </Box>
  );
};

export default DriverMap;

const Markers = ({ points }) => {
  const map = useMap();
  const markersRef = useRef({});
  const clusterer = useRef(null);

  useEffect(() => {
    if (map && !clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map: map,
        markers: [],
      });
    }
  }, [map]);

  const setMarkerRef = useCallback((marker, key) => {
    if (!marker || markersRef.current[key]) return;

    markersRef.current[key] = marker;

    if (clusterer.current) {
      clusterer.current.addMarker(marker);
    }
  }, []);

  useEffect(() => {
    if (!map || !points) return;

    // Clear any existing polyline
    let polyline = null;

    points.forEach((point, index) => {
      if (index === 0) return;

      const [prevName, prevStatus, prevLng, prevLat] = points[index - 1];
      const [name, status, lng, lat] = point;

      const polylinePath = [
        { lat: Number(prevLat), lng: Number(prevLng) },
        { lat: Number(lat), lng: Number(lng) },
      ];

      const polylineOptions = {
        path: polylinePath,
        geodesic: true,
        strokeColor: status === "SUCCESS_SHIPPED" ? "#2489FF" : "#2489FF",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons:
          status !== "SUCCESS_SHIPPED"
            ? [
                {
                  icon: {
                    path: "M 0,-1 0,1",
                    strokeOpacity: 8,
                    scale: 4,
                  },
                  offset: "0",
                  repeat: "20px",
                },
              ]
            : null,
      };

      polyline = new window.google.maps.Polyline(polylineOptions);
      polyline.setMap(map);
    });

    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [map, points]);

  return (
    <>
      {points?.map((point) => {
        const [name, status, lng, lat] = point;
        const position = { lat: Number(lat), lng: Number(lng) };

        return (
          <AdvancedMarker
            position={position}
            key={name}
            ref={(marker) => setMarkerRef(marker, name)}
          >
            {status === "SUCCESS_SHIPPED" ? (
              <div className="label success">
                <div className="label-text">{name}</div>
                <div className="label-line"></div>
                <div className="label-circle"></div>
              </div>
            ) : (
              <div className="label non-success">
                <div className="label-text">{name}</div>
                <div className="label-line"></div>
                <div className="label-circle"></div>
              </div>
            )}
          </AdvancedMarker>
        );
      })}
    </>
  );
};
