import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import {
  HeadlineHeadlineLarge,
  HeadlineHeadlineMedium,
} from "../../utils/styleMethod";

import { Box, Grid2, IconButton, Menu, MenuItem } from "@mui/material";
import {
  Camera,
  DotsThreeVertical,
  Pencil,
  Trash,
} from "@phosphor-icons/react";
import {
  BodyBodyMedium,
  HeadlineHeadlineSmall,
  TitleTitleMedium,
} from "../../utils/styleMethod";

import "./box-style.css";

// import MergeAccountModal from "../../modals/MergeAccount";
// import CustomerEditModal from "../../modals/CustomerEdit";
// import AddToAdmin from "../../modals/AddToAdmin";
// import AcceptableModal from "../../modals/Acceptable";

const Profile = () => {
  const { user, setUser } = useContext(UserContext);
  //   const { name, profileImage, email } = JSON.parse(user);

  let name, profileImage, email;

  // Check if user is a valid JSON string
  try {
    const parsedUser = user;
    name = parsedUser?.name || "Default Name"; // Set default if not provided
    profileImage = parsedUser?.profileImage || "https://replusmn.s3.amazonaws.com/images/202410/fdf90a8c-0047-4078-8d21-90e46e36ce48.webp"; // Default image
    email = parsedUser?.email || "default@example.com"; // Default email
  } catch (error) {
    console.error("Error parsing user:", error);
    name = "Default Name";
    profileImage = "https://replusmn.s3.amazonaws.com/images/202410/fdf90a8c-0047-4078-8d21-90e46e36ce48.webp";
    email = "default@example.com";
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [customerModalOpen, setCustomerModal] = useState(false);
  const [addToAdmin, setAddToAdmin] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  const openMergeModal = () => setMergeModalOpen(true);

  const customerModalClose = () => setCustomerModal(false);

  const addToAdminModalClose = () => setAddToAdmin(false);

  const mergeAccountClose = () => setMergeModalOpen(false);

  const acceptableModalClose = () => setAcceptableModalOpen(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid2>
      <HeadlineHeadlineMedium color="var(--on-surface-high)">
        Админ профайл
      </HeadlineHeadlineMedium>
      <Box
        sx={{
          // border: "1px solid #E0E0E0",
          borderRadius: "10px",
          p: "12px 32px",
          backgroundColor: "#FFFFFF",
          width: "100%",
          maxWidth: "100vw",
          margin: "0 auto",
          mt: 2,
          boxSizing: "border-box",
        }}
      >
        <Grid2 container spacing={2} mb={4} mt={4}>
          <div style={{ position: "relative", width: "90px", height: "90px" }}>
            <div
              style={{
                position: "absolute",
                zIndex: 2,
                backgroundColor: "#368137",
                borderRadius: "50%",
                width: "54px",
                height: "54px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "62px",
                marginLeft: "62px",
              }}
            >
              <Camera size={32} color="white" />
            </div>

            {/* Profile Image */}
            <img
              src={profileImage}
              alt={profileImage}
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                objectFit: "cover",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                border: "solid #3F9740",
              }}
            />
          </div>

          {/* User Information Section */}
          <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }} ml={4}>
            <Box display="flex" alignItems="center">
              <Box sx={{ flexGrow: 1, paddingLeft: "8px" }}>
                <HeadlineHeadlineSmall color="var(--on-surface-high)"></HeadlineHeadlineSmall>

                <Box
                  display={"flex"}
                  sx={{ borderRadius: "8px", backgroundColor: "#F2F2F2" }}
                  width={148}
                  height={37}
                  justifyContent={"center"}
                  alignContent={"center"}
                  textAlign={"center"}
                  pt={0.7}
                >
                  {" "}
                  <TitleTitleMedium>Супер админ</TitleTitleMedium>
                </Box>
                <HeadlineHeadlineLarge color="var(--on-surface-high)">
                  {name}{" "}
                </HeadlineHeadlineLarge>
                <TitleTitleMedium color="var(--on-surface-high)">
                  {email}
                  {""}
                </TitleTitleMedium>
              </Box>
              {/* More options icon using Phosphor */}
              <IconButton
                onClick={handleClick}
                sx={{
                  "&:hover": { color: "#4CAF50" }, // Change color on hover
                }}
              >
                <DotsThreeVertical size={24} weight="bold" />
              </IconButton>
            </Box>
          </Grid2>
        </Grid2>

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setCustomerModal(true)}>
            {" "}
            <Pencil
              style={{ marginRight: "12px" }}
              color="var( --on-surface-high)"
              size={16}
              weight="light"
            />
            <BodyBodyMedium color="var( --on-surface-high)">
              Хэрэглэгчийн мэдээлэл засах
            </BodyBodyMedium>
          </MenuItem>

          <MenuItem onClick={() => setAcceptableModalOpen(true)}>
            <Trash
              style={{ marginRight: "12px" }}
              color="var( --on-surface-high)"
              size={16}
              weight="light"
            />
            <BodyBodyMedium color="var(--on-surface-high)">
              Устгах
            </BodyBodyMedium>
          </MenuItem>
        </Menu>

        {/* <CustomerEditModal
        data={data}
        open={customerModalOpen}
        handleClose={customerModalClose}
        setUserData={setUserData}
      />


      <AcceptableModal
        title={"Та энэхүү хэрэглэгчийг системээс устгах гэж байна"}
        content={
          "Системээс устгагдсан хэрэглэгч 1 сарын хугацаанд Архив цэсэнд хадгалагдах болно"
        }
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
      /> */}
      </Box>
    </Grid2>
  );
};

export default Profile;
