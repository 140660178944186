import { Box, Button, Grid2, IconButton, Menu, MenuItem } from "@mui/material";
import {
  DotsThreeVertical,
  MapPinLine,
  Pencil,
  Trash,
} from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  HeadlineHeadlineSmall,
  LabelLabelSmall,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import { useEffect, useState } from "react";
import "./box-style.css";
import AcceptableModal from "../../modals/Acceptable";
import DriverCreateEditModal from "../driver-create-edit-modal";
import { mainApi } from "../../../api/main";
import { useNavigate } from "react-router-dom";

export default function DriverBoxDetail({ data }) {
  const [driverData, setDriverData] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);

  const [driverModalOpen, setDriverModal] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setDriverData(data);
    }
  }, [data]);

  const driverModalClose = () => setDriverModal(false);
  const acceptableModalClose = () => setAcceptableModalOpen(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteDriver = async () => {
    mainApi(`driver/${data.userId}`, "DELETE", null);
    navigate(-1);
  };

  return (
    <Box
      sx={{
        // border: "1px solid #E0E0E0",
        borderRadius: "10px",
        p: "12px 32px",
        backgroundColor: "#FFFFFF",
        width: "100%",
        maxWidth: "100vw",
        margin: "0 auto",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <Grid2 container spacing={2} mb={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <img
            src={data.profilePicture}
            alt={data.name}
            style={{
              width: 85,
              height: 85,
              marginRight: "10px",
              border: "1px solid #f2f2f2",
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* User Information Section */}
        <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center">
            <Box sx={{ flexGrow: 1, paddingTop: "12px", paddingLeft: "8px" }}>
              <HeadlineHeadlineSmall color="var(--on-surface-high)">
                {data.userName}
              </HeadlineHeadlineSmall>
              <HeadlineHeadlineSmall color="var(--on-surface-high)">
                {data.driverCode}
              </HeadlineHeadlineSmall>
              <TitleTitleMedium color="var(--on-surface-medium)">
                Жолооч {data.name} · {driverData.phoneNumber}
              </TitleTitleMedium>
            </Box>
            {/* More options icon using Phosphor */}
            <IconButton
              onClick={handleClick}
              sx={{
                "&:hover": { color: "#4CAF50" }, // Change color on hover
              }}
            >
              <DotsThreeVertical size={24} weight="bold" />
            </IconButton>
          </Box>
        </Grid2>
      </Grid2>
      {/* Phone, Email, Address Section */}

      <Grid2 container spacing={2} mb={4}>
        <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center">
            <Box sx={{ flexGrow: 1, paddingTop: "12px", paddingLeft: "8px" }}>
              <Grid2 container spacing={2} mb={4} width="100%">
                <Grid2 xs={12} sm={4} sx={{ mr: 5 }}>
                  <BodyBodyLarge color="var(--on-surface-medium)">
                    Бүртгэлтэй үйлдвэр
                  </BodyBodyLarge>
                  <TitleTitleMedium color="var(--on-surface-high)">
                    {driverData.registeredFactory}
                  </TitleTitleMedium>
                </Grid2>

                <Grid2 xs={12} sm={4} sx={{ mr: 5 }}>
                  <BodyBodyLarge color="var(--on-surface-medium)">
                    Ачилтын чиглэл
                  </BodyBodyLarge>
                  <TitleTitleMedium color="var(--on-surface-high)">
                    {driverData.zoneName}
                  </TitleTitleMedium>
                </Grid2>

                <Grid2 xs={12} sm={4}>
                  <BodyBodyLarge color="var(--on-surface-medium)">
                    Авсан дуудлага
                  </BodyBodyLarge>
                  <TitleTitleMedium color="var(--on-surface-high)">
                    {driverData.totalCall}
                  </TitleTitleMedium>
                </Grid2>
              </Grid2>
            </Box> 
            <Button
            onClick={()=>{navigate(`/driver/detail/location/${data.userId}`)}}
              variant="contained"
              sx={{
                borderRadius: "12px",
                backgroundColor: "#368137",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <MapPinLine size={18} style={{ marginRight: "8px" }} />
              <LabelLabelSmall color="#FFFFFF">
                {"Байршил хянах"}
              </LabelLabelSmall>
            </Button>
          </Box>
        </Grid2>
      </Grid2>

      {/* Dropdown Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => setDriverModal(true)}>
          {" "}
          <Pencil
            style={{ marginRight: "12px" }}
            color="var( --on-surface-high)"
            size={16}
            weight="light"
          />
          <BodyBodyMedium color="var( --on-surface-high)">
            Жолоочийн мэдээлэл засах
          </BodyBodyMedium>
        </MenuItem>

        <MenuItem onClick={() => setAcceptableModalOpen(true)}>
          <Trash
            style={{ marginRight: "12px" }}
            color="var( --on-surface-high)"
            size={16}
            weight="light"
          />
          <BodyBodyMedium color="var(--on-surface-high)">Устгах</BodyBodyMedium>
        </MenuItem>
      </Menu>

      {driverData ? (
        <DriverCreateEditModal
          data={driverData}
          open={driverModalOpen}
          handleClose={driverModalClose}
          setDriverData={setDriverData}
          type="update"
        />
      ) : null}

      <AcceptableModal
        title={"Та энэхүү хэрэглэгчийг системээс устгах гэж байна"}
        content={
          "Системээс устгагдсан хэрэглэгч 1 сарын хугацаанд Архив цэсэнд хадгалагдах болно"
        }
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
        deleteApi={deleteDriver}
        successTitle="Хэрэглэгч амжилттай устгагдлаа"
      />
    </Box>
  );
}
