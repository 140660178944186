import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid2,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { PDFViewer } from "@react-pdf/renderer";
import OrganizationDetail from "../../../components/org-detail";
import UserList from "../../../components/user-list";
import {
    BodyBodyMedium,
    HeadlineHeadlineMedium,
    HeadlineHeadlineSmall,
    tableBodyCellStyle,
    tableHeaderStyle,
    TitleTitleMedium,
    TitleTitleSmall,
} from "../../../utils/styleMethod";
import { CertificatePdf } from "../../../components/factory/certificate-pdf";
import PdfModal from "../../../components/modals/PdfModal";

const products = [
    {
        name: "Түүхий эд",
        imgUrl: "https://replusmn.s3.amazonaws.com/images/202411/5e9bb1fd-eb82-4a44-b287-43f3443f10e8.webp",
    },
    {
        name: "Пресс",
        imgUrl: "https://replusmn.s3.amazonaws.com/images/202411/70bf023b-efb2-4f00-966d-822dd1662c53.webp",
    },
    {
        name: "Хэрчдэс",
        imgUrl: "https://replusmn.s3.amazonaws.com/images/202411/23cde87c-19bb-40f1-8552-bcd279296bf7.webp",
    },
    {
        name: "Хэрчдэс ",
        imgUrl: "https://replusmn.s3.amazonaws.com/images/202411/d54b1a10-b059-4840-b6bd-91c51817f66b.webp",
    },
    {
        name: "Хуруу сав ",
        imgUrl: "https://replusmn.s3.amazonaws.com/images/202411/f520aa6e-d3e4-4382-b029-285b80e15fff.webp",
    },
    {
        name: "rPET савлагаа",
        imgUrl: "https://replusmn.s3.amazonaws.com/images/202411/ab1b1e70-0222-4206-82cf-a98b02da8c8c.webp",
    },
];

const FactoryDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [factoryData, setFactoryData] = useState({});
    const [filteredFactoryUsers, setFilteredFactoryUsers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [callQuery, setCallQuery] = useState({
        page: 1,
        limit: 8,
        sort: "desc", // createdDate
        status: null,
        keyWord: id,
        total: 0,
        searchType: "factory.id",
    });
    const [callHistoryData, setCallHistoryData] = useState([]);

    const classessCell = tableBodyCellStyle();
    const classessHeader = tableHeaderStyle();

    useEffect(() => {
        if (id) {
            findByFactoryId();
            getDrivers();
            callHistory();
        }
    }, []);

    const findByFactoryId = async () => {
        try {
            const res = await mainApi(
                `organization/getById/${id}`,
                "GET",
                null
            );
            setFactoryData(res);
            const factoryRoles = ["FactoryUser", "FactoryWorker", "FactoryAdmin"];
            const factoryUsers = res.users.filter((user) => factoryRoles.includes(user.role));

            setFilteredFactoryUsers(factoryUsers);
        } catch (error) {
            toast.warning("Өгөгдөл олдсонгүй");
        }
    };

    const getDrivers = async () => {
        try {
            const res = await mainApi(`driver/org-list/${id}`, "GET", null);
            setDrivers(res.data);
        } catch (error) {}
    };

    const callHistory = async () => {
        try {
            const res = await mainApi("call/list", "POST", callQuery);
            setCallHistoryData(res.content);
        } catch (error) {}
    };
    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setCallQuery({ ...callQuery, page: newPage });
    };

    return (
        <Grid2>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "12px",
                }}
            >
                <HeadlineHeadlineMedium color="var(--on-surface-high)">
                    Үйлдвэрийн дэлгэрэнгүй
                </HeadlineHeadlineMedium>
            </Box>
            {id ? <OrganizationDetail data={factoryData} /> : "lodading.."}

            <Box p={4} mt={4} backgroundColor="white">
                <HeadlineHeadlineSmall color="var(--on-surface-high)">
                    Үйлдвэрээс гарч буй бүтээгдэхүүнүүд
                </HeadlineHeadlineSmall>

                <Box display={"flex"} pt={2} >
                    {products.map((item, index) => (
                        <Box
                        m={2}
                            key={index}
                            textAlign={"center"}
                        >
                            <img
                                src={item.imgUrl}
                                alt={item.name}
                                style={{
                                    width: "85px",
                                    height: "85px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                }}
                            />
                            <TitleTitleSmall color="var(--on-surface-high)">
                                {item.name}
                            </TitleTitleSmall>
                        </Box>
                    ))}
                </Box>
            </Box>

            {id ? <UserList type={'Factory'} data={filteredFactoryUsers}  organizationId = {factoryData.organizationId}/> : "loading..."}
    

            <Box p={4} backgroundColor="white">
                <HeadlineHeadlineSmall style={{paddingBottom:'24px'}} color="var(--on-surface-high)">Дахивар дуудлагууд</HeadlineHeadlineSmall>
                <Table aria-label="order table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                            {[
                                "Ачигдах огноо",
                                "Дахивар",
                                "Жин",
                                "Жоншны уут",
                                "Жолооч",
                                "Ачилтын хаяг",
                                "Төлөв",
                            ].map((header) => (
                                <TableCell
                                    key={header}
                                    className={classessHeader.tableHeader}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {callHistoryData.map((row, index) => (
                            <TableRow
                                key={index}
                                className={
                                    index % 2 === 1 ? "table-row-odd" : ""
                                }
                            >
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.shipDate}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.itemsGroupName}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.measure}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.qty}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.driverCode}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.address}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.status}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                        count={Math.ceil(callQuery.total / callQuery.limit)}
                        page={callQuery.page}
                        onChange={handleChangePage}
                        siblingCount={1}
                        boundaryCount={1}
                    />
                </Box>
            </Box>

            <Box p={4} backgroundColor="white" mb={4} mt={2}>
                <Box pb={2} display={"flex"}>
                    <HeadlineHeadlineSmall color="var(--on-surface-high)">
                        Бүртгэлтэй жолоочид
                    </HeadlineHeadlineSmall>{" "}
                    <BodyBodyMedium
                        color="6C6C6C"
                        style={{ paddingTop: "8px" }}
                    >
                        {" "}
                        | Нийт {drivers.length} жолооч
                    </BodyBodyMedium>
                </Box>

                <Grid2 container spacing={3}>
                    {drivers.map((item, index) => (
                        <Grid2
                            key={index}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                    padding: "12px",
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    width: "100%",
                                    maxWidth: "258px",
                                    backgroundColor: "#fff",
                                }}
                            >
                                {/* Left Column: Image */}
                                <Box
                                    component="img"
                                    src={
                                        factoryData.profileImage ||
                                        "default-profile.png"
                                    }
                                    alt={item.userName}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        marginRight: "8px",
                                    }}
                                />

                                {/* Right Column: Text Information */}

                                <Box>
                                    <TitleTitleMedium color="var(--on-surface-high)">
                                        {item.driverCode}
                                    </TitleTitleMedium>
                                    <BodyBodyMedium color="var(--on-surface-medium)">
                                        {item.driverName} · {item.phoneNumber}
                                    </BodyBodyMedium>
                                </Box>
                            </Box>
                        </Grid2>
                    ))}
                </Grid2>
            </Box>

        </Grid2>
    );
};

export default FactoryDetail;
