import React, { useState } from "react";
import { Modal, Box, Button, Grid2, TextField, CircularProgress } from "@mui/material";
import { ArrowRight, X } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  HeadlineHeadlineMedium,
  LabelLabelLarge,
} from "../../utils/styleMethod";
import SuccessModal from "./Success";
import { useForm } from "react-hook-form";
import { mainApi } from "../../api/main";

const CreateCustomerModal = ({ open, handleClose }) => {
  const [contactValue, setContactValue] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { contact } = data;
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact);
    const isPhone = /^[89]\d{7}$/.test(contact);
    setContactValue(contact)
    if (isEmail) {
      setLoading(true); // Start loading
      const json = {
        email: contact,
        subject: "Replus",
        text: "Та https://replus.mn - ээр орж бүртгүүлнэ үү, баярлалаа",
      };
      try {
        await mainApi("email/send", "POST", json);
        handleClose();
        setOpenSuccessModal(true);
      } catch (error) {
        console.error("Email send error:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    } else if (isPhone) {
      console.log("Phone number entered:", contact);
    } else {
      console.log("Invalid input format");
    }
  };

  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box display="flex" justifyContent="end" width="100%">
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" width="100%" mb={2}>
            <HeadlineHeadlineMedium>Шинэ хэрэглэгч нэмэх</HeadlineHeadlineMedium>
          </Box>
          <Box display="flex" textAlign="center" width="100%" mb={3}>
            <BodyBodyLarge>
              Та шинээр нэмэх хэрэглэгчийн утас эсвэл и-мэйл хаягыг оруулна уу
            </BodyBodyLarge>
          </Box>

          <Grid2 container spacing={2} justifyContent="center">
            <Grid2 xs={12} mb={2}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "300px" }}>
                <TextField
                  {...register("contact", {
                    required: "Утасны дугаар эсвэл и-мэйл хаягаа оруулна уу",
                    pattern: {
                      value: /^(^[89]\d{7}$|^[^\s@]+@[^\s@]+\.[^\s@]+$)/,
                      message: "И-мэйл эсвэл 8 оронтой утасны дугаар оруулна уу",
                    },
                  })}
                  error={!!errors.contact}
                  helperText={errors.contact?.message}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                />

                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    sx={{
                      height: "45px",
                      border: "1px solid #368137",
                      borderRadius: "16px",
                      padding: "16px",
                      color: "#368137",
                    }}
                    variant="contained"
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <LabelLabelLarge color="white">
                        Урилга илгээх <ArrowRight size={20} />
                      </LabelLabelLarge>
                    )}
                  </Button>
                </Box>
              </form>
            </Grid2>
          </Grid2>
        </Box>
      </Modal>
      <SuccessModal
        title=""
        content={`${contactValue}-д шинэ хэрэглэгчийн урилга амжилттай илгээгдлээ`}
        open={openSuccessModal}
        handleClose={closeSuccessModal}
      />
    </>
  );
};

export default CreateCustomerModal;
