import React, { useEffect, useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { PDFViewer } from "@react-pdf/renderer";
import OrganizationDetail from "../../../components/org-detail";
import UserList from "../../../components/user-list";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";
import { CertificatePdf } from "../../../components/factory/certificate-pdf";
import PdfModal from "../../../components/modals/PdfModal";

const FactoryDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [factoryData, setFactoryData] = useState({});
  const [pdfOpen, setPdfOpen] = useState(false);
  const [filteredFactoryUsers, setFilteredFactoryUsers] = useState([])

  useEffect(() => {
    if (id) {
      findByFactoryId();
    }
  }, []);

  const findByFactoryId = async () => {
    try {
      const res = await mainApi(`organization/getById/${id}`, "GET", null);
      setFactoryData(res);
      const factoryUsers = res.users.filter(user => user.role === "FactoryUser");
      setFilteredFactoryUsers(factoryUsers)
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Үйлдвэрийн дэлгэрэнгүй
        </HeadlineHeadlineMedium>
      </Box>
      {id ? <OrganizationDetail data={factoryData} /> : "lodading.."}
      <Button
        sx={{ background: "green", color: "white" }}
        onClick={() => setPdfOpen(true)}
      >
        CERTIFICATE OPEN
      </Button>
      {id ? <UserList data={filteredFactoryUsers} /> : "loading..."}

      <PdfModal open={pdfOpen} handleClose={() => setPdfOpen(false)} />
    </Grid2>
  );
};

export default FactoryDetail;
