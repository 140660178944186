// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .camera-icon {
    cursor: pointer;
    background-color: #368137;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .camera-icon:hover {
    background-color: #285e2f;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .uploading-message {
    font-size: 14px;
    margin-top: 10px;
    color: #368137;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/camera-upload/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".upload-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n  }\n  \n  .camera-icon {\n    cursor: pointer;\n    background-color: #368137;\n    padding: 12px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .camera-icon:hover {\n    background-color: #285e2f;\n  }\n  \n  .error-message {\n    color: red;\n    font-size: 14px;\n    margin-top: 10px;\n  }\n  \n  .uploading-message {\n    font-size: 14px;\n    margin-top: 10px;\n    color: #368137;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
