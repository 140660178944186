import { createContext } from "react";

// Providing a default value for the context
export const UserContext = createContext({
    user: {},
    activeMenu: 0,
    setUser: () => {},
    setActiveMenu: () => {},
    toggleMenu: () => {},
    saveUser: () => {}
});
