import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import {
  HeadlineHeadlineMedium,
  TitleTitleMedium,
  TitleTitleSmall,
} from "../../utils/styleMethod";
import SuccessModal from "./Success";

const AddToAdmin = ({ open, handleClose }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const users = [
    "User 1",
    "User 2",
    "User 3",
    "User 4",
    "User 5",
    "User 6",
    "User 7",
    "User 8",
    "User 9",
    "User 10",
    "User 11",
  ];

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleSearchChange = (event, value) => {
    setSearchValue(value);
    setSelectedUser(value); // Set the selected user from the dropdown
    console.log("event.target.value ------> ", event.target.value);
  };
  const handleSave = () => {
    console.log("Selected Role:", selectedRole);
    console.log("Search Value:", searchValue);
    // Add your save logic here
    // handleClose();
    setOpenSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 445,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <X size={24} color="green" />
            </IconButton>
          </Box>

          <Box display="flex" justifyContent="center" mb={3}>
            <HeadlineHeadlineMedium color="var(--on-surface-high)">
              Админаар нэмэх
            </HeadlineHeadlineMedium>
          </Box>

          {/* Search Field */}
          <Autocomplete
            freeSolo
            options={users.slice(0, 10)} // Limit to 10 suggestions
            inputValue={searchValue}
            onInputChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="үйлдвэр хайх..."
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <MagnifyingGlass size={24} style={{ marginRight: 8 }} />
                  ),
                }}
              />
            )}
            sx={{ mb: 2 }}
          />
          <FormControl component="fieldset" sx={{ mb: 3, mt: 2 }}>
            <TitleTitleMedium color="var(--on-surface-high)">
              Админаар нэмэх чиглэл сонгох
            </TitleTitleMedium>
            <RadioGroup
              value={selectedRole}
              onChange={handleRoleChange}
              sx={{
                paddingTop: "12px",
                display: "flex",
                flexDirection: "row", // Ensure vertical alignment
              }}
            >
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label={
                  <TitleTitleSmall color="var(--on-surface-high)">
                    Админ
                  </TitleTitleSmall>
                }
                sx={{
                  paddingTop: "12px",
                  marginBottom: "8px", // Optional for spacing between radio buttons
                }}
              />
              <FormControlLabel
                value="assistant"
                control={<Radio />}
                label={
                  <TitleTitleSmall color="var(--on-surface-high)">
                    Туслах
                  </TitleTitleSmall>
                }
                sx={{
                  paddingTop: "12px",
                  marginBottom: "8px", // Optional for spacing
                }}
              />
            </RadioGroup>
          </FormControl>

          <Box display="flex" justifyContent="center" mb={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#368137",
                color: "white",
                borderRadius: "8px",
                alignItems: "center",
                padding: "12px",
                "&:hover": { backgroundColor: "#2c6e30" },
              }}
              onClick={handleSave}
            >
              Хадгалах
            </Button>
          </Box>
        </Box>
      </Modal>
      <SuccessModal
        title="Админаар нэмэх"
        content="88888888 дугаартай хэрэглэгч “TML Plastic” үйлдвэрт туслах админаар бүртгэгдлээ"
        open={openSuccessModal}
        handleClose={closeSuccessModal}
      />
    </>
  );
};

export default AddToAdmin;
