import React, { useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { rows } from "../../../utils/method";
import OrganizationDetail from "../../../components/org-detail";
import UserList from "../../../components/user-list";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";

const EnterpriseDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [enterpriseData, setEnterpriseData] = useState({});
  const [filteredEnterpriseUsers, setFilteredEnterpriseUsers] = useState([])
  useEffect(() => {
    if (id) {
      findByEnterpriseId();
    }
  }, []);

  const findByEnterpriseId = async () => {
    try {
      const res = await mainApi(`organization/getById/${id}`, "GET", null);
      setEnterpriseData(res);
      const enterpriseUsers = res.users.filter(user => user.role === "SUHuser");
      setFilteredEnterpriseUsers(enterpriseUsers)
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
        Аж ахуйн нэгжийн дэлгэрэнгүй
        </HeadlineHeadlineMedium>
      </Box>
      {id ? <OrganizationDetail data={enterpriseData} /> : "lodading.."}
      {id ? <UserList data={filteredEnterpriseUsers} /> : "loading..."}
    </Grid2>
  );
};

export default EnterpriseDetail;
