import { Box, Button, Grid2, IconButton, Menu, MenuItem } from "@mui/material";
import {
  DotsThreeVertical,
  EnvelopeOpen,
  Intersect,
  Leaf,
  MapPinLine,
  Pencil,
  Phone,
  Recycle,
  Trash,
  UserPlus,
} from "@phosphor-icons/react";
import {
  BodyBodyMedium,
  BodyBodySmall,
  HeadlineHeadlineSmall,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import { useEffect, useState } from "react";
import "./box-style.css";
import MergeAccountModal from "../../modals/MergeAccount";
import CustomerEditModal from "../../modals/CustomerEdit";
import AddToAdmin from "../../modals/AddToAdmin";
import AcceptableModal from "../../modals/Acceptable";

export default function CustomerBoxDetail({ data }) {
  const [userData, setUserData] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [customerModalOpen, setCustomerModal] = useState(false);
  const [addToAdmin, setAddToAdmin] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setUserData(data);
    }
  }, [data]);

  // MergeModal open
  const openMergeModal = () => setMergeModalOpen(true);

  const customerModalClose = () => setCustomerModal(false);

  const addToAdminModalClose = () => setAddToAdmin(false);

  const mergeAccountClose = () => setMergeModalOpen(false);

  const acceptableModalClose = () => setAcceptableModalOpen(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        // border: "1px solid #E0E0E0",
        borderRadius: "10px",
        p: "12px 32px",
        backgroundColor: "#FFFFFF",
        width: "100%",
        maxWidth: "100vw",
        margin: "0 auto",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <Grid2 container spacing={2} mb={4}>
        {/* Logo Section */}
        <div style={{ position: "relative", width: "90px", height: "90px" }}>
          {/* Laurels Image */}
          <img
            src="/images/Laurels.svg"
            alt="laurels"
            style={{
              marginTop: "12px",
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 2,
            }}
          />

          {/* Profile Image */}
          <img
            src={data.profilePicture}
            alt={data.profilePicture}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              objectFit: "cover",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          />
        </div>

        {/* User Information Section */}
        <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center">
            <Box sx={{ flexGrow: 1, paddingTop: "12px", paddingLeft: "8px" }}>
              <HeadlineHeadlineSmall color="var(--on-surface-high)">
                {data.userName}
              </HeadlineHeadlineSmall>

              <div className="button-container">
                {/* First button with progress bar */}
                <div className="button">
                  <div className="icon">
                    <Leaf size={24} weight="duotone" color="#4CAF50" />
                  </div>
                  <BodyBodySmall>Идэвхтэн</BodyBodySmall>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${78}%` }}></div>
                  </div>
                </div>

                {/* Second button with score */}
                <div className="button">
                  <div className="icon">
                    <Recycle size={24} weight="duotone" color="#4CAF50" />
                  </div>
                  <BodyBodySmall>828 оноо</BodyBodySmall>
                </div>
              </div>
            </Box>
            {/* More options icon using Phosphor */}
            <IconButton
              onClick={handleClick}
              sx={{
                "&:hover": { color: "#4CAF50" }, // Change color on hover
              }}
            >
              <DotsThreeVertical size={24} weight="bold" />
            </IconButton>
          </Box>
        </Grid2>
      </Grid2>

      {/* Phone, Email, Address Section */}
      <Grid2 container spacing={2} mb={4}>
        <Grid2 xs={12} sm={4} sx={{ mr: 5 }}>
          {" "}
          {/* Added right margin */}
          <Phone size={24} weight="duotone" />
          <TitleTitleMedium color="var(--on-surface-high)">
            {userData.phoneNumber }
          </TitleTitleMedium>
        </Grid2>
        <Grid2 xs={12} sm={4} sx={{ mr: 5 }}>
          {" "}
          {/* Added right margin */}
          <EnvelopeOpen size={24} weight="duotone" />
          <TitleTitleMedium color="var(--on-surface-high)">
            {userData.email}
          </TitleTitleMedium>
        </Grid2>
        <Grid2 xs={12} sm={4}>
          <MapPinLine size={24} weight="duotone" />

          <TitleTitleMedium color="var(--on-surface-high)">
            {userData.address}
          </TitleTitleMedium>
        </Grid2>
      </Grid2>

      {/* Dropdown Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => setCustomerModal(true)}>
          {" "}
          <Pencil
            style={{ marginRight: "12px" }}
            color="var( --on-surface-high)"
            size={16}
            weight="light"
          />
          <BodyBodyMedium color="var( --on-surface-high)">
            Хэрэглэгчийн мэдээлэл засах
          </BodyBodyMedium>
        </MenuItem>

        <MenuItem onClick={openMergeModal}>
          <Intersect size={16} weight="light" style={{ marginRight: "12px" }} />
          <BodyBodyMedium color="var( --on-surface-high)">
            Аккоунт нэгтэх
          </BodyBodyMedium>
        </MenuItem>

        <MenuItem onClick={() => setAddToAdmin(true)}>
          <UserPlus size={16} weight="light" style={{ marginRight: "12px" }} />
          <BodyBodyMedium color="var( --on-surface-high)">
            Админаар нэмэх
          </BodyBodyMedium>
        </MenuItem>

        <MenuItem onClick={() => setAcceptableModalOpen(true)}>
          <Trash
            style={{ marginRight: "12px" }}
            color="var( --on-surface-high)"
            size={16}
            weight="light"
          />
          <BodyBodyMedium color="var(--on-surface-high)">Устгах</BodyBodyMedium>
        </MenuItem>
      </Menu>

      <MergeAccountModal
        open={mergeModalOpen}
        handleClose={mergeAccountClose}
        data={data}
        setUserData={setUserData}
      />

      <CustomerEditModal
        data={data}
        open={customerModalOpen}
        handleClose={customerModalClose}
        setUserData={setUserData}
      />
      <AddToAdmin open={addToAdmin} handleClose={addToAdminModalClose} />

      <AcceptableModal
        title={"Та энэхүү хэрэглэгчийг системээс устгах гэж байна"}
        content={
          "Системээс устгагдсан хэрэглэгч 1 сарын хугацаанд Архив цэсэнд хадгалагдах болно"
        }
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
      />
    </Box>
  );
}




