import React from "react";
import {
  BlobProvider,
  Document,
  Font,
  Image,
  PDFDownloadLink,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import { Box, Button, Modal, Typography } from "@mui/material";

// Register the font globally
Font.register({
  family: "Noto Sans Mongolian",
  src: "/fonts/NotoSans-Regular.ttf", // Adjust the path if needed
});

export const CertificatePdf = ({ data }) => {
  console.log('pdf data----> ' , data);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}.${
    currentDate.getMonth() + 1
  }.${currentDate.getDate()} ${
    currentDate.getDate() === 1 ? "1" : ""
  }`;

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View
          style={{
            padding: "0px",
            margin: "0px",
            position: "relative",
            height: "100%", // Ensure full height
          }}
        >
          {/* Background Image */}
          <Image
            style={{
              width: "100%",
              height: "100%",
            }}
            source="/images/background.png"
          />

          <View
            style={{
              position: "absolute",
              top: "2px",
              width: "100%",
              padding: "0 1px",
            }}
          >
            <View
              style={{
                position: "absolute",
                right: "10px",
              }}
            >
              <Image
                style={{
                  width: "130px",
                }}
                source="/images/TML-logo.png"
              />
            </View>

            {/* Centered Text Content */}
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: "180px", // Adjusts spacing from the top
              }}
            >
              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "32px",
                }}
              >
                <Text
                  style={{
                    fontSize: "52px", // Adjusted font size for better fit
                    color: "#2A2A2A",
                    fontFamily: "Noto Sans Mongolian",
                    marginBottom: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Байгууллагын нэр
                </Text>
                <Text
                  style={{
                    fontSize: "14px", // Adjusted font size for better fit
                    color: "#2A2A2A",
                    fontFamily: "Noto Sans Mongolian",
                    marginBottom: "20px",
                    paddingLeft: "90px", // Reduced padding for better fit
                    paddingRight: "90px",
                    textAlign: "center",
                  }}
                >
                  Нийгмийн хариуцлагын хүрээнд 2024 онд нийт
                  <Text style={{ color: "green" }}> {1800}кг </Text> хуванцар
                  хог хаягдлыг байгальд хаягдахаас сэргийлж,
                  <Text style={{ color: "green" }}>{96}</Text> м3 талбайг хогийн
                  цэг болохоос сэргийлсэн.{" "}
                  <Text style={{ color: "green" }}>{5.055} kg</Text> нефтийн
                  бүтээгдэхүүнийг хэмнэсэн{" "}
                  <Text style={{ color: "green" }}> 10,226,555л </Text>цэвэр
                  усны нөөцийг хэмнэсэн{" "}
                  <Text style={{ color: "green" }}>2,666,333кг </Text>нүүрс
                  хүчлийн хийн ялгарлыг бууруулсан үнэтэй хувь нэмрийг оруулсан
                  тул сертификат олгов.
                </Text>
              </View>
            </View>

            {/* Bottom Section with Two Columns */}
            <View
              style={{
                display: "flex",
                flexDirection: "row", // Two columns
                justifyContent: "space-between",
                marginTop: "40px", // Adds space above columns
              }}
            >
              {/* First column */}
              <View
                style={{
                  flex: 1,
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "18px",
                    color: "#2A2A2A",
                    fontFamily: "Noto Sans Mongolian",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  {"Ч.АГИЙМАА"}
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    color: "#2A2A2A",
                    fontFamily: "Noto Sans Mongolian",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {"ТЖТБ ХХК-ийн Гүйцэтгэх захирал"}
                </Text>
              </View>

              {/* Second column */}
              <View
                style={{
                  flex: 1,
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "18px",
                    color: "#2A2A2A",
                    fontFamily: "Noto Sans Mongolian",
                    fontWeight: "bold",
                    marginTop: "12px",
                  }}
                >
                  {"Л.ДАВАА"}
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    color: "#2A2A2A",
                    fontFamily: "Noto Sans Mongolian",
                    fontWeight: "bold",
                  }}
                >
                  {"Эс И Эс Эл Смарт Солюшн ХХК-ийн\nГүйцэтгэх захирал"}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              position: "absolute",
              bottom: "20px", // Increased distance from the bottom
              width: "100%", // Full width
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            <Image
              style={{
                width: "100px", // Logo width
              }}
              source="/images/save-logo.png" // Ensure this path is correct
            />
            <Text
              style={{
                fontSize: "12px", // Font size for date
                color: "#2A2A2A",
                fontFamily: "Noto Sans Mongolian",
              }}
            >
              {formattedDate} {/* Display the current date */}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
