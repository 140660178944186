// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 355px;
}

.image-box {
  width: 100%;
  min-width: 350px;
  height: 180px;
  border: 2px dashed #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;
}

.image-box.dragging {
  background-color: #f0f8ff; 
  border-color: #00aaff; 
}

.upload-prompt {
  color: #666;
  font-size: 14px;
  text-align: center;
}

.upload-prompt img {
  width: 40px;
  margin-bottom: 10px;
}

.preview-image {
  max-width: 250px;
  max-height: 160px;
  object-fit: contain; 
}
.info-text {
  width: 100%;
  min-width: 350px;
  border-right: 2px dashed #ddd;
  border-bottom: 2px dashed #ddd;
  border-left: 2px dashed #ddd;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/image-upload/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,6BAA6B;EAC7B,8BAA8B;EAC9B,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".upload-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  max-width: 355px;\n}\n\n.image-box {\n  width: 100%;\n  min-width: 350px;\n  height: 180px;\n  border: 2px dashed #ddd;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  cursor: pointer;\n  transition: background-color 0.2s;\n}\n\n.image-box.dragging {\n  background-color: #f0f8ff; \n  border-color: #00aaff; \n}\n\n.upload-prompt {\n  color: #666;\n  font-size: 14px;\n  text-align: center;\n}\n\n.upload-prompt img {\n  width: 40px;\n  margin-bottom: 10px;\n}\n\n.preview-image {\n  max-width: 250px;\n  max-height: 160px;\n  object-fit: contain; \n}\n.info-text {\n  width: 100%;\n  min-width: 350px;\n  border-right: 2px dashed #ddd;\n  border-bottom: 2px dashed #ddd;\n  border-left: 2px dashed #ddd;\n  padding: 15px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n}\n\n.error-message {\n  color: red;\n  font-size: 14px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
