import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Grid2,
  InputLabel,
  Typography,
  FormHelperText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AnimationPage from "../AnimationPage";
import { UserContext } from "../context/user";
import { ArrowRight } from "@phosphor-icons/react";
import { login } from "../api/main";
import { toast } from "react-toastify";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "solid 1px",
    borderColor: "#A9DBAA",
    height: "45px",
  },
  "& .MuiInputBase-input": {
    // padding: "15px 25px",
    fontSize: "15px",
    lineHeight: "20px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
});

const Login = () => {
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { saveUser } = useContext(UserContext);
  const spacingValue = { xs: 2, md: 3 };
  const columnValue = { xs: 4, sm: 8, md: 12 };

  const submit = async () => {
    const { email, password } = getValues();

    setLoader(true);
    await login(email, password.toString())
      .then((res) => {
        if (res.statusCode === 200) {
          saveUser(res.data);
          // toast.success(res.message);
          navigate("/");
        } else {
          toast.warning(res.message);
        }

        setLoader(false);
      })
      .catch((error) => {
        toast.warning(error);
        setLoader(false);
      });
  };

  return (
    <AnimationPage>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Grid2
          container
          spacing={spacingValue}
          columns={columnValue}
          sx={{
            flexGrow: 1,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid2
            size={{ xs: 2, sm: 4, md: 6 }}
            sx={{
              flexGrow: 1,
              height: "100vh",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Box width="557px" marginRight={"40px"}>
              <img
                style={{ width: "100%" }}
                src="/images/Eco solution logo.png"
                alt="login"
              />
            </Box>
          </Grid2>
          <Grid2
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid2
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "600px",
                  padding: "48px",
                  borderRadius: "24px",
                  border: "solid 1px",
                  borderColor: "#A9DBAA",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 45,
                    fontWeight: 800,
                    color: "#153216",
                    textAlign: "center",
                  }}
                >
                  Нэвтрэх
                </Typography>
                <Box
                  textAlign="center"
                  sx={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingLeft: "16px",
                      }}
                    >
                      Имэйл{" "}
                    </InputLabel>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Имэйл оруулах шаардлагатай",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Имэйл хаяг буруу байна",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <StyledTextField
                            {...field}
                            placeholder="Имэйл оруулах"
                            variant="outlined"
                            fullWidth
                          />
                          {errors.email && (
                            <FormHelperText error>
                              {errors.email.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Box>

                  <Box sx={{ paddingTop: "30px" }}>
                    <InputLabel
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingLeft: "16px",
                      }}
                    >
                      Нууц үг{" "}
                    </InputLabel>
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: "Нууц үг оруулах шаардлагатай",
                        minLength: {
                          value: 3,
                          message: "Хамгийн багадаа 3 тэмдэгттэй байх ",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <StyledTextField
                            {...field}
                            type="password"
                            placeholder="Нууц үг оруулах"
                            variant="outlined"
                            fullWidth
                          />
                          {errors.password && (
                            <FormHelperText error>
                              {errors.password.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Box>

                  <Button
                    sx={{
                      width: "146px",
                      height: "46px",
                      backgroundColor: "#368137",
                      borderRadius: "12px",
                      marginTop: "30px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                    disabled={loader}
                    variant="contained"
                    onClick={handleSubmit(submit)}
                  >
                    Нэвтрэх
                    <ArrowRight size={20} />
                  </Button>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </AnimationPage>
  );
};

export default Login;
