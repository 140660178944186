import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, IconButton, Menu, MenuItem } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Plus, DotsThreeVertical, Pencil, Trash } from "@phosphor-icons/react";

import { useForm } from "react-hook-form";
import { roles } from "../../../utils/method";

import { tableBodyCellStyle, tableHeaderStyle, BodyBodyMedium } from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";
import { toast } from "react-toastify";
import PointDetail from "./pointDetail";
import YesNoDialog from "../../../components/modals/YesNoDialog";
import { ToggleButton } from "../../../components/form/buttons/toggle-button";
import { useNavigate } from "react-router-dom";
import DonationModal from "./donationModal";

export const DonationList = () => {
  const navigate = useNavigate();
  const { control } = useForm({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showDetail, setShowDetail] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [query, setQuery] = useState({ roleName: Array.isArray(roles) && roles.length > 0 ? roles[0].value : "" });
  const [data, setData] = useState([]);

  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();

  useEffect(() => {
    if (isLoaded) return;
    getList();
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    getList();
  }, [query.roleName]);

  const getList = async () => {
    if (isLoading) return;
    try {
      setData([]);
      setIsLoading(true);
      const res = await mainApi(`settings/admin/donation/list`, "GET", null);
      setData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error");
    }
  };

  const handleCheckboxChange = (systemResourceCode) => {
    setData((prevRows) => prevRows.map((row) => (row.systemResourceCode === systemResourceCode ? { ...row, checked: !row.checked } : row)));
  };

  const saveData = async () => {
    try {
      let params = {
        roleName: query.roleName,
        resourceRoleInfos: data.filter((item) => item.checked),
      };
      const res = await mainApi("role/resource/save", "POST", params);
      getList();
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  const openDetail = () => {
    setShowDetail(true);
    setIsEditMode(false);
    setCurrentData({});
  };

  const openDetailEdit = () => {
    setShowDetail(true);
    setIsEditMode(true);
  };

  const closeDetail = (isRefresh) => {
    if (isRefresh) getList();
    setShowDetail(false);
  };

  const handleClick = (row) => {
    navigate(`/settings/donations/detail/${row.id}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const acceptableModalClose = () => {
    setAcceptableModalOpen(false);
    setAnchorEl(false);
  };

  const deleteData = async () => {
    try {
      const res = await mainApi(`settings/admin/point/config/delete/${currentData?.id}`, "DELETE", null);

      acceptableModalClose();
      toast.success("Амжилттай устгалаа");
      getList();
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Устгах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Хандив
        </p>
        <Box
          sx={{
            display: "flex", // Enable flexbox
            flexDirection: "row", // Align items horizontally
            alignItems: "center", // Vertically center items
          }}
        >
          <Button
            variant="contained"
            sx={{
              "backgroundColor": "#2e7d32",
              "borderRadius": "24px",
              "padding": "8px 24px",
              "textTransform": "none",
              "fontWeight": "bold",
              "display": "flex",
              "alignItems": "center",
              "&:hover": {
                backgroundColor: "#1b5e20",
              },
            }}
            onClick={openDetail}
            startIcon={<Plus size={24} />}
          >
            Шинэ хандив нэмэх
          </Button>
        </Box>
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["№", "Хандив", "Идэвхтэй эсэх", "Тухай"].map((header, index) => (
              <TableCell
                key={index}
                className={classessHeader.tableHeader}
                sx={{
                  width: index === 0 || index === 3 ? "80px" : index === 2 ? "200px" : "auto",
                  textAlign: index === 0 || index === 2 || index === 3 ? "center" : "left",
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.callId || index} className={index % 2 === 1 ? "table-row-odd" : ""}>
              <TableCell className={classessCell.tableBodyCellCenter} sx={{ width: "80px" }}>
                {index + 1}
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.name}</TableCell>

              <TableCell
                className={classessCell.tableBodyCellCenter}
                sx={{
                  width: "200px",
                }}
              >
                <Checkbox checked={row.active} disabled={true} />
              </TableCell>
              <TableCell
                className={classessCell.tableBodyCellRight}
                sx={{
                  width: "80px",
                }}
              >
                <ToggleButton label={"Дэлгэрэнгүй"} onClickButton={() => handleClick(row)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DonationModal key={"donationModal"} open={showDetail} handleClose={closeDetail} isEditMode={false} />

      <YesNoDialog
        title={"Та оноо цуглуулах боломжийг устгахдаа итгэлтэй байна уу?"}
        content={""}
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
        handleConfirm={deleteData}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            openDetailEdit();
            setAnchorEl(false);
          }}
        >
          {" "}
          <Pencil style={{ marginRight: "12px" }} color="var( --on-surface-high)" size={16} weight="light" />
          <BodyBodyMedium color="var( --on-surface-high)">Засварлах</BodyBodyMedium>
        </MenuItem>
        <MenuItem onClick={() => setAcceptableModalOpen(true)}>
          <Trash style={{ marginRight: "12px" }} color="var( --on-surface-high)" size={16} weight="light" />
          <BodyBodyMedium color="var( --on-surface-high)">Устгах</BodyBodyMedium>
        </MenuItem>
      </Menu>
    </TableContainer>
  );
};
