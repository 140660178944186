import { UserContext } from "../../context/user";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid2, Paper, TableContainer, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import { ToggleButton } from "../../components/form/buttons/toggle-button";
import { mainApi } from "../../api/main";
import ArrowLeftBtn from "../../components/form/buttons/arrow-left";
import ArrowRightBtn from "../../components/form/buttons/arrow-right";
import GridCardComponent from "../../components/grid-card-component";
import {
  BodyBodyMedium,
  HeadlineHeadlineMedium,
  HeadlineHeadlineSmall,
} from "../../utils/styleMethod";
import CreateOrganizationModal from "../../components/modals/CreateOrganization";

const initialQuery = {
  page: 1,
  limit: 8,
  total: 0,
  type: "DROPOFF",
  itemGroupId: 0,
};

const DropOff = () => {
  const { user, setUser } = useContext(UserContext);

  const [query, setQuery] = useState(initialQuery);

  const [selectedStatus, setSelectedStatus] = useState("Бүгд");
  const [selectedStatusId, setSelectedStatusId] = useState(1);
  const [dropOffData, setDropOffData] = useState();
  const [category, setCategory] = useState([
    { id: 0, name: "Бүгд", code: "A001" },
  ]);
  const [itemList, setItemList] = useState([]);
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);

  useEffect(() => {
    getCategory();
    getItemList();
  }, []);

  useEffect(() => {
    getAllFactory();
  }, [query, selectedStatusId]);

  const getCategory = async () => {
    const res = await mainApi("items/group/list", "GET", null);
    setCategory((prevCategories) => {
      const newCategories = res.filter(
        (newCat) => !prevCategories.some((prevCat) => prevCat.id === newCat.id)
      );

      if (newCategories.length > 0) {
        return [...prevCategories, ...newCategories];
      }
      return prevCategories; // No change if no new categories
    });
  };
  const getItemList = async () => {
    const res = await mainApi("items/group/list", "GET", null);
    setItemList(res);
  };

  const getAllFactory = useCallback(async () => {
    try {
      const res = await mainApi("organization/", "POST", query);
      setDropOffData(res.content);

      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery; //
      });
    } catch (err) {
      console.error("DROPOFF fetch error:", err);
    } finally {
      console.log("end");
    }
  }, [query]);

  const handleButtonClick = (label, id) => {
    console.log("selected label: ", label);
    setSelectedStatus(label);
    setSelectedStatusId(id);
    setQuery({ ...query, itemGroupId: id });
  };

  const onClickButtonLeft = async () => {
    setQuery({ ...query, page: query.page - 1 });
    getAllFactory();
  };
  const onClickButtonRight = async () => {
    setQuery({ ...query, page: query.page + 1 });
    getAllFactory();
  };
  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Цэг
        </HeadlineHeadlineMedium>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2e7d32",
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
          onClick={() => setOpenOrganizationModal(true)}
        >
          Шинэ цэг нэмэх
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {category.map((status, index) => (
          <ToggleButton
            key={index}
            label={status.name}
            onClickButton={() => {
              handleButtonClick(status.name, status.id);
            }}
            textColor="var(--primary-primary)"
            bgColor="var(--primary-soft-primary-soft)"
            selectedStatus={selectedStatus}
          />
        ))}
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "64px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HeadlineHeadlineSmall color="var(--on-surface-high)">
              Дахивар хүлээн авах цэгүүд
            </HeadlineHeadlineSmall>
            <HeadlineHeadlineSmall>|</HeadlineHeadlineSmall>
            <BodyBodyMedium sx={{}} color="var(--on-surface-medium-neutral)">
              Нийт {query.total} цэг
            </BodyBodyMedium>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftBtn onClick={() => onClickButtonLeft()} />
            <ArrowRightBtn onClick={() => onClickButtonRight()} />
          </Box>
        </Box>

        {dropOffData ? (
          <GridCardComponent data={dropOffData} type="DROPOFF" />
        ) : null}
      </TableContainer>

      {openOrganizationModal ? (
        <CreateOrganizationModal
          Title={"Цэгийн бүртгэл"}
          open={openOrganizationModal}
          handleClose={() => setOpenOrganizationModal(false)}
          type="DROPOFF"
          itemList={itemList}
          roleName={"DropOffCenterAdmin"}
          initialQuery={initialQuery}
          setQuery={setQuery}
        />
      ) : null}
    </Grid2>
  );
};

export default DropOff;
