import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/user";
import { FaqTable } from "../../components/help/faq-table";
import { ContactInfo } from "../../components/help/contact-info";
import { RoleList } from "./detail/roleList";
import { PointList } from "./detail/pointList";
import { DonationList } from "./detail/donationList";

const initQuery = {
  sort: "desc", // createdDate
  // status: null,
  newsType: "news",
  //  "status":"ON_WAY",
  // "status":"SUCCESS_SHIPPED",
  // "status":"TRANSFERRED_TO_DRIVER",
  // "status":"CANCELLED",
  keyWord: null, // "1",
  searchType: null, // "organization.id"
  total: 0,
};

const initPage = {
  page: 1,
  limit: 8,
};
const statuses = [
  {
    id: "list",
    label: "Бүртгэлтэй баркод",
    value: "list",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
  },
  {
    id: "request",
    label: "Баркод таниулах хүсэлт",
    value: "request",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
  },
  {
    id: "notrecycled",
    label: "Дахин боловсруулахгүй хуванцар",
    value: "notrecycled",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
  },
];
const BarcodePage = () => {
  const { type } = useParams();
  const { user } = useContext(UserContext);

  const [selectedStatusId, setSelectedStatusId] = useState("list");

  const [query, setQuery] = useState(initQuery);

  useEffect(() => {
    if (type) setSelectedStatusId(type);
  }, [type]);

  const handleButtonClick = (label, id, value) => {
    setSelectedStatusId(id);
    setQuery({ ...query, status: value });
    const newUrl = `/barcode/${id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <p
          className="HeadlineHeadline---Small"
          style={{
            color: "var(--on-surface-high)",
            paddingTop: "8px",
          }}
        >
          Баркод
        </p>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {statuses.map((status, index) => (
          <Button
            key={index}
            className="LabelLabel---Small"
            variant="contained"
            sx={{
              "backgroundColor": selectedStatusId === status.id ? status.color : status.bgColor,
              "color": selectedStatusId === status.id ? "#fff" : "var(--primary-soft-on-primary-soft-h)",
              "borderRadius": "24px",
              "border": "0",
              "padding": "4px 16px",
              "textTransform": "none",
              "minWidth": "120px",
              "height": "36px",
              "&:hover": {
                backgroundColor: selectedStatusId === status.id ? "#1b5e20" : status.bgColor,
              },
            }}
            // onClick={() => setOpenMapModal(true)}
            onClick={() => {
              handleButtonClick(status.label, status.id, status.value);
            }}
          >
            {status.label}
          </Button>
        ))}
      </Box>

      {selectedStatusId === "list" ? <RoleList /> : null}
      {selectedStatusId === "request" ? <PointList /> : null}
      {selectedStatusId === "notrecycled" ? <DonationList /> : null}
    </Grid2>
  );
};

export default BarcodePage;
