import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import HomePage from "../pages/Home";
import MainLayout from "../layouts/side-bar";
import Driver from "../pages/driver";
import Call from "../pages/call";
import NotFound from "../pages/not-found";
import { UserContext } from "../context/user";
import Enterprise from "../pages/enterprise";
import EnterpriseRegister from "../pages/enterprise/register-request";
import Customer from "../pages/customer";
import Factory from "../pages/factory";
import DropOff from "../pages/dropoff";
import Prepare from "../pages/dropoff/prepare";
import Finance from "../pages/finance";
import ItemGroup from "../pages/guide/itemGroup";
import ItemGroupDetail from "../pages/guide/itemGroup/detail";
import Barcode from "../pages/barcode";
import News from "../pages/news";
import NewsDetail from "../pages/news/detail";
import Exam from "../pages/exam";
import ExamDetail from "../pages/exam/detail";
import ExamEntry from "../pages/exam/detail/examEntry";
import Help from "../pages/help";
import Settings from "../pages/settings";
import FactoryDetail from "../pages/factory/detail";
import DropOffDetail from "../pages/dropoff/detail";
import EnterpriseDetail from "../pages/enterprise/detail";
import CustomerDetail from "../pages/customer/detail";
import DriverDetail from "../pages/driver/detail";
import DriverLocation from "../pages/driver/driver-location";
import Profile from "../pages/profile";
import ReelsDetail from "../pages/news/detail/reelsDetail";
import UserDeletion from "../pages/UserDeletion";
import DonationDetail from "../pages/settings/detail/donationDetail";

function Routing() {
  const { user, setUser } = useContext(UserContext);

  // Check session validity on component mount
  useEffect(() => {
    const cookieData = localStorage.getItem("user");
    if (cookieData) {
      try {
        const parsedData = JSON.parse(cookieData);
        const expiryDate = new Date(parsedData.expiryDate);
        if (expiryDate > new Date()) {
          setUser(parsedData); // Session is valid
        } else {
          setUser(null); // Session expired
        }
      } catch (error) {
        setUser(null); // If cookie is corrupted, treat it as expired
      }
    } else {
      setUser(null); // If no cookie, redirect to login
    }
  }, [setUser]);

  // Extract allowed resources
  const allowedResources = user?.roleResource?.map((item) => item.resourceCode) || [];

  // Define all possible routes with their corresponding resource codes
  const allRoutes = [
    { path: "/", label: "Нүүр", element: <HomePage />, resourceCode: "Dashboard" },
    { path: "/call", label: "Дуудлага", element: <Call />, resourceCode: "CallPage" },
    { path: "/driver", label: "Жолооч", element: <Driver />, resourceCode: "DriverPage" },
    { path: "/driver/detail/:id", label: "Жолоочийн дэлгэрэнгүй", element: <DriverDetail />, resourceCode: "DriverPage" },
    { path: "/driver/detail/location/:id", label: "Жолоочийн байршил", element: <DriverLocation />, resourceCode: "DriverEdit" },
    { path: "/enterprise", label: "Аж ахуй нэгж", element: <Enterprise />, resourceCode: "EnterprisePage" },
    { path: "/enterprise/detail/:id", label: "Аж ахуй нэгжийн дэлгэрэнгүй", element: <EnterpriseDetail />, resourceCode: "EnterpriseRegister" },
    { path: "/enterprise/register", label: "Бүртгүүлэх хүсэлт", element: <EnterpriseRegister />, resourceCode: "EnterpriseRegister" },
    { path: "/customer", label: "Харилцагч", element: <Customer />, resourceCode: "CustomerPage" },
    { path: "/customer/detail/:id", label: "Харилцагчийн дэлгэрэнгүй", element: <CustomerDetail />, resourceCode: "CustomerPage" },
    { path: "/factory", label: "Үйлдвэр", element: <Factory />, resourceCode: "FactoryPage" },
    { path: "/factory/detail/:id", label: "Үйлдвэрийн дэлгэрэнгүй", element: <FactoryDetail />, resourceCode: "FactoryPage" },
    { path: "/dropoff", label: "Цэг", element: <DropOff />, resourceCode: "DropOffPage" },
    { path: "/dropoff/detail/:id", label: "Цэгийн дэлгэрэнгүй", element: <DropOffDetail />, resourceCode: "DropOffPage" },
    { path: "/dropoff/prepare", label: "Бэлтгэх цэг", element: <Prepare />, resourceCode: "DropOffPrepare" },
    { path: "/finance", label: "Санхүү", element: <Finance />, resourceCode: "FinancePage" },
    { path: "/guide", label: "Заавар", element: <ItemGroup />, resourceCode: "GuidePage" },
    { path: "/guide/detail/:id/:type", label: "Заавар", element: <ItemGroupDetail />, resourceCode: "GuidePage" },
    { path: "/barcode/:id", label: "Баркод", element: <Barcode />, resourceCode: "BarcodePage" },
    { path: "/news", label: "Мэдээ", element: <News />, resourceCode: "NewsPage" },
    { path: "/news/detail/:id", label: "Мэдээний дэлгэрэнгүй", element: <NewsDetail />, resourceCode: "NewsPage" },
    { path: "/news/detail/:id/reels", label: "Мэдээний видео", element: <ReelsDetail />, resourceCode: "NewsReels" },
    { path: "/exam", label: "Тест", element: <Exam />, resourceCode: "ExamPage" },
    { path: "/exam/detail/:id", label: "Тестийн дэлгэрэнгүй", element: <ExamDetail />, resourceCode: "ExamPage" },
    { path: "/exam/edit/:id", label: "Тест засах", element: <ExamEntry isEditMode={true} />, resourceCode: "ExamEdit" },
    { path: "/exam/create", label: "Тест үүсгэх", element: <ExamEntry isEditMode={false} />, resourceCode: "ExamCreate" },
    { path: "/help/:type", label: "Тусламж", element: <Help />, resourceCode: "HelpPage" },
    { path: "/settings/:type", label: "Тохиргоо", element: <Settings />, resourceCode: "SettingsPage" },
    { path: "/settings/donations/detail/:id", label: "Хандивын мэдээлэл", element: <DonationDetail />, resourceCode: "SettingsPage" },
    { path: "/profile", label: "Профайл", element: <Profile />, resourceCode: "Dashboard" },
  ];

  // Filter routes based on allowed resources
  const filteredRoutes = allRoutes.filter((route) =>
    allowedResources.includes(route.resourceCode)
  );

  return (
    <BrowserRouter>
      {!(user === undefined || user === null) ? (
        <Routes>
          {/* Render filtered routes */}
          {filteredRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <MainLayout>
                  {route.element}
                </MainLayout>
              }
            />
          ))}

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/user/deletion" element={<UserDeletion />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Routing;