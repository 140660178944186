import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid2,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { UserContext } from "../../context/user";
import { mainApi } from "../../api/main";
import {
  BodyBodyMedium,
  HeadlineHeadlineMedium,
  HeadlineHeadlineSmall,
} from "../../utils/styleMethod";
import { Plus } from "@phosphor-icons/react";
import ArrowLeftBtn from "../../components/form/buttons/arrow-left";
import ArrowRightBtn from "../../components/form/buttons/arrow-right";
import GridCardComponent from "../../components/grid-card-component";
import CreateOrganizationModal from "../../components/modals/CreateOrganization";

const initialQuery = {
  page: 1,
  limit: 8,
  total: 0,
  type: "SUH",
  itemGroupId: 0,
};

const Enterprise = () => {
  const { user, setUser } = useContext(UserContext);
  const [query, setQuery] = useState(initialQuery);
  const [suhData, setSuhData] = useState([]);

  const [itemList, setItemList] = useState([]);
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);

  useEffect(() => {
    getItemList();
  }, []);

  useEffect(() => {
    getAllFactory();
  }, [query]);

  const getAllFactory = useCallback(async () => {
    try {
      const res = await mainApi("organization/", "POST", query);
      setSuhData(res.content);

      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery; //
      });
    } catch (err) {
      console.error("SUH fetch error:", err);
    } finally {
      console.log("end");
    }
  }, [query]);

  const onClickButtonLeft = async () => {
    setQuery({ ...query, page: query.page - 1 });
    getAllFactory();
  };
  const onClickButtonRight = async () => {
    setQuery({ ...query, page: query.page + 1 });
    getAllFactory();
  };

  const getItemList = async () => {
    const res = await mainApi("items/group/list", "GET", null);
    setItemList(res);
  };
  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">
          Аж ахуй нэгж
        </HeadlineHeadlineMedium>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2e7d32",
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
          onClick={() => setOpenOrganizationModal(true)}
        >
          ААН нэмэх
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "64px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HeadlineHeadlineSmall color="var(--on-surface-high)">
              Бүртгэлтэй ААН
            </HeadlineHeadlineSmall>
            <HeadlineHeadlineSmall>|</HeadlineHeadlineSmall>
            <BodyBodyMedium sx={{}} color="var(--on-surface-medium-neutral)">
              Нийт {query.total} ААН
            </BodyBodyMedium>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <ArrowLeftBtn onClick={() => onClickButtonLeft()} />
            <ArrowRightBtn onClick={() => onClickButtonRight()} />
          </Box>
        </Box>

        {suhData ? <GridCardComponent data={suhData} type="SUH" /> : null}
      </TableContainer>

      {openOrganizationModal ? (
        <CreateOrganizationModal
          Title={"Аж ахуйн нэгжийн бүртгэл"}
          open={openOrganizationModal}
          handleClose={() => setOpenOrganizationModal(false)}
          type="SUH"
          itemList={itemList}
          roleName={"SUHadmin"}
          initialQuery={initialQuery}
          setQuery={setQuery}
        />
      ) : null}
    </Grid2>
  );
};

export default Enterprise;
