import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";

import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import { FormInputDropdown } from "../../form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { callFilter } from "../../../utils/method";
import { StatusSpan } from "../../form/span";
import { BasicButton } from "../../form/buttons/basic-button";
import { ToggleButton } from "../../form/buttons/toggle-button";
import { tableBodyCellStyle, tableHeaderStyle } from "../../../utils/styleMethod";
import AsignToDriverModal from "../../modals/AsignToDriver";
import { mainApi } from "../../../api/main";

export const NewsTable = ({ data, query, setQuery, driver }) => {
  const { control } = useForm({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [asignModalOpen, setAsignModalOpen] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [filteredFactoryDrivers, setFilteredFactoryDrivers] = useState([]);

  useEffect(() => {
    getTimeSlots();
  }, []);

  const getTimeSlots = async () => {
    const res = await mainApi("driver/time-slots", "GET", null);
    setTimeSlots(res.data);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => setQuery({ ...query, page: newPage });

  const onChangeSort = (values) => setQuery({ ...query, sort: values });

  const handleButtonClick = (row) => {
    setSelectedRowData(row);
    organizationDriverList(row.factoryId);
    setAsignModalOpen(true);
  };

  const organizationDriverList = async (id) => {
    try {
      const res = await mainApi(`driver/org-list/${id}`, "GET", null);
      setFilteredFactoryDrivers(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Мэдээний жагсаалт <span> | </span> <span className="BodyBody---Medium"> Нийт {query.total} мэдээ байна</span>{" "}
        </p>
        <FormInputDropdown
          control={control}
          name={`asc`}
          options={callFilter}
          sx={{ minWidth: "250px", marginRight: "12px" }}
          onChange={(selectedValue) => onChangeSort(selectedValue)}
        />
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["Огноо", "Гарчиг", "Хэл", "Тухай"].map((header, index) => (
              <TableCell key={index} className={classessHeader.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.callId || index} className={index % 2 === 1 ? "table-row-odd" : ""}>
              <TableCell className={classessCell.tableBodyCell}>{row.postedDateStr}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.caption}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.language}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <ToggleButton label={"Дэлгэрэнгүй"} onClickButton={() => handleButtonClick(row)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AsignToDriverModal
        open={asignModalOpen}
        callData={selectedRowData}
        handleClose={() => setAsignModalOpen()}
        timeSlots={timeSlots}
        filteredFactoryDrivers={filteredFactoryDrivers}
        setQuery={setQuery}
        driver={driver}
      />

      {/* Custom Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination count={Math.ceil(query.total / query.limit)} page={query.page} onChange={handleChangePage} siblingCount={1} boundaryCount={1} />
      </Box>
    </TableContainer>
  );
};
