// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-text {
  font-size: 24px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 12px;
  position: relative;
  text-align: center;
}

/* Common line and circle styles */
.label-line {
  width: 2px;
  height: 20px;
}

.label-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/* Styling for SUCCESS status */
.success .label-text {
  background-color: #368137; /* Green background color */
  color: white; /* White text color */
}

.success .label-line {
  background-color: #368137; /* Same green color as the background */
}

.success .label-circle {
  background-color: #368137; /* Same green color as the background */
}

/* Styling for NON-SUCCESS status */
.non-success .label-text {
  background-color: #FFB601; /* Yellow background color */
  color: #3A2900; /* Dark text color */
}

.non-success .label-line {
  background-color: #FFB601; /* Same yellow color as the background */
}

.non-success .label-circle {
  background-color: #FFB601; /* Same yellow color as the background */
}
`, "",{"version":3,"sources":["webpack://./src/components/driver/google-map/map-style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA,kCAAkC;AAClC;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA,+BAA+B;AAC/B;EACE,yBAAyB,EAAE,2BAA2B;EACtD,YAAY,EAAE,qBAAqB;AACrC;;AAEA;EACE,yBAAyB,EAAE,uCAAuC;AACpE;;AAEA;EACE,yBAAyB,EAAE,uCAAuC;AACpE;;AAEA,mCAAmC;AACnC;EACE,yBAAyB,EAAE,4BAA4B;EACvD,cAAc,EAAE,oBAAoB;AACtC;;AAEA;EACE,yBAAyB,EAAE,wCAAwC;AACrE;;AAEA;EACE,yBAAyB,EAAE,wCAAwC;AACrE","sourcesContent":[".label {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.label-text {\n  font-size: 24px;\n  font-weight: bold;\n  padding: 8px 16px;\n  border-radius: 12px;\n  position: relative;\n  text-align: center;\n}\n\n/* Common line and circle styles */\n.label-line {\n  width: 2px;\n  height: 20px;\n}\n\n.label-circle {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n}\n\n/* Styling for SUCCESS status */\n.success .label-text {\n  background-color: #368137; /* Green background color */\n  color: white; /* White text color */\n}\n\n.success .label-line {\n  background-color: #368137; /* Same green color as the background */\n}\n\n.success .label-circle {\n  background-color: #368137; /* Same green color as the background */\n}\n\n/* Styling for NON-SUCCESS status */\n.non-success .label-text {\n  background-color: #FFB601; /* Yellow background color */\n  color: #3A2900; /* Dark text color */\n}\n\n.non-success .label-line {\n  background-color: #FFB601; /* Same yellow color as the background */\n}\n\n.non-success .label-circle {\n  background-color: #FFB601; /* Same yellow color as the background */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
