import React, { useState } from "react";
import { Modal, Box, Button, Grid2 } from "@mui/material";
import { X } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  HeadlineHeadlineMedium,
  LabelLabelLarge,
} from "../../utils/styleMethod";
import CustomInput from "../form/input";
import SuccessModal from "./Success";
import { mainApi } from "../../api/main";
import { toast } from "react-toastify";

const MergeAccountModal = ({ open, handleClose, data, setUserData }) => {
  const [contactValue, setContactValue] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleInputChange = (value) => {
    setContactValue(value);
  };

  const onClickMerge = async () => {
    const json = {
      userId: data.userId,
      requestedPhoneNumberOrEmail: contactValue,
    };
    try {
      const res = await mainApi("user/merge", "POST", json);
      handleClose();
      setOpenSuccessModal(true);
      setUserData({...data, phoneNumber: contactValue})

    } catch (error) {
      toast.warning(error.response.data.message);
    }
  };

  const closeSuccessModal = () => setOpenSuccessModal(false)

  return (
    <>
      <Modal open={open} onClose={() => handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420, // Updated width
            height: 400, // Updated height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            // justifyContent: 'center',
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            width="100%"
          >
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            mb={2}
          >
            <HeadlineHeadlineMedium>Аккоунт нэгтгэх</HeadlineHeadlineMedium>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={3}
          >
            <BodyBodyLarge>
              Та {data.phoneNumber} дугаар бүхий хэрэглэгчийн аккоунттай нэгтгэх
              бүртгэлтэй дугаарыг эсвэл имэйл хаяг оруулна уу
            </BodyBodyLarge>
          </Box>

          <Grid2 container spacing={2} justifyContent="center">
            <Grid2 xs={12}>
              <CustomInput
                handleInputChange={handleInputChange}
                style={{ width: "250px", height: "45px" }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Button
                sx={{
                  height: "45px",
                  border: "1px solid #368137",
                  borderRadius: "16px",
                  padding: "16px",
                  color: "#368137",
                }}
                variant="contained"
                onClick={() => {
                  onClickMerge();
                }}
              >
                <LabelLabelLarge color="white">Нэгтгэх</LabelLabelLarge>
              </Button>
            </Grid2>
          </Grid2>
        </Box>
      </Modal>
      <SuccessModal
        title="Аккоунт амжилттай нэгтгэгдлээ"
        content={`${contactValue} болон ${data.phoneNumber} хэрэглэгчийн аккоунт амжилттай нэгтгэгдэн цаашид үндсэн хаягаар ${contactValue} ашиглагдах болно`}
        open={openSuccessModal}
        handleClose={closeSuccessModal}
      />
    </>
  );
};

export default MergeAccountModal;
