import React, { useContext } from "react";
import { Grid2, Typography } from "@mui/material";

const NotFound = () => {
  

  return (
    <Grid2
      sx={{
        height: "68vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      container
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 700,
        }}
      >
        Хуудас олдсонгүй
        
      </Typography>
    </Grid2>
  );
};

export default NotFound;
