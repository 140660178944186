// DynamicInput.jsx
import React, { useState } from "react";
import "./custom-input.css"; 

const CustomInput = ({ handleInputChange, style, placeholderText , defaultValue}) => {
  const [contact, setContact] = useState(defaultValue);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setContact(value);

    handleInputChange(value);

    
    // if (value.trim() === '' || value.length < 6) {
    //   setError(true);
    // } else {
    //   setError(false);
    // }
  };

  return (
    <div>
      <input
        className={`custom-input ${error ? "error" : ""}`} // Dynamically add 'error' class
        value={contact}
        onChange={handleChange}
        placeholder={
          placeholderText ? placeholderText : "Утас эсвэл имэйл хаяг оруулна уу"
        }
        style={style} // Apply the dynamic style
      />
      {/* {error && <p style={{ color: '#4caf50' }}>{placeholderText ? placeholderText :'Буруу утга байна'}</p>} */}
    </div>
  );
};

export default CustomInput;
