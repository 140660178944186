import React, { useEffect, useState } from "react";
import { Grid2 } from "@mui/material";
import DriverMap from "../../../components/driver/google-map";
import { useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import dayjs from "dayjs";

const DriverLocation = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) driverPlan();
  }, [selectedDate]);

  const driverPlan = async () => {
    try {
      const json = {
        driverId: id,
        onDay: selectedDate.format("YYYY-MM-DD"),
      };
      const res = await mainApi("driver/driver-plan", "POST", json);
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid2>
      {data != [] ? <DriverMap
        data={data[0]}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        driverId = {id}
      /> : null}
      
    </Grid2>
  );
};

export default DriverLocation;
