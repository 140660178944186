import React, { useState } from "react";
import { Modal, Box, Button, Divider } from "@mui/material";
import {
  BodyBodyMedium,
  LabelLabelSmall,
  TitleTitleLarge,
} from "../../utils/styleMethod";
import { WarningCircle } from "@phosphor-icons/react";
import SuccessModal from "./Success";

const AcceptableModal = ({ title, content, buttonText, open, handleClose, deleteApi, successTitle}) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  
  const confirm = () =>{
    deleteApi()
    setOpenSuccessModal(true)
    handleClose(false)
  }
  return (
    <>
      <Modal open={open} onClose={() => handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340, 
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            // justifyContent: 'center',
            alignItems: "center",
          }}
        >
          <WarningCircle size={32} weight="fill" color="#EA9708" />

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={2}
          >
            <TitleTitleLarge>{title}</TitleTitleLarge>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            width="100%"
            mb={5}
          >
            <BodyBodyMedium>{content}</BodyBodyMedium>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              sx={{
                width: "100%",
                height: "45px",
                border: "1px solid #C5E7C6",
                borderRadius: "16px",
                paddingRight: "32px",
                paddingLeft: "32px",
                color: "#368137",
                marginRight: "6px",
                backgroundColor: "#C5E7C6",
              }}
              variant="contained"
              onClick={handleClose}
            >
              <LabelLabelSmall color="#368137">
                {buttonText ? buttonText : "Үгүй"}
              </LabelLabelSmall>
            </Button>
            <Button
              sx={{
                width: "100%",
                height: "45px",
                border: "1px solid #368137",
                borderRadius: "16px",
                paddingRight: "32px",
                paddingLeft: "32px",
                marginLeft: "6px",
                color: "#368137",
                backgroundColor: "#368137",
              }}
              variant="contained"
              onClick={()=> confirm()}
            >
              <LabelLabelSmall color="white">
                {buttonText ? buttonText : "Тийм"}
              </LabelLabelSmall>
            </Button>
          </Box>
        </Box>
      </Modal>
      
      <SuccessModal
        title={successTitle ? successTitle: "Хэрэглэгч амжилттай устгагдлаа"} 
        content=""
        open={openSuccessModal}
        handleClose={closeSuccessModal}
        buttonText="Хаах"
      />
    </>
  );
};

export default AcceptableModal;
