import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
import { Box, Pagination } from "@mui/material";
import { FormInputDropdown } from "../../form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { callFilter } from "../../../utils/method";
import { transferToDriverData } from "../../../utils/method";
import { StatusSpan } from "../../form/span";
import { BasicButton } from "../../form/buttons/basic-button";
import {
  tableBodyCellStyle,
  tableHeaderStyle,
} from "../../../utils/styleMethod";
import CallDetailModal from "../../modals/CallDetail";

export const TransferToDriverTable = ({ data, query, setQuery }) => {
  const { control } = useForm({});
  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setDetailModalOpen(true);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setQuery({ ...query, page: newPage });
  };

  const onChangeSort = (values) => setQuery({ ...query, sort: values });

  const handleButtonClick = (label) => {
    console.log("handleButtonClick==========> ", label);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p
          className="HeadlineHeadline---Small"
          style={{ color: "var(--on-surface-high)" }}
        >
          Дуудлагууд <span> | </span>{" "}
          <span className="BodyBody---Medium">
            {" "}
            Нийт {query.total || 0} дуудлага{" "}
          </span>{" "}
        </p>
        <FormInputDropdown
          control={control}
          name={`asc`}
          options={callFilter}
          sx={{ minWidth: "250px", marginRight: "12px" }}
          onChange={(selectedValue) => onChangeSort(selectedValue)}
        />
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {[
              "Ачигдах огноо",
              "Дахивар",
              "Ачилтын хаяг",
              "Хүргэх үйлдвэр",
              "Жолооч",
              "Төлөв",
            ].map((header) => (
              <TableCell key={header} className={classessHeader.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              className={index % 2 === 1 ? "table-row-odd" : ""}
              onClick={() => handleRowClick(row)}
              style={{ cursor: "pointer" }}
            >
              <TableCell className={classessCell.tableBodyCell}>
                {row.shipDate}
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <StatusSpan label={row.itemsGroupName} statusColor={"green"} />
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                {row.address}
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                {row.factoryName}
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                {row.driverCode}
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <StatusSpan label={row.status} statusColor={row.statusColor} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {detailModalOpen ? (
        <CallDetailModal
          open={detailModalOpen}
          handleClose={() => setDetailModalOpen(false)}
          callData={selectedRowData}
        />
      ) : null}
      {/* Custom Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(query.total / query.limit)}
          page={query.page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
    </TableContainer>
  );
};
