import { Card, CardContent, Box, Grid2 } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import GridCardPlus from "../../components/grid-card-plus";

const StyledCard = styled(Card)(({ theme }) => ({
  "width": "196px",
  "height": "190px",
  "padding": "12px 16px 12px 16px",
  "border": "1px solid #A9DBAA ",
  "borderRadius": "12px",
  "boxShadow": "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "transition": "transform 0.2s ease-in-out",
  "cursor": "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
}));

const AdminInfoValue = styled("span")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.primary.dark,
  fontWeight: "bold",
  fontStretch: "normal",
  lineHeight: 1.4,
  letterSpacing: "normal",
  textAlign: "center",
}));

const LabelDisplay = ({ label, value }) => {
  return (
    <Box display="flex" alignItems="center" marginBottom="8px" justifyContent="center" width="100%">
      <AdminInfoValue>{value}</AdminInfoValue>
    </Box>
  );
};

export default function GridCardItemGroup(props) {
  const navigate = useNavigate();
  
  const onClickNavigate = (id) => {
    if (props.type === "itemGroup") {
      navigate(`/guide/${id}`);
    } else if (props.type === "type") {
      navigate(`/guide/detail/${id}/type`);
    }
  };

  return (
    <Grid2 container spacing={2} alignItems="stretch">
      {props.data.map((item, index) => (
        <Grid2 xs={12} sm={6} md={4} lg={3} key={index}>
          <StyledCard onClick={() => onClickNavigate(item.id)}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  style={{
                    width: 130,
                    height: 118,
                    marginRight: "10px",
                  }}
                />
              </Box>
              <LabelDisplay label="" value={item.name} />
            </CardContent>
          </StyledCard>
        </Grid2>
      ))}
      {props?.showAddButton && <GridCardPlus type="ItemGroupType" onAddClick={props?.onAddClick} />}
    </Grid2>
  );
}
