import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
} from "@mui/material";
import { X } from "@phosphor-icons/react"; // Close icon
import { useForm, Controller } from "react-hook-form";
import { LabelLabelLarge } from "../../utils/styleMethod";
import { mainApi } from "../../api/main";
import { toast } from "react-toastify";

const AddOrRemoveModal = ({
    open,
    handleClose,
    organizationId,
    title,
    buttonText,
    setEmployeeUser,
    type,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedId, setSelectedId] = useState(0);

    useEffect(() => {
        organizationEmployees();
    }, []);

    const organizationEmployees = async () => {
        const res = await mainApi(
            `organization/employees/${organizationId}/${type}`,
            "GET",
            null
        );
        setEmployeeList(res.data);
    };

    const onSubmit = async (data) => {
        if ("Ажилтан хасах" === title) {

            try {
                const res = await mainApi(
                    `organization/remove-employee/${selectedId}`,
                    "DELETE",
                    null
                );
            } catch (error) {
                toast.warning("Ажилтан хасахад системд алдаа гарлаа.")
            }

            setLoading(true);
            handleClose();
        } else {
            console.log("add ");
            setEmployeeUser(data.role);
            setLoading(true);
            handleClose();
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 450,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box display="flex" justifyContent="end" width="100%">
                    <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
                        <X size={24} color="#4caf50" />
                    </Button>
                </Box>

                <Box display="flex" justifyContent="center" width="100%">
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                </Box>

                <Typography variant="body2" align="center" marginBottom={2}>
                    Та Прокрафт дизайн агентлаг ХХК -с хасах хүсэлтэй админыг
                    сонгоно уу
                </Typography>

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    {/* Role selection */}
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Сонго</FormLabel>
                        <Controller
                            name="role"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Энэ талбарыг сонгох шаардлагатай!",
                            }}
                            render={({ field }) => {
                                const roles = {
                                    admin:
                                        type === "SUH"
                                            ? "SUHadmin"
                                            : type === "DropOff"
                                            ? "DropOffCenterAdmin"
                                            : "FactoryAdmin",
                                    user:
                                        type === "Enterprise"
                                            ? "SUHuser"
                                            : type === "DropOff"
                                            ? "DropOffCenterUser"
                                            : "FactoryUser",
                                    worker:
                                        type === "Factory"
                                            ? "FactoryWorker"
                                            : null,
                                };

                                const admin = employeeList?.find(
                                    (item) => item.roleName === roles.admin
                                );
                                const users =
                                    employeeList?.filter(
                                        (item) => item.roleName === roles.user
                                    ) || [];

                                const worker = roles.worker
                                    ? employeeList?.find(
                                          (item) =>
                                              item.roleName === roles.worker
                                      )
                                    : [];

                                const handleChange = (event) => {
                                    const selectedValue = event.target.value;
                                    let selectedLabel = event.target.label;

                                    if (selectedValue === "admin") {
                                        selectedLabel = admin?.id;
                                    } else if (
                                        [
                                            "assistant_admin_1",
                                            "assistant_admin_2",
                                        ].includes(selectedValue)
                                    ) {
                                        const index =
                                            selectedValue ===
                                            "assistant_admin_1"
                                                ? 0
                                                : 1;
                                        selectedLabel = users[index]?.id;
                                    } else if (
                                        selectedValue === "factory_worker"
                                    ) {
                                        selectedLabel = worker?.id;
                                    }
                                    setSelectedId(selectedLabel);

                                    console.log(
                                        "Selected Label:",
                                        selectedLabel
                                    );
                                };

                                return (
                                    <RadioGroup
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange(e);
                                        }}
                                    >
                                        {/* Admin */}
                                        <FormControlLabel
                                            value="admin"
                                            control={<Radio />}
                                            label={
                                                admin
                                                    ? `Админ (${admin.userName})`
                                                    : "Админ"
                                            }
                                            disabled={
                                                "Ажилтан хасах" === title
                                                    ? false
                                                    : !!admin
                                            }
                                        />

                                        {/* Assistant Admins */}
                                        {[
                                            "assistant_admin_1",
                                            "assistant_admin_2",
                                        ].map((value, index) => (
                                            <FormControlLabel
                                                key={value}
                                                value={value}
                                                control={<Radio />}
                                                label={
                                                    users[index]
                                                        ? `Туслах админ (${users[index].userName})`
                                                        : "Туслах админ"
                                                }
                                                disabled={
                                                    "Ажилтан хасах" === title
                                                        ? false
                                                        : !!users[index]
                                                }
                                            />
                                        ))}

                                        {/* Worker (conditionally rendered for Factory type) */}
                                        {type === "Factory" && (
                                            <FormControlLabel
                                                value="factory_worker"
                                                control={<Radio />}
                                                label={
                                                    worker
                                                        ? `Үйлдвэрийн ажилтан (${worker.userName})`
                                                        : "Үйлдвэрийн ажилтан"
                                                }
                                                disabled={
                                                    "Ажилтан хасах" === title
                                                        ? false
                                                        : !!worker
                                                }
                                            />
                                        )}
                                    </RadioGroup>
                                );
                            }}
                        />

                        {errors.role && (
                            <Box sx={{ color: "red", fontSize: "12px" }}>
                                {errors.role.message}
                            </Box>
                        )}
                    </FormControl>

                    {/* Submit Button */}
                    <Box mt={2} display="flex" justifyContent="center">
                        <Button
                            type="submit"
                            sx={{
                                height: "45px",
                                border: "1px solid #368137",
                                borderRadius: "16px",
                                padding: "16px",
                                color: "white",
                            }}
                            variant="contained"
                            disabled={loading}
                        >
                            {/* {loading ? "Илгээж байна..." : { buttonText }} */}
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                <LabelLabelLarge color="white">
                                    {buttonText}
                                </LabelLabelLarge>
                            )}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default AddOrRemoveModal;
