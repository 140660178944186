import { useState, useRef } from "react";
import "./styles.css";
import { Camera } from "@phosphor-icons/react";
import { mainApi } from "../../api/main";

const CameraImageUpload = ({ setImageUrl, type }) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
    "image/tiff",
    "image/svg+xml",
  ];
  const maxFileSize = 20 * 1024 * 1024; // 20MB limit

  const fileInputRef = useRef(null);

  const handleFileUpload = async (file) => {
    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setIsError(true);
      setErrorMsg(
        "Only JPEG, PNG, GIF, WebP, BMP, TIFF, and SVG images are allowed."
      );
      return;
    }

    // Validate file size
    if (file.size > maxFileSize) {
      setIsError(true);
      setErrorMsg("File size exceeds 20MB limit.");
      return;
    }

    setIsError(false);
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("imageType", type || "cover");

    try {
      const response = await mainApi("image/upload", "POST", formData);
      if (response.statusCode === 200) {
        setImageUrl(response.data);
      }
    } catch (error) {
      console.error("Upload failed: ", error);
      setIsError(true);
      setErrorMsg("Failed to upload image. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadImage = (e) => {
    const selectedFile = e.target.files[0];
    handleFileUpload(selectedFile);
  };

  return (
    <div className="upload-container">
      {/* Camera Icon */}
      <div
        className="camera-icon"
        onClick={() => fileInputRef.current.click()} 
      >
        <Camera size={24} color="white" />
      </div>

      {isError && <p className="error-message">{errorMsg}</p>}

      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        id="imageupload"
        style={{ display: "none" }}
        onChange={handleUploadImage}
        ref={fileInputRef}
      />

      {/* {isUploading && <p className="uploading-message">Uploading...</p>} */}
    </div>
  );
};

export default CameraImageUpload;
