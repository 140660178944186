import React, { useContext, useState } from "react";
import { Grid2, Typography } from "@mui/material";
import { UserContext } from "../../context/user";
import ImageUpload from "../../components/image-upload";
import CameraImageUpload from "../../components/camera-upload";

const Help = () => {
  const { user, setUser } = useContext(UserContext);
  const [urlImage, setImageUrl] = useState('image url ')
  
  return (
    <Grid2
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        Help page
      </Typography>

      <ImageUpload type={"cover"} setImageUrl={setImageUrl}/>
      
      {urlImage}

      <CameraImageUpload type={"cover"} setImageUrl={setImageUrl}/>
    </Grid2>
  );
};

export default Help;
